import { useRouter } from 'next/router'
import { FunctionComponent, useEffect } from 'react'
import MisterContainer from '../../shared/MisterContainer'
import { useLocalStorage } from 'react-use'
import MisterArticleCard from '../../pages/blog/article-card/MisterArticleCard'
import MisterIcon from 'src/components/shared/MisterIcon'
import { cn } from 'ui/lib/utils'
import { useTranslations } from 'src/contexts/Globals.context'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { Button } from '@ui/components/ui/button'
import { SectionArticleOverviewData } from 'data-access/sanity/fragments/sections/sectionArticleOverview.fragment'

interface SectionArticleOverviewProps {
  data: SectionArticleOverviewData
}

const POSTSPERPAGE = 9

const SectionArticleOverview: FunctionComponent<SectionArticleOverviewProps> = ({ data }) => {
  const translate = useTranslations()
  const { articles, numberOfArticles } = data || {}
  const { asPath } = useRouter()
  const pageNumber = asPath.split('/').length < 3 ? 1 : Number(asPath.split('/')[2])
  const blogPath = asPath.split('/').length < 2 ? asPath : asPath.split('/')[1]
  const amountOfPages = Math.ceil(numberOfArticles / POSTSPERPAGE)
  const pageNumbers = Array.from(Array(amountOfPages).keys())
  const [, setLastVisitedPageNumber] = useLocalStorage(`${blogPath}|lastVisitedPageNumber`)

  useEffect(() => {
    setLastVisitedPageNumber(pageNumber)
  }, [pageNumber, setLastVisitedPageNumber])

  const handleBackToTop = () => {
    if (typeof window !== 'undefined') {
      window?.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  return (
    <MisterContainer dataLabel='section-article-overview' as='div' className='pt-12'>
      {articles && (
        <ol className='grid grid-cols-1 gap-y-10 sm:grid-cols-2 sm:gap-x-4 md:grid-cols-3 lg:gap-y-14'>
          {articles?.map((article, index) => {
            return <MisterArticleCard index={index} key={article._id} article={article} titleAs='h2' />
          })}
        </ol>
      )}
      <footer>
        {pageNumbers?.length > 1 ? (
          <div className='mx-auto mt-14 flex w-full max-w-4xl flex-wrap items-center justify-center gap-2 px-4'>
            <MisterSiteLink
              className={cn(
                'group mx-2 flex size-10 items-center justify-center rounded-full bg-brand-beige-light drop-shadow transition-all duration-100 hover:bg-brand-medium-grey hover:text-white',
                pageNumber === 1 && 'hidden',
              )}
              link={{
                page: {
                  slug: { current: `${blogPath}/${pageNumber - 1}` },
                },
              }}
            >
              <MisterIcon type='chevron-left' className='size-5 transition-transform duration-100 group-hover:scale-110' />
            </MisterSiteLink>
            {pageNumbers?.map((page) => {
              return (
                <MisterSiteLink
                  key={page}
                  link={{
                    page: {
                      slug: { current: `${blogPath}/${page + 1}` },
                    },
                  }}
                  className={cn(
                    'flex size-8 items-center justify-center rounded-full text-center text-btn-sm transition-colors duration-150',
                    page + 1 === pageNumber
                      ? 'pointer-events-none bg-brand-blue font-bold text-brand-beige-light'
                      : 'font-semibold text-brand-medium-grey hover:bg-brand-grey hover:text-brand-blue',
                  )}
                >
                  <span className='tracking-[-.01em]'>{page + 1}</span>
                </MisterSiteLink>
              )
            })}
            <MisterSiteLink
              className={cn(
                'group mx-2 flex size-10 items-center justify-center rounded-full bg-brand-beige-light drop-shadow transition-all duration-100 hover:bg-brand-medium-grey hover:text-white',
                pageNumber === Number(pageNumbers?.[(pageNumbers || []).length - 1]) + 1 && 'hidden',
              )}
              link={{
                page: {
                  slug: { current: `${blogPath}/${pageNumber + 1}` },
                },
              }}
            >
              <MisterIcon type='chevron-right' className='size-5 transition-transform duration-150 group-hover:scale-110' />
            </MisterSiteLink>
          </div>
        ) : null}
        <div className='relative mx-auto mb-14 mt-14 flex w-full max-w-4xl justify-center'>
          {numberOfArticles >= 12 && (
            <Button variant='outline' onClick={() => handleBackToTop()}>
              {translate('backToTop')}
            </Button>
          )}
        </div>
      </footer>
    </MisterContainer>
  )
}

export default SectionArticleOverview
