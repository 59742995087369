import { Button } from '@ui/components/ui/button'
import { cn } from '@ui/lib/utils'
import Image from 'next/image'
import { FunctionComponent } from 'react'
import { useUI } from 'src/contexts/UI.context'
import { imageQuality } from 'src/utils/image.util'
import { trackEvent, TELEMETRY_DATA_LAYER_EVENTS } from 'src/utils/telemetry.util'

export const MobileMenuButton: FunctionComponent = () => {
  const { openMobileMenu, displayMobileMenu, displaySearchUI } = useUI()

  const showIcon = displayMobileMenu || displaySearchUI
  return (
    <Button
      onClick={() => {
        openMobileMenu()
        trackEvent(TELEMETRY_DATA_LAYER_EVENTS.OPEN_NAVIGATION)
      }}
      variant='ghost'
      size='icon'
      data-testid='mobile-menu-button'
      className={cn('flex transition-[transform,opacity] duration-500 ease-out lg:hidden', showIcon && '-translate-x-10 -translate-y-5 opacity-0')}
    >
      <Image src='/img/menu-burger.svg' alt='Mobile menu button' priority={false} quality={imageQuality} width={24} height={24} />
    </Button>
  )
}
