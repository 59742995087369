import { Button } from '@ui/components/ui/button'
import { FunctionComponent } from 'react'
import MisterOrderSummary from 'src/components/pages/account/MisterOrderSummary'
import MisterContainer from 'src/components/shared/MisterContainer'
import { useAccountContext } from 'src/contexts/Account.context'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'

const SectionOrdersOverview: FunctionComponent = () => {
  const { accountData, customer } = useAccountContext()
  const { orders, noOrdersYet, shopNowLink } = accountData || {}

  return (
    <MisterContainer dataLabel='section-orders-overview' type='fluid'>
      <div className='mb-24 mt-8 flex flex-col px-4 lg:px-16'>
        <h1 data-testid='ordersLabel' className='mb-8 text-heading-4 lg:text-heading-3'>
          {orders}
        </h1>
        {customer.orders.edges.length > 0 ? (
          <ol>
            {customer.orders.edges
              .sort((x, y) => new Date(y.node.processedAt).valueOf() - new Date(x.node.processedAt).valueOf())
              .map(({ node: order }) => {
                return <MisterOrderSummary key={order.orderNumber} order={order} email={customer.email} accountData={accountData} />
              })}
          </ol>
        ) : (
          <div className='h-screen'>
            <h2 className='mb-8 text-body-md-bold'>{noOrdersYet}</h2>
            <Button asChild>
              <MisterSiteLink link={shopNowLink}>{shopNowLink?.linkText || 'Shop now'}</MisterSiteLink>
            </Button>
          </div>
        )}
      </div>
    </MisterContainer>
  )
}

export default SectionOrdersOverview
