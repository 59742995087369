import { useState } from 'react'
import { useUI } from '../../contexts/UI.context'
import useCart from './useCart'
import { ELEVAR_DATA_LAYER_EVENTS, trackEvent } from '../../utils/telemetry.util'
import { ecommerceInfoAddToCart } from '../../utils/elevar.util'
import { useCookie, useToggle } from 'react-use'
import { useTranslations } from '../../contexts/Globals.context'
import { get } from '../../utils/localStorage.util'
import { toast } from 'react-toastify'
import { ShopifyProductAvailability } from '@/types/shopify'
import { ShopifyProductInSanity, ShopifyVariantInSanity } from '@/types/product'
import { useTelemetry } from '../../contexts/Telemetry.context'
import { useRouter } from 'next/router'
import { ProductMedia } from 'data-access'
import { algoliaInsightsClient, getAlgoliaIndexName } from '../../utils/algolia.util'
import { ELEVAR_USER_ID_COOKIE } from '../../middleware'
import useAuthenticatedUserToken from '../customer/useAuthenticatedUserToken'

interface ProductFormProps {
  slug: string
  product: ShopifyProductInSanity
  productMedia: ProductMedia[]
  liveProductData?: ShopifyProductAvailability
  isGiftCard?: boolean
  onAddToCartComplete?: () => void
}

type CombinedShopifySanityVariant = ShopifyVariantInSanity

export interface Option {
  name: string
  value: string
}

export interface CombinedOption {
  name: string
  values: string[]
}

const combineOptions = (options: Option[]): CombinedOption[] => {
  const combinedOptionsMap = options.reduce((map, { name, value }) => {
    const existingValues = map.get(name) || new Set<string>()
    existingValues.add(value)
    return map.set(name, existingValues)
  }, new Map<string, Set<string>>())

  return Array.from(combinedOptionsMap.entries()).map(([name, value]) => ({ name, values: Array.from(value).filter(Boolean) }))
}
const optionsToObject = (options: Option[] | []): Record<string, string> => options.reduce((acc, { name, value }) => ({ [name]: value, ...acc }), {})

const useProductForm = ({ slug, product, productMedia, liveProductData, isGiftCard, onAddToCartComplete }: ProductFormProps) => {
  const { locale } = useRouter()
  const translate = useTranslations()
  const { add, cart, currencyCode } = useCart()
  const { openCart, displayShopTheLook } = useUI()
  const { userProperties } = useTelemetry()
  const [customUserId] = useCookie(ELEVAR_USER_ID_COOKIE)
  const authenticatedUserToken = useAuthenticatedUserToken()
  const [algoliaLastIndexName] = useCookie('algoliaLastIndexName')
  const [algoliaLastQueryId] = useCookie('algoliaLastQueryId')
  const indexName = getAlgoliaIndexName(String(locale))

  const combinedVariants = product.variants
    .filter((variant) => variant.shopifyVariantId)
    .map((variant) => {
      const shopifyData = liveProductData?.variants.edges.find(({ node }) => node.sku === variant.sku)?.node
      return {
        ...variant,
        isAvailable: isGiftCard ? true : (shopifyData?.availableForSale ?? variant.isAvailable),
        inventory: {
          quantity: shopifyData?.quantityAvailable ?? variant.inventory?.quantity,
        },
      }
    })

  const [selectedVariant, setSelectedVariant] = useState<CombinedShopifySanityVariant | null>(combinedVariants.length === 1 && combinedVariants[0] ? combinedVariants[0] : null)
  const [loadingAddToCart, setLoadingAddToCart] = useToggle(false)
  const [addToCartError, setAddToCartError] = useState<boolean | string>(false)
  const availableOptions = product.variants.filter(({ isAvailable }) => isAvailable).map(({ options }) => optionsToObject(options || []))
  const liveAvailableOptions = liveProductData?.variants.edges.filter(({ node }) => node.availableForSale)?.map(({ node }) => optionsToObject(node.selectedOptions))

  const handleAddToCart = async (sideEffect: 'drawer' | 'toast' = 'drawer') => {
    setAddToCartError(false)
    setLoadingAddToCart(true)
    const productHandle = `product-${slug}-${selectedVariant?.sku}`

    if (selectedVariant) {
      setAddToCartError(
        await add({
          lines: [{ quantity: 1, merchandiseId: selectedVariant.shopifyVariantId }],
          queryID: algoliaLastQueryId || undefined,
          handle: productHandle,
        }).then((e: unknown) => {
          if (!e) {
            return true
          }

          // Artificial delay so that the cart panel doesn't open in
          // a loading state, but the new product is already there
          // todo: implement eager loading for cart
          setTimeout(
            () =>
              sideEffect === 'drawer'
                ? openCart()
                : toast(translate('addToCartToastMessage', `${product.productTitle?.split('*')[1]} added to cart`)?.replace('{productName}', product.productTitle?.split('*')[1])),
            500,
          )

          trackEvent(ELEVAR_DATA_LAYER_EVENTS.DL_ADD_TO_CART, {
            user_properties: userProperties,
            ecommerce: ecommerceInfoAddToCart({
              slug,
              product,
              productMedia,
              locale,
              variant: selectedVariant,
              list: get('elevar-list') ?? '',
              context: displayShopTheLook.open ? 'shop_the_look' : 'product_form',
              currencyCode: cart?.cost?.totalAmount?.currencyCode,
            }),
          })

          // Algolia event to complete event tracking for Algolia user journey from PCP to PDP to ATC.
          // Note: the quick-ATC on the PCP has out-of-the-box Algolia event tracking using sendEvent(),
          // here we use a custom instantiated client to 'manually' push events to Algolia.
          // @see https://www.algolia.com/doc/guides/sending-events/instantsearch/send-ecommerce-events/?client=React+InstantSearch#on-pages-without-instantsearch-widgets
          const base = {
            index: algoliaLastIndexName || indexName,
            eventName: algoliaLastQueryId ? 'PDP Add to Cart' : 'PDP Add to Cart Without Query',
            currency: currencyCode,
            objectIDs: [productHandle],
            objectData: [
              {
                quantity: 1,
                price: selectedVariant?.price || 0,
                ...(algoliaLastQueryId && { queryID: algoliaLastQueryId }),
              },
            ],
            ...(customUserId && { userToken: customUserId }),
            ...(authenticatedUserToken && { authenticatedUserToken }),
          }

          if (algoliaLastQueryId) {
            algoliaInsightsClient('addedToCartObjectIDsAfterSearch', { ...base, queryID: algoliaLastQueryId })
          } else {
            algoliaInsightsClient('addedToCartObjectIDs', base)
          }

          if (onAddToCartComplete) {
            onAddToCartComplete()
          }

          return false
        }),
      )
    } else {
      setAddToCartError(translate('noVariantError', 'Please select a size'))
    }

    setLoadingAddToCart(false)
  }

  return {
    error: addToCartError ? (typeof addToCartError === 'string' ? addToCartError : translate('serverError', 'Something went wrong, please try again later.')) : undefined,
    loadingAddToCart,
    variants: combinedVariants,
    options: combineOptions(product.variants.flatMap(({ options }) => options)),
    availableOptions,
    liveAvailableOptions,
    separateVariants: product.variants,
    handleAddToCart,
    selectedVariant,
    setSelectedVariant,
  }
}

export default useProductForm
