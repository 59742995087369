import { FunctionComponent } from 'react'

type Props = {
  title: string
  count: number
}

export const ResultListTitle: FunctionComponent<Props> = ({ title, count }) => {
  return (
    <h4 className='text-body-xl-bold'>
      {title} <span className='font-normal text-secondary-disabled'>({count})</span>
    </h4>
  )
}
