import { FunctionComponent, useContext, useState } from 'react'
import MisterDialog from '../../shared/dialog/MisterDialog'
import SizeChartsListItem from './size-charts-list-item/size-charts-list-item'
import MisterContainer from '../../shared/MisterContainer'
import { GlobalsContext, useTranslations } from '../../../contexts/Globals.context'
import SizeChart from './collection-size-charts/collection-size-charts'
import { sort } from 'src/utils/array.util'
import { SectionSizeChartsData } from 'data-access/sanity/fragments/sections/sectionSizeCharts.fragment'
import { SizeGuideModal } from 'data-access/sanity/fragments/components/sizeCharts.fragment'

interface Props {
  data: SectionSizeChartsData
}

const SectionSizeCharts: FunctionComponent<Props> = ({ data }) => {
  const translate = useTranslations()
  const { stylesOrder } = useContext(GlobalsContext)
  const [sizeChartModalData, setSizeChartModalData] = useState<SizeGuideModal | null>(null)
  const filteredCollections = data?.sizeGuideModals?.filter((sizeChart) => sizeChart?.showInLocales !== false)
  const sortedCollections = stylesOrder.applyOrderTo?.sizeCharts ? sort(filteredCollections || [], stylesOrder.styles || []) : filteredCollections

  return (
    <MisterContainer dataLabel='section-size-charts' padding={false} type='small' className='relative mb-14 grid justify-center gap-2 sm:grid-cols-2 sm:gap-4' data-id={data._id}>
      {sizeChartModalData && (
        <MisterDialog
          isOpen={!!sizeChartModalData}
          onClose={() => setSizeChartModalData(null)}
          title={translate('sizeChart', 'Size chart')}
          ariaDescription={sizeChartModalData?.collectionTitle}
          showTitle={false}
        >
          <div className='size-full overflow-x-hidden'>
            <SizeChart data={sizeChartModalData} />
          </div>
        </MisterDialog>
      )}
      {sortedCollections?.map((collection) => {
        return (
          <SizeChartsListItem
            buttonText={data?.buttonText || translate('sizeCharts', 'Size charts')}
            key={collection?.collectionTitle}
            image={collection?.collectionBulletImage}
            collectionTitle={collection?.collectionTitle}
            onClick={() => setSizeChartModalData(collection)}
          />
        )
      })}
    </MisterContainer>
  )
}

export default SectionSizeCharts
