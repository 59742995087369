export const sortColors = (
  styleColors: {
    colorId: string
    [key: string]: any
  }[],
  colorOrder: string[],
) =>
  styleColors.sort(({ colorId: a }, { colorId: b }) => {
    // If a or colorId is undefined, we want to sort it to the end
    if (a === undefined) return 1
    if (b === undefined) return -1
    const indexA = colorOrder.indexOf(a)
    const indexB = colorOrder.indexOf(b)

    return indexA - indexB
  })
