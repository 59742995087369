import { FunctionComponent } from 'react'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { Button } from '@ui/components/ui/button'
import { useMobileMenu } from 'src/contexts/MobileMenu.context'
import MenuCardCta from '../MenuCardCta'
import { SecondaryMenuItem } from 'data-access'

const SecondaryMenu: FunctionComponent = () => {
  const { data, handleMobileMenuClose, currentSecondaryMenuItem } = useMobileMenu()
  const { secondarySubmenuItems, secondaryMenuCard } = (data.secondaryMenuItems?.[currentSecondaryMenuItem || 0] as SecondaryMenuItem) ?? {}
  return (
    <div className='flex h-full flex-col px-4 pt-10'>
      <ul className='flex flex-col'>
        {secondarySubmenuItems?.map((menuItem, i) => (
          <li key={i}>
            <Button onClick={handleMobileMenuClose} variant='ghost' className='py-3 pl-4 pr-0 text-btn-xl'>
              <MisterSiteLink link={menuItem}>{menuItem.linkText}</MisterSiteLink>
            </Button>
          </li>
        ))}
      </ul>
      {secondaryMenuCard && (
        <MenuCardCta title={secondaryMenuCard.title} buttonText={secondaryMenuCard?.pageLink.linkText} link={secondaryMenuCard.pageLink} image={secondaryMenuCard.image} />
      )}
    </div>
  )
}

export default SecondaryMenu
