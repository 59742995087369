import { SizeToggle } from '@ui/components/ui/size-toggle'
import { FC } from 'react'
import { RendererProps } from '../SearchFilterGroup'

const SizeRenderer: FC<RendererProps> = ({ label, value, isRefined, refine }) => {
  return (
    <SizeToggle pressed={isRefined} defaultPressed={isRefined} onPressedChange={() => refine(value)}>
      {label}
    </SizeToggle>
  )
}

export default SizeRenderer
