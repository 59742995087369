import { FunctionComponent, useState } from 'react'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@ui/components/ui/tooltip'
import { useCopyToClipboard, useToggle } from 'react-use'
import { Copy, LucideIcon } from 'lucide-react'
import { useTranslations } from 'src/contexts/Globals.context'

export interface MisterCopyIconProps {
  textToCopy: string
  className?: string
  tooltipText?: string
  CopyIcon?: LucideIcon
  iconSize?: number
}

const MisterCopyIcon: FunctionComponent<MisterCopyIconProps> = ({ textToCopy, className, tooltipText, CopyIcon = Copy, iconSize = 22 }) => {
  const [_, copyToClipboard] = useCopyToClipboard()
  const [copied, setCopied] = useToggle(false)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const translate = useTranslations()

  const copyTrackingNumber = () => {
    copyToClipboard(textToCopy)
    setCopied(true)
    setIsTooltipOpen(true)
    setTimeout(() => setCopied(false), 2000)
    setTimeout(() => setIsTooltipOpen(false), 1800)
  }

  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip open={isTooltipOpen} onOpenChange={setIsTooltipOpen}>
        <TooltipTrigger asChild>
          <button
            className={className}
            onClick={(e) => {
              e.preventDefault()
              copyTrackingNumber()
            }}
          >
            <CopyIcon size={iconSize} />
          </button>
        </TooltipTrigger>
        <TooltipContent>
          <span className='text-body-sm'>{copied ? `${translate('copied', 'Copied')}!` : tooltipText || translate('copyToClipboard', 'Copy to clipboard')}</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default MisterCopyIcon
