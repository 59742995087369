import { PRODUCT_CARD_PRODUCT_FRAGMENT } from 'data-access/sanity/fragments/components/productCardProduct.fragment'
import { coalesceFilter, coalesceQuery } from './sanity.util'
import { MEDIA_FRAGMENT } from 'data-access/sanity/fragments/components/media.fragment'
import { SECTION_TEXT_FRAGMENT } from 'data-access/sanity/fragments/sections/sectionText.fragment'
import { RICH_TEXT_FRAGMENT } from 'data-access/sanity/fragments/components/richText.fragment'
import { LINK_FRAGMENT } from 'data-access/sanity/fragments/components/link.fragment'

export const matchFilter = (field: string, searchTerm: string) => `
  ${field} match ["${searchTerm}*"] ||
  ${field} match ["*${searchTerm}"] ||
  ${field} match ["*${searchTerm}*"]`

const primaryCollection = `*[_type == 'collection' && ^._id in products[]._ref && isPrimary == true][0]`

export const quickSearchQuery = (searchTerm: string, locale: string, resultLimit: number) => `{
  "productResults": *[_type in ["product"] && showInLocales.${locale} != false && (${primaryCollection}.showInLocales.${locale}) != false && availableInShopify.${locale} != false && (
    ${matchFilter(`productTitle.${locale}`, searchTerm)}
  )][0...${resultLimit}] {
    _type,
    ${coalesceQuery('productTitle', locale, 'title')},
    "slug": slug.current,
  },
  "collectionResults": *[_type in ["collection"] && showInLocales.${locale} != false && (
    ${matchFilter(coalesceFilter('title', locale), searchTerm)}
  )][0...${resultLimit}] {
    _type,
    ${coalesceQuery('title', locale)},
    ${coalesceQuery('slug', locale)}.current,
  },
  "pageResults": *[_type in ["page"] && showInLocales.${locale} != false && (
    ${matchFilter(`seo.seoTitle.${locale}`, searchTerm)}
  )][0] {
    _type,
    ${coalesceQuery('seo.seoTitle', locale, 'title')},
    ${coalesceQuery('slug', locale)}.current,
  },
  "articleResults": *[_type in ["pageArticle"] && showInLocales.${locale} != false && (
  ${matchFilter(`title.${locale}`, searchTerm)}
  )][0] {
    _type,
    ${coalesceQuery('title', locale)},
    ${coalesceQuery('slug', locale)}.current,
  },
  "storyResults": *[_type in ["pageStory"] && showInLocales.${locale} != false && (
    ${matchFilter(`title.${locale}`, searchTerm)}
  )][0] {
    _type,
    ${coalesceQuery('title', locale)},
    ${coalesceQuery('slug', locale)}.current,
  },
}`

export const productQueryFilter = (locale: string, searchTerm: string) =>
  `*[_type in ["product"] && showInLocales.${locale} != false && (${primaryCollection}.showInLocales.${locale}) != false && availableInShopify.${locale} != false && (
    ${matchFilter(`productTitle.${locale}`, searchTerm)} ||
    ${matchFilter(`seo.seoTitle.${locale}`, searchTerm)} ||
    ${matchFilter(`seo.seoDescription.${locale}`, searchTerm)} ||
    ${matchFilter(`color->colorName.${locale}`, searchTerm)} ||
    ${matchFilter(`color->colorQuote.${locale}`, searchTerm)} ||
    ${matchFilter(`color->descriptiveName.${locale}`, searchTerm)}
  )]`

export const collectionQueryFilter = (locale: string, searchTerm: string) =>
  `*[_type in ["collection"] && showInLocales.${locale} != false && (
    ${matchFilter(coalesceFilter('title', locale), searchTerm)} ||
    ${matchFilter(`collectionDescription.${locale}`, searchTerm)} ||
    ${matchFilter(`seo.seoTitle.${locale}`, searchTerm)} ||
    ${matchFilter(`seo.seoDescription.${locale}`, searchTerm)}
  )]`

export const pageQueryFilter = (locale: string, searchTerm: string) =>
  `*[_type in ["page"] && showInLocales.${locale} != false && (
    ${matchFilter(`seo.seoTitle.${locale}`, searchTerm)} ||
    ${matchFilter(`seo.seoDescription.${locale}`, searchTerm)}
  )]`

export const pageArticleQueryFilter = (locale: string, searchTerm: string) =>
  `*[_type in ["pageArticle"] && showInLocales.${locale} != false && (
    ${matchFilter(`title.${locale}`, searchTerm)} ||
    ${matchFilter(`seo.seoTitle.${locale}`, searchTerm)} ||
    ${matchFilter(`seo.seoDescription.${locale}`, searchTerm)}
  )]`

export const storyQueryFilter = (locale: string, searchTerm: string) =>
  `*[_type in ["pageStory"] && showInLocales.${locale} != false && (
    ${matchFilter(`title.${locale}`, searchTerm)} ||
    ${matchFilter(`preTitle.${locale}`, searchTerm)}
  )]`

export const countQuery = (searchTerm: string, locale: string) => `{
  "productCount": count(${productQueryFilter(locale, searchTerm)}),
  "collectionCount": count(${collectionQueryFilter(locale, searchTerm)}),
  "pageCount": count(${pageQueryFilter(locale, searchTerm)}),
  "articleCount": count(${pageArticleQueryFilter(locale, searchTerm)}),
  "storyCount": count(${storyQueryFilter(locale, searchTerm)}),
}`

export const searchResultsQuery = (searchTerm: string, locale: string, paginateFrom: number, paginateTo: number, type: string) => {
  switch (type) {
    case 'product':
      return `
      {
        "results": ${productQueryFilter(locale, searchTerm)}[${paginateFrom}...${paginateTo}] {
          ${PRODUCT_CARD_PRODUCT_FRAGMENT(locale)}
        }
      }`
    case 'collection':
      return `
      {
        "results": ${collectionQueryFilter(locale, searchTerm)}[${paginateFrom}...${paginateTo}] {
          _type,
          ${coalesceQuery('title', locale)},
          ${coalesceQuery('slug', locale)},
          ${coalesceQuery('collectionDescription', locale)},
          "collectionBulletImage": bulletImage-> {
            ${MEDIA_FRAGMENT(locale)}
          },
        }
      }`
    case 'page':
      return `
      {
        "results": ${pageQueryFilter(locale, searchTerm)}[${paginateFrom}...${paginateTo}] {
          _type,
          ${coalesceQuery('seo.seoTitle', locale, 'title')},
          ${coalesceQuery('slug', locale)},
        }
      }`
    case 'pageArticle':
      return `
        {
          "results": ${pageArticleQueryFilter(locale, searchTerm)}[${paginateFrom}...${paginateTo}] {
            _type,
            _id,
            dateCreated,
            ${coalesceQuery('slug', locale)},
            ${coalesceQuery('title', locale)},
            defined(articleThumbnailImage) => {
              articleThumbnailImage {
                _type == 'reference' => @-> {
                  ${MEDIA_FRAGMENT(locale)}
                }
              }
            },
            "sections" : *[_type == "sectionText" && _id in ^.sections[]._ref] {
              ${SECTION_TEXT_FRAGMENT(locale)},
            },
          }
        }`
    case 'pageStory':
      return `
        {
          "results": ${storyQueryFilter(locale, searchTerm)}[${paginateFrom}...${paginateTo}] {
            _type,
            _id,
            ${coalesceQuery('slug', locale)},
            ${coalesceQuery('preTitle', locale)},
            ${coalesceQuery('title', locale)},
            defined(excerpt) => {${RICH_TEXT_FRAGMENT(locale, 'excerpt')}},
            defined(thumbnail) => {
              thumbnail {
                _type == 'reference' => @-> {
                  ${MEDIA_FRAGMENT(locale)}
                },
              }
            },
            link -> {
              ${LINK_FRAGMENT(locale)}
            },
          }
        }`
    default:
      return null
  }
}
export const updateRecentSearches = ({
  searchTerm,
  action,
  recentlySearchedTerms,
  index,
  setRecentlySearchedTerms,
}: {
  searchTerm: string
  setRecentlySearchedTerms: (terms: string[]) => void
  action: string
  recentlySearchedTerms: string[]
  index?: number
}) => {
  const strippedSearchTerm = searchTerm.trim()
  switch (action) {
    case 'search':
      if (!recentlySearchedTerms.length) {
        setRecentlySearchedTerms([strippedSearchTerm])
        return // Exit the function after initializing the array
      }

      // Check if the search term already exists in the array
      if (recentlySearchedTerms.includes(strippedSearchTerm)) {
        return // Exit the function if the term already exists
      }

      const termsToPush = [...recentlySearchedTerms] // Make a copy of the array to avoid mutating state directly

      // If the length is already 5, remove the oldest term (at the end)
      if (termsToPush.length >= 5) {
        termsToPush.pop()
      }

      // Add the new search term to the front of the array
      termsToPush.unshift(strippedSearchTerm)

      // Update the state with the new array
      setRecentlySearchedTerms(termsToPush)
      break

    case 'remove':
      if (index !== undefined && index >= 0 && index < recentlySearchedTerms.length) {
        const termsToRemove = [...recentlySearchedTerms]
        termsToRemove.splice(index, 1)
        setRecentlySearchedTerms(termsToRemove)
      }
      break

    case 'update':
      if (index !== undefined && index >= 0 && index < recentlySearchedTerms.length) {
        const termsToUpdate = [...recentlySearchedTerms]
        termsToUpdate[index] = strippedSearchTerm
        setRecentlySearchedTerms(termsToUpdate)
      }
      break
    case 'clear':
      setRecentlySearchedTerms([])

      break

    default:
      break
  }
}
