import * as React from 'react'

import { cn } from '@ui/lib/utils'
import { Label } from './label'
import { Input } from './input'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  placeholder?: string
}

const InputFloatingLabel = React.forwardRef<HTMLInputElement, InputProps>(({ className, label, placeholder = ' ', ...props }, ref) => {
  return (
    <div className='relative w-full'>
      <Input placeholder={placeholder} type={props.type || 'text'} className={cn('peer pb-2 pt-6', className)} ref={ref} aria-label={label} {...props} />
      <Label className='pointer-events-none absolute start-3 top-2 translate-y-0 text-body-sm text-brand-grey-dark transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-body-lg peer-focus:top-2 peer-focus:translate-y-0 peer-focus:text-body-sm lg:text-body-sm'>
        {label}
      </Label>
    </div>
  )
})
InputFloatingLabel.displayName = 'InputFloatingLabel'

export { InputFloatingLabel }
