import React, { FunctionComponent } from 'react'

const TinyChevron: FunctionComponent<{ className?: string }> = ({ className }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' fill='currentColor' className={className}>
      <path fill='currentColor' d='m6.354 7.646 2.792-2.792A.5.5 0 0 0 8.793 4H3.207a.5.5 0 0 0-.353.854l2.792 2.792a.5.5 0 0 0 .708 0Z' />
    </svg>
  )
}

export default TinyChevron
