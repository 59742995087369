import React, { FunctionComponent, TouchEventHandler, useRef } from 'react'
import useCart from '../../../hooks/shop/useCart'
import MisterProductCardImage from './MisterProductCardImage'
import MisterWishListButton from './MisterWishListButton'
import { cn } from 'ui/lib/utils'
import { ELEVAR_DATA_LAYER_EVENTS, trackEvent } from '../../../utils/telemetry.util'
import { ecommerceInfoSelectItem } from '../../../utils/elevar.util'
import MisterProductInfo from './MisterProductInfo'
import MisterBadge from './MisterBadge'
import useProductsAvailability from 'src/hooks/shop/useProductsAvailability'
import { productTitle as productTitleHandler } from '../../../utils/product.util'
import MisterQuickAddToCart from './MisterQuickAddToCart'
import { useTelemetry } from '../../../contexts/Telemetry.context'
import Link from 'next/link'
import { ProductCardProduct } from 'data-access/sanity/fragments/components/productCardProduct.fragment'
import { useExperimentFeatureFlag } from 'src/hooks/posthog/useExperimentFeatureFlag'

interface MisterProductCardProps {
  product: ProductCardProduct
  quickAddToCart?: boolean
  wishList?: boolean
  onClick?: () => void
  onClickOverride?: () => void
  onTouchStart?: TouchEventHandler
  onAddToCart?: () => void
  singleView?: boolean
  index: number
  category?: string
  itemProp?: string
  imageSizes: string
  addToCartToast?: boolean
  modelImageFirst?: boolean
  className?: string
  // Used for Algolia telemetry
  objectID?: string
  position?: number
  queryID?: string
  variantSku?: string // If provided, we use this to get the product availability from Shopify
  squareImage?: boolean
}

const MisterProductCard: FunctionComponent<MisterProductCardProps> = ({
  product,
  index,
  category,
  quickAddToCart = false,
  wishList = false,
  onClick,
  onClickOverride,
  onAddToCart,
  itemProp,
  imageSizes,
  addToCartToast = false,
  className,
  objectID,
  position,
  queryID,
  variantSku,
  squareImage,
}) => {
  const isGiftCard = product?.slug?.current?.includes('gift')
  const { cart } = useCart()
  const ref = useRef<HTMLLIElement>(null)
  const { userProperties } = useTelemetry()

  const { slug, shopifyProductId, isAvailable: shopifyProductAvailable, price, discontinued, badges, productTitle } = product || {}

  const Component = onClickOverride ? 'button' : Link
  const liveProductData = useProductsAvailability(shopifyProductId ? [shopifyProductId] : null)

  const { title, category: subtitle } = productTitleHandler(productTitle, true)
  const linkTitle = subtitle ? `${title} - ${subtitle}` : title

  // This handles displaying the stock status directly from Shopify with a fallback of Sanity
  const productIsAvailable = isGiftCard
    ? true
    : liveProductData?.variants?.edges
      ? // The MisterSearchProductCard component passes a variantSku to the product card to be used to check the stock status
        variantSku
        ? liveProductData.variants.edges.some(({ node }) => node.sku === variantSku && node.quantityAvailable > 0)
        : // Otherwise, we check if any variant is in stock
          liveProductData.variants.edges.some(({ node }) => node.quantityAvailable > 0)
      : shopifyProductAvailable

  // A/B test Show model shot for trousers
  // @see https://eu.posthog.com/project/12378/experiments/25715
  const flag = 'model-shot-trousers'
  const variant: 'control' | 'model-shot' | string | boolean | undefined = useExperimentFeatureFlag(flag, undefined, false)

  const onClickProduct = (index: number) => {
    onClick?.()

    trackEvent(ELEVAR_DATA_LAYER_EVENTS.DL_SELECT_ITEM, {
      user_properties: userProperties,
      ecommerce: ecommerceInfoSelectItem(product, index, cart?.cost?.totalAmount?.currencyCode || ''),
    })
  }

  const modelShotFirst = product.primaryCollection?.productType === 'tops' || (product.primaryCollection?.productType === 'trousers' && variant === 'model-shot')
  const [packShot, defaultModelShot] = product?.images ?? []
  const modelShot = modelShotFirst && defaultModelShot ? product.modelShot || defaultModelShot : defaultModelShot
  const productCardImage = modelShot && modelShotFirst ? modelShot : packShot
  const hoverImage = modelShot && (modelShotFirst ? packShot : modelShot)
  const crop = modelShotFirst && !product.modelShot && modelShot && product.primaryCollection?.productType === 'trousers'

  return (
    <li
      ref={ref}
      className={cn('group/product relative h-full list-none overflow-hidden @container/product-card [container-type:inline-size]', className)}
      itemType='https://schema.org/Product'
      itemScope
      itemProp={itemProp || ''}
      data-testid='product-card'
      data-insights-object-id={objectID ?? undefined} // Algolia data attr
      data-insights-position={position ?? undefined} // Algolia data attr
      data-insights-query-id={queryID ?? undefined} // Algolia data attr
    >
      <meta itemProp='mpn' content={slug?.current} />
      <meta itemProp='name' content={linkTitle} />
      <meta itemProp='brand' content='MR MARVIS' />
      <meta itemProp='description' content={[linkTitle, 'MR MARVIS', category].filter(Boolean).join(' - ')} />
      <Component
        href={`/products/${slug.current}`}
        title={linkTitle}
        onClick={() => (onClickOverride ? onClickOverride() : onClickProduct(index))}
        itemProp='url'
        className='absolute inset-0 z-productCardLink cursor-pointer'
      />
      <div className='relative w-full'>
        <div className='relative overflow-hidden rounded-md bg-brand-grey'>
          {productCardImage && (
            <div className={cn('grid grid-cols-1 grid-rows-1', !productIsAvailable && 'opacity-50', squareImage && 'aspect-square')}>
              <div
                className={cn(
                  'col-span-1 col-start-1 row-span-1 row-start-1',
                  hoverImage && 'transition-[opacity,transform] duration-200 ease-out group-hover/product:scale-[94%] group-hover/product:opacity-0',
                )}
              >
                <MisterProductCardImage crop={crop} image={productCardImage} priority={index === 0} alt={linkTitle} sizes={imageSizes} className='object-contain' />
              </div>
              <div className='col-span-1 col-start-1 row-span-1 row-start-1 scale-[106%] opacity-0 transition-[opacity,transform] duration-200 ease-out group-hover/product:scale-100 group-hover/product:opacity-100'>
                {hoverImage && <MisterProductCardImage image={hoverImage} priority={false} alt={linkTitle} sizes={imageSizes} className='object-contain' />}
              </div>
            </div>
          )}
          {wishList && <MisterWishListButton product={product} />}
          {quickAddToCart && productIsAvailable && (
            <MisterQuickAddToCart product={product} productMedia={product.images} liveProductData={liveProductData} addToCartToast={addToCartToast} onAddToCart={onAddToCart} />
          )}
        </div>
        <MisterBadge badges={badges} discontinued={discontinued} productIsAvailable={productIsAvailable} />
      </div>

      <MisterProductInfo title={title} subtitle={subtitle} price={price} productIsAvailable={productIsAvailable} showPrice={!isGiftCard} productId={product._id} />
    </li>
  )
}

export default MisterProductCard
