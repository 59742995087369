import MisterInput from '../../../../shared/input/MisterInput'
import useCustomerAddress from '../../../../../hooks/customer/useCustomerAddress'
import { cn } from 'ui/lib/utils'
import { useTranslations } from '../../../../../contexts/Globals.context'
import { FormEvent, FunctionComponent, useEffect, useState } from 'react'
import { ContextCustomer } from '@/types/contextCustomer'
import useCustomer from 'src/hooks/customer/useCustomer'
import MisterDropdown from 'src/components/shared/input/MisterDropdown'
import { Button } from '@ui/components/ui/button'
import { LoaderCircle } from 'lucide-react'
import { AccountData } from 'data-access/sanity/fragments/globals/account.fragment'
import { getAvailableCountries } from 'data-access'
import { useRouter } from 'next/router'
import { AvailableCountriesDto } from 'data-access/shopify/fragments/customer/availableCountriesQuery.fragment'

interface AddressFormProps {
  inputFields: { [key: string]: any }
  accountData: AccountData
  updateFields: (e: any) => void
  customer: ContextCustomer
}

const AddressForm: FunctionComponent<AddressFormProps> = ({ inputFields, accountData, updateFields, customer }) => {
  const { customer: ironCustomer } = useCustomer()
  const translate = useTranslations()
  const { city, country, postalCode, streetName, userInfoChangeSuccessMesssage } = accountData
  const {
    updateAddress,
    state: { response, loading, error },
  } = useCustomerAddress()

  const [countries, setCountries] = useState<AvailableCountriesDto['data']['localization']>({
    availableCountries: ['Belgium', 'Denmark', 'France', 'Germany', 'Italy', 'Netherlands', 'Spain', 'United Kingdom', 'United States'].map((country) => ({ name: country })),
    country: { name: inputFields?.country },
  })
  const { locale } = useRouter()
  useEffect(() => {
    const fetchData = async () => {
      if (locale) {
        try {
          const response = await getAvailableCountries(locale)
          setCountries(response)
        } catch (error) {
          console.error('Error fetching countries:', error)
        }
      }
    }
    fetchData()
  }, [locale])

  if (!ironCustomer.isLoggedIn) {
    return null
  }

  const handleEditAddress = (e: FormEvent) => {
    e.preventDefault()

    updateAddress({
      customerAccessToken: ironCustomer?.customerAccessToken,
      id: customer?.defaultAddress?.id,
      address: {
        firstName: inputFields?.firstNameAddress,
        lastName: inputFields?.lastNameAddress,
        address1: inputFields?.streetName,
        city: inputFields?.city,
        country: inputFields?.country,
        zip: inputFields?.postalCode,
      },
    })
  }

  return (
    <form onSubmit={(e) => handleEditAddress(e)} className='mb-8 grid w-full max-w-2xl gap-x-4 lg:w-[57%] lg:grid-cols-4'>
      <MisterInput
        wrapperClassName='col-span-4 lg:col-span-2'
        inputClassName='!bg-brand-grey'
        type='text'
        label={translate('firstName')}
        name='firstNameAddress'
        id='firstNameAddress'
        placeholder={translate('firstName')}
        onChangeHandler={updateFields}
        value={inputFields?.firstNameAddress}
        autoComplete='given-name'
        maskForAnalytics={true}
      />
      <MisterInput
        wrapperClassName='col-span-4 lg:col-span-2'
        inputClassName='!bg-brand-grey'
        type='text'
        label={translate('lastName')}
        name='lastNameAddress'
        id='lastNameAddress'
        placeholder={translate('lastName')}
        onChangeHandler={updateFields}
        value={inputFields?.lastNameAddress}
        autoComplete='family-name'
        maskForAnalytics={true}
      />
      <MisterInput
        wrapperClassName='col-span-4'
        inputClassName='!bg-brand-grey'
        type='text'
        label={streetName}
        name='streetName'
        id='streetName'
        placeholder={streetName}
        onChangeHandler={updateFields}
        value={inputFields?.streetName}
        required={true}
        autoComplete='address-line1'
        maskForAnalytics={true}
      />
      <MisterInput
        wrapperClassName='col-span-2 lg:col-span-2'
        inputClassName='!bg-brand-grey'
        type='text'
        label={postalCode}
        name='postalCode'
        id='postalCode'
        placeholder={postalCode}
        onChangeHandler={updateFields}
        value={inputFields?.postalCode}
        required={true}
        autoComplete='postal-code'
        maskForAnalytics={true}
      />
      <MisterInput
        wrapperClassName='col-span-2 lg:col-span-2'
        inputClassName='!bg-brand-grey'
        type='text'
        label={city}
        name='city'
        id='city'
        placeholder={city}
        onChangeHandler={updateFields}
        value={inputFields?.city}
        required={true}
        autoComplete='address-level2'
        maskForAnalytics={true}
      />

      <MisterDropdown
        wrapperClassName='col-span-4'
        value={inputFields?.isoCode || inputFields?.country}
        name='country'
        id='country'
        label={country}
        onChangeHandler={(e) => {
          updateFields({ ['country']: e?.target?.value })
          updateFields({ ['isoCode']: e?.target?.value })
        }}
        options={countries?.availableCountries.map((country) => {
          return { label: country.name, value: country.isoCode }
        })}
        primaryOptions={countries?.country && [{ label: countries?.country?.name, value: countries?.country?.isoCode }]}
        inForm
      />

      <div className='col-span-2'>
        <div className={cn(response ? 'mt-4 block text-body-sm lg:mt-0' : 'hidden')}>{userInfoChangeSuccessMesssage}</div>
      </div>
      <div className='col-span-4 flex justify-end'>
        <Button
          size='md'
          type='submit'
          className='flex gap-2'
          disabled={
            customer?.defaultAddress?.firstName === inputFields?.firstNameAddress &&
            customer?.defaultAddress?.lastName === inputFields?.lastNameAddress &&
            customer?.defaultAddress?.address1 === inputFields?.streetName &&
            customer?.defaultAddress?.city === inputFields?.city &&
            customer?.defaultAddress?.country === inputFields?.country &&
            customer?.defaultAddress?.zip === inputFields?.postalCode
          }
        >
          {loading && <LoaderCircle className='size-4 animate-spin' />}
          {accountData?.saveAddress}
        </Button>
      </div>
      {error && <div className='mt-4 block text-brand-warning lg:mt-0'>{error}</div>}
    </form>
  )
}

export default AddressForm
