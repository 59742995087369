'use client'

import * as TogglePrimitive from '@radix-ui/react-toggle'
import { cn } from '@ui/lib/utils'
import { cva, VariantProps } from 'class-variance-authority'
import * as React from 'react'

const sizeToggleVariants = cva(
  'inline-flex items-center justify-center rounded-md border-[1.5px] border-brand-blue-light bg-transparent text-btn-sm ring-offset-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:text-brand-grey-dark disabled:opacity-50',
  {
    variants: {
      size: {
        default: 'px-4 py-2.5',
        md: 'px-3 py-2.5',
        sm: 'px-2.5 py-1.5',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
)

const SizeToggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> & VariantProps<typeof sizeToggleVariants>
>(({ size, className, ...props }, ref) => (
  <TogglePrimitive.Root
    ref={ref}
    className={cn(
      sizeToggleVariants({ size }),
      'data-[state=on]:border-primary data-[state=on]:bg-primary data-[state=on]:text-primary-foreground',
      'fine-pointer:hover:border-brand-grey-dark fine-pointer:hover:bg-brand-grey-dark fine-pointer:hover:text-white',
      'fine-pointer:data-[state=on]:hover:border-primary fine-pointer:data-[state=on]:hover:bg-primary fine-pointer:data-[state=on]:hover:text-primary-foreground',
      className,
    )}
    {...props}
  />
))

SizeToggle.displayName = TogglePrimitive.Root.displayName

export { SizeToggle }
