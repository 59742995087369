import { useAlgoliaSearch } from 'src/contexts/AlgoliaSearch.context'

import { useTranslations } from 'src/contexts/Globals.context'
import { ResultListTitle } from './ResultListTitle'
import { AlgoliaPageHit } from 'src/utils/algolia.util'
import { FunctionComponent } from 'react'
import { MisterContentResult } from './MisterContentResult'
import { Button } from '@ui/components/ui/button'
import { PlusIcon } from 'lucide-react'
import { useHits } from 'react-instantsearch'
import { useUI } from 'src/contexts/UI.context'

type Props = {
  items: AlgoliaPageHit[]
}

export const PageResultsList: FunctionComponent<Props> = ({ items }) => {
  const { hideSearchUI } = useUI()
  const { limitPagesBy, setLimitPagesBy } = useAlgoliaSearch()
  const { sendEvent } = useHits<AlgoliaPageHit>()
  const translate = useTranslations()
  const FAQCount = items.length

  if (FAQCount === 0) {
    return null
  }

  const hasMorePages = FAQCount > limitPagesBy

  const handleShowMore = () => {
    setLimitPagesBy(limitPagesBy * 2)
  }

  return (
    <div className='flex flex-col gap-y-6'>
      <ResultListTitle title={translate('searchPageResultsTitle', 'Pages')} count={FAQCount} />
      <ol className={'grid w-full grid-cols-1 gap-x-3 gap-y-6 md:grid-cols-2 lg:grid-cols-4 lg:gap-3'}>
        {items.slice(0, limitPagesBy).map((item) => (
          <MisterContentResult
            onClick={() => {
              sendEvent('click', item, 'Search Page Clicked')
              hideSearchUI()
            }}
            key={`faq-result-${item._id}`}
            item={item}
          />
        ))}
      </ol>
      {hasMorePages && (
        <div className='col-span-2 mx-auto w-fit'>
          <Button hover='expandIcon' Icon={PlusIcon} variant='outline' onClick={handleShowMore}>
            {translate('showMore', 'Show more')}
          </Button>
        </div>
      )}
    </div>
  )
}
