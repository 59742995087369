import React, { FunctionComponent, useEffect, useState } from 'react'

export interface MisterHoverDelayProps {
  children: React.ReactNode
  delay: number
}

const MisterHoverDelay: FunctionComponent<MisterHoverDelayProps> = ({ children, delay }) => {
  const [allowHover, setAllowHover] = useState(false)
  let timeoutId: NodeJS.Timeout | null = null

  const startAllowHover = () => {
    timeoutId = setTimeout(() => setAllowHover(true), delay)
  }

  const clearAllowHover = () => {
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }
    setAllowHover(false)
  }

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [])

  return (
    <div onMouseEnter={startAllowHover} onMouseLeave={clearAllowHover}>
      <div className={allowHover ? 'pointer-events-auto' : 'pointer-events-none'}>{children}</div>
    </div>
  )
}

export default MisterHoverDelay
