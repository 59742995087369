import { FC, ReactNode, useEffect, useState } from 'react'
import { useInstantSearch } from 'react-instantsearch'

const MisterNoResultsBoundary: FC<{ children: ReactNode; fallback: ReactNode }> = ({ children, fallback }) => {
  const { results, status } = useInstantSearch()
  const [shouldShowNoResults, setShouldShowNoResults] = useState(false)

  useEffect(() => {
    // Reset state when search status changes to loading
    if (status === 'loading') {
      setShouldShowNoResults(false)
      return
    }

    // Update no results state only when search is complete
    if (status === 'idle') {
      setShouldShowNoResults(results.query !== '' && !results?.__isArtificial && results?.nbHits === 0)
      return
    }

    // Cleanup function
    return () => {
      setShouldShowNoResults(false)
    }
  }, [results, status])

  if (shouldShowNoResults) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    )
  }

  return children
}

export default MisterNoResultsBoundary
