import { TriangleAlert } from 'lucide-react'
import { FunctionComponent } from 'react'
import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from '../../../utils/telemetry.util'
import { useRouter } from 'next/router'
import { useReadyStateEffect } from '../../../hooks/utils/useReadyStateEffect'
import MisterContainer from 'src/components/shared/MisterContainer'
import { Button } from '@ui/components/ui/button'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { SectionErrorData } from 'data-access/sanity/fragments/sections/sectionError.fragment'

interface Props {
  data: SectionErrorData
}

const SectionError: FunctionComponent<Props> = ({ data }) => {
  const { title, description, cta } = data || {}
  const { buttonText, link, variant } = cta || {}

  const { locale, pathname, asPath, basePath, isReady } = useRouter()

  useReadyStateEffect(() => {
    if (!isReady) {
      return
    }

    setTimeout(() => {
      trackEvent(TELEMETRY_DATA_LAYER_EVENTS.ERROR_PAGE, {
        errorPage: {
          locale,
          pathname,
          asPath,
          basePath,
        },
      })
    }, 1000)
  }, [isReady, locale, pathname, asPath, basePath])

  return (
    <MisterContainer type='fluid' dataLabel='section-error' as='div' data-id={data._id}>
      <div className='container flex w-full flex-col items-start space-y-6 px-4 py-10 sm:mx-auto sm:items-center sm:text-center lg:py-16 2xl:py-32'>
        <TriangleAlert className='size-16 lg:mx-auto' />
        <h1 className='text-heading-3 font-bold sm:text-heading-1'>{title}</h1>
        <p>{description}</p>
        {cta && (
          <Button variant={variant}>
            <MisterSiteLink link={link}>{buttonText}</MisterSiteLink>
          </Button>
        )}
      </div>
    </MisterContainer>
  )
}

export default SectionError
