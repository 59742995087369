'use client'

import * as React from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider'

import { cn } from '@ui/lib/utils'
import { SliderThumb } from './slider'

const DoubleSlider = React.forwardRef<React.ElementRef<typeof SliderPrimitive.Root>, React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>>(
  ({ className, ...props }, ref) => (
    <SliderPrimitive.Root ref={ref} className={cn('relative flex w-full touch-none select-none items-center', className)} {...props}>
      <SliderPrimitive.Track className='relative h-0.5 w-full grow overflow-hidden rounded-full bg-brand-blue-light'>
        <SliderPrimitive.Range className='absolute h-full bg-primary' />
      </SliderPrimitive.Track>
      <SliderThumb />
      <SliderThumb />
    </SliderPrimitive.Root>
  ),
)
DoubleSlider.displayName = SliderPrimitive.Root.displayName

export { DoubleSlider }
