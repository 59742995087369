import { Button } from '@ui/components/ui/button'
import { cn } from '@ui/lib/utils'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import MisterMedia from '../../shared/media/MisterMedia'
import MisterIcon from '../../shared/MisterIcon'
import { OverviewCardProps, SectionProps } from './section-overview-cards'
import { GlobalsContext } from 'src/contexts/Globals.context'
import { sort } from 'src/utils/array.util'

const OverviewPill: FunctionComponent<OverviewCardProps> = ({ slug, title, bulletImage, className }) => {
  return (
    <li>
      <Button variant='pill' className={cn('', className)} asChild>
        <Link onClick={() => trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_CATEGORY_PILL, { clickTitle: title, clickDestination: slug })} href={slug} title={title}>
          <div className='flex size-12 items-center justify-center rounded-full'>
            <MisterMedia className='rounded-full' media={bulletImage} />
          </div>
          <span>{title}</span>
        </Link>
      </Button>
    </li>
  )
}

const OverviewPills: FunctionComponent<SectionProps> = ({ data }) => {
  const {
    query: { collection },
  } = useRouter()
  const [isOverflowing, setIsOverflowing] = useState(false)
  const ulRef = useRef<HTMLUListElement>(null)
  const displayCollection = data.collections[0]
  const { stylesOrder } = useContext(GlobalsContext)

  const currentPathMatchesSlug = useCallback(
    (slug: string) => {
      return collection === slug
    },
    [collection],
  )

  useEffect(() => {
    if (ulRef.current) {
      const ulWidth = ulRef.current.scrollWidth
      const viewportWidth = window.innerWidth
      setIsOverflowing(ulWidth > viewportWidth)
    }
  }, [data])

  if (!displayCollection) {
    return null
  }

  return (
    <div className='relative snap-x snap-mandatory overflow-x-scroll px-4 scrollbar-hide lg:px-8'>
      <ul ref={ulRef} className={cn('flex list-none gap-x-2', isOverflowing && 'animate-nudge')}>
        {displayCollection && displayCollection.slug && displayCollection.title && (
          <li>
            <Link
              onClick={() => trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_CATEGORY_PILL, { clickTitle: displayCollection?.title, clickDestination: displayCollection?.slug })}
              href={displayCollection.slug}
              title={displayCollection.title}
            >
              <Button variant='pill'>
                <div className='flex size-12 items-center justify-center rounded-full bg-primary'>
                  <MisterIcon type='chevron-left' className='w-5 text-white' />
                </div>
                <span>{displayCollection.title}</span>
              </Button>
            </Link>
          </li>
        )}
        {(stylesOrder.applyOrderTo?.pcpCollectionCards ? sort(displayCollection?.data, stylesOrder.styles || []) : displayCollection?.data).map((col, index) => (
          <OverviewPill
            key={`overview-pill-${index}`}
            {...col}
            className={cn(currentPathMatchesSlug(col.slug) && 'bg-primary text-primary-foreground hover:border-brand-beige-light hover:bg-primary')}
          />
        ))}
      </ul>
    </div>
  )
}

export default OverviewPills
