import { useHits } from 'react-instantsearch'
import { FunctionComponent } from 'react'

import MisterNoResultsBoundary from './MisterNoResultsBoundary'
import { ProductResultsList } from './ProductResultsList'
import { CollectionResultsList } from './CollectionResultsList'
import { ArticleResultsList } from './ArticleResultsList'
import { FAQResultsList } from './FAQResultsList'
import { PageResultsList } from './PageResultsList'
import { AlgoliaArticleHit, AlgoliaCollectionHit, AlgoliaFAQHit, AlgoliaPageHit, AlgoliaProductHit } from 'src/utils/algolia.util'
import { cn } from '@ui/lib/utils'
import { MisterNoResults } from './MisterNoResults'
import MisterTrendingItems from './MisterTrendingItems'
import { useTranslations } from 'src/contexts/Globals.context'

type Props = {
  className?: string
}

const MisterCustomHits: FunctionComponent<Props> = ({ className }) => {
  const { items } = useHits<AlgoliaProductHit | AlgoliaCollectionHit | AlgoliaArticleHit | AlgoliaFAQHit | AlgoliaPageHit>()

  return (
    <div key={'customHits'} className={cn('relative', className)}>
      <MisterNoResultsBoundary fallback={<MisterNoResults />}>
        <div className='mb-10 mt-4'>
          <div className='flex flex-col gap-y-4 lg:gap-y-8'>
            <ProductResultsList items={(items as AlgoliaProductHit[]).filter((item) => item._type === 'product')} />
            <CollectionResultsList items={(items as AlgoliaCollectionHit[]).filter((item) => item._type === 'collection')} />
            <ArticleResultsList items={(items as AlgoliaArticleHit[]).filter((item) => item._type === 'pageArticle')} />
            <FAQResultsList items={(items as AlgoliaFAQHit[]).filter((item) => item._type === 'faqQuestion')} />
            <PageResultsList items={(items as AlgoliaPageHit[]).filter((item) => item._type === 'page')} />
          </div>
        </div>
      </MisterNoResultsBoundary>
    </div>
  )
}

export default MisterCustomHits
