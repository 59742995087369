import { createContext, FunctionComponent, PropsWithChildren, useContext, useMemo } from 'react'
import { contextualizedValue, SectionLayoutData } from 'data-access/sanity/fragments/sections/sectionLayout.fragment'

export const SectionLayoutContext = createContext<SectionLayoutData | null>(null)

export const SectionLayoutContextProvider: FunctionComponent<PropsWithChildren<{ data: SectionLayoutData }>> = ({ data, children }) => (
  <SectionLayoutContext.Provider value={data}>{children}</SectionLayoutContext.Provider>
)

type BreakpointName = 'base' | 'md' | 'lg'
type ResponsiveValue = Record<BreakpointName, number>
const BREAKPOINTS = ['base', 'md', 'lg'] as const
const SCREEN_WIDTHS = { base: 480, md: 768, lg: 1024 }

type SectionWithOrder = {
  columnSpan: number
  order?: number
  originalIndex: number
}

export const useSectionLayout = (sectionId: string) => {
  const data = useContext(SectionLayoutContext)

  return useMemo(() => {
    if (!data) return null

    const section = data.sections?.find((section) => section._id === sectionId)
    if (!section) return null

    const responsiveValues = (array: contextualizedValue[]): ResponsiveValue => {
      return BREAKPOINTS.reduce(
        (acc, size, i) => ({
          ...acc,
          [size]: array.find(({ name }) => name === size)?.value ?? (i === 0 ? 1 : (acc[BREAKPOINTS[i - 1] as BreakpointName] ?? 1)),
        }),
        { base: 1, md: 1, lg: 1 } as ResponsiveValue,
      )
    }

    const isInFirstColumn = (targetIndex?: number, totalColumns = 4, items?: SectionWithOrder[], targetColSpan = 1): boolean => {
      if (!items || targetIndex === undefined) return false

      // Sort items by order, keeping original order for items without an order value
      const sortedItems = [...items].sort((a, b) => {
        if (a.order === undefined && b.order === undefined) {
          return a.originalIndex - b.originalIndex
        }
        if (a.order === undefined) return 1
        if (b.order === undefined) return -1
        return a.order - b.order
      })

      // Find where our target section ended up in the sorted order
      const sortedTargetIndex = sortedItems.findIndex((item) => item.originalIndex === targetIndex)
      if (sortedTargetIndex === -1) return false

      let currentColumn = 0

      // Process all sections before our target in the sorted order
      for (let i = 0; i < sortedTargetIndex; i++) {
        const itemSpan = sortedItems[i]?.columnSpan || 1 // Default to 1 if not specified

        // If there's not enough space in current row, wrap to next row
        const remainingInRow = totalColumns - currentColumn
        if (remainingInRow < itemSpan) {
          currentColumn = 0
        }

        currentColumn = (currentColumn + itemSpan) % totalColumns
      }

      // Check if target section needs to wrap
      const remainingInRow = totalColumns - currentColumn
      if (remainingInRow < targetColSpan) {
        currentColumn = 0
      }

      return currentColumn === 0
    }

    const calculateSizes = (columns: ResponsiveValue, colSpan: ResponsiveValue): string =>
      Object.entries(SCREEN_WIDTHS)
        .map(([size, width]) => `(min-width: ${width}px) ${Math.floor((width / columns[size as BreakpointName]) * colSpan[size as BreakpointName] * 1.5)}px`)
        .concat('100vw')
        .join(', ')

    const sectionIndex = data.sections?.indexOf(section)
    const layoutColumns = responsiveValues(data.columns)

    const layoutDataForSection = {
      columns: layoutColumns,
      sectionSizes: {
        colSpan: responsiveValues(section.colSpan),
        rowSpan: responsiveValues(section.rowSpan),
      },
      isInFirstColumn: BREAKPOINTS.reduce(
        (acc, breakpoint) => ({
          ...acc,
          [breakpoint]: isInFirstColumn(
            sectionIndex,
            layoutColumns[breakpoint],
            data.sections?.map((section, index) => ({
              columnSpan: responsiveValues(section.colSpan)[breakpoint],
              order: section.orderOverride?.find((o) => o.name === breakpoint)?.value,
              originalIndex: index,
            })),
            responsiveValues(section.colSpan)[breakpoint],
          ),
        }),
        {} as Record<BreakpointName, boolean>,
      ),
    }

    const sizes = calculateSizes(layoutDataForSection.columns, layoutDataForSection.sectionSizes.colSpan)

    return {
      layoutDataForSection,
      sizes,
    }
  }, [data, sectionId])
}
