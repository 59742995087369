import { FunctionComponent, useEffect, useState } from 'react'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@ui/components/ui/accordion'
import RichText from 'src/components/shared/rich-text/rich-text'
import MisterInputSearchBar from 'src/components/shared/input/MisterInputSearchBar'
import MisterButton from 'src/components/shared/button/MisterButton'
import { useTranslations } from 'src/contexts/Globals.context'
import { cn } from '@ui/lib/utils'
import { useSearchParams } from 'next/navigation'
import MisterCopyIcon from 'src/components/shared/button/MisterCopyIcon'
import { Link2 } from 'lucide-react'
import { useGlobalTopMargin } from 'src/components/globalState/MisterScrollMargin'
import { FaqQuestion, FaqTopic, SectionFaqData } from 'data-access/sanity/fragments/sections/sectionFaq.fragment'

interface Props {
  data: SectionFaqData
}

export interface FilteredFaqQuestion extends FaqQuestion {
  isFiltered: boolean
}

export interface FilteredFaqTopic extends FaqTopic {
  isFiltered: boolean
}

const extractTextFromPortableText = (blocks: any[]): string => {
  return blocks
    .map((block) => {
      if (block._type === 'span') {
        return block.text
      } else if (block.children) {
        return extractTextFromPortableText(block.children)
      }
      return ''
    })
    .join(' ')
}

const SectionFaq: FunctionComponent<Props> = ({ data }) => {
  const translate = useTranslations()
  const [searchQuery, setSearchQuery] = useState('')
  const [activeAccordion, setActiveAccordion] = useState<string | undefined>(undefined)
  const [activeSubAccordion, setActiveSubAccordion] = useState<string | undefined>(undefined)
  const [showAllAccordions, setShowAllAccordions] = useState(false)
  const [faqs, setFaqs] = useState<(FilteredFaqQuestion | FilteredFaqTopic)[]>(data.faqs.map((faq) => ({ ...faq, isFiltered: false })))
  const searchParams = useSearchParams()
  const faqSearchParam = searchParams.get('faq')
  const [{ scrollTopMargin }] = useGlobalTopMargin()
  const location = typeof window !== 'undefined' ? window.location : undefined

  const handleSearchChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(target.value)
    filterFaqs(target.value)
  }

  const handleAccordionChange = (value: string) => {
    setActiveAccordion(value)
    setShowAllAccordions(true)
  }

  const containsValue = (faq: FaqQuestion, searchValue: string) => {
    return (
      faq.question.toLowerCase().includes(searchValue.toLowerCase()) ||
      extractTextFromPortableText(faq.answer).toLowerCase().includes(searchValue.toLowerCase()) ||
      faq._id === searchValue
    )
  }

  const filterFaqs = (searchValue: string) => {
    setShowAllAccordions(false)
    const reFilteredFaqs = data.faqs.map((faq) => {
      if (faq._type === 'faqTopic') {
        return {
          ...faq,
          isFiltered: faq.title.toLowerCase().includes(searchValue.toLowerCase()) || faq.faqs.some((subFaq) => containsValue(subFaq, searchValue)),
        }
      } else {
        return {
          ...faq,
          isFiltered: containsValue(faq, searchValue),
        }
      }
    })

    const filteredFaqs = reFilteredFaqs.filter((faq) => faq.isFiltered)

    if (filteredFaqs.length === 1) {
      const faq = filteredFaqs[0]
      setActiveAccordion(`accordion${faq?._id}`)
      if (faq?._type === 'faqTopic') {
        const filteredSubFaqs = faq.faqs.filter((subFaq) => {
          return containsValue(subFaq, searchValue)
        })
        filteredSubFaqs.length === 1 && setActiveSubAccordion(`subAccordion${filteredSubFaqs[0]?._id}`)
      }
    }

    setFaqs(reFilteredFaqs)
  }

  useEffect(() => {
    if (faqSearchParam) {
      const url = decodeURI(faqSearchParam.split('?')[0] || faqSearchParam)
      filterFaqs(url)
      const targetAccordion = document.querySelector('#faqs')
      setTimeout(() => {
        if (targetAccordion) {
          targetAccordion.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }, 300) // wait for accordion to open
    }
  }, [faqSearchParam])

  const filteredFaqs = faqs.filter((faq) => faq.isFiltered)
  const unfilteredFaqs = faqs.filter((faq) => !faq.isFiltered)

  return (
    <section id='faqs' style={{ scrollMarginTop: `${scrollTopMargin + 28}px` }} className='w-full'>
      {data.hasSearchbar && (
        <MisterInputSearchBar
          className='mb-10 md:mb-7'
          placeholderText={translate('searchYourQuestion', 'Search your question')}
          value={searchQuery}
          onChangeHandler={handleSearchChange}
          onClear={() => {
            setSearchQuery('')
            setFaqs(data.faqs.map((faq) => ({ ...faq, isFiltered: false })))
          }}
        />
      )}
      {data.header && <h3 className='mb-2 py-2 text-body-sm-bold'>{data.header}</h3>}
      <Accordion onValueChange={(value) => handleAccordionChange(value)} value={activeAccordion} type='single' collapsible>
        {filteredFaqs.length === 0 && searchQuery !== '' ? (
          <div className='flex justify-center py-6'>
            <span className='text-heading-5 font-bold'>{translate('noResults', 'No results')}</span>
          </div>
        ) : (
          [...filteredFaqs, ...unfilteredFaqs].map((faq, i) =>
            faq._type === 'faqTopic' ? (
              <AccordionItem
                value={`accordion${faq._id}`}
                className={cn(filteredFaqs?.length > 0 && !showAllAccordions && !faq.isFiltered && 'opacity-30 hover:border-opacity-30 hover:opacity-100')}
                key={i}
              >
                <AccordionTrigger>{faq.title}</AccordionTrigger>
                <AccordionContent variant='nested'>
                  <Accordion onValueChange={(value) => setActiveSubAccordion(value)} value={activeSubAccordion} type='single' collapsible>
                    {faq.faqs.map((subFaq, subIndex) => (
                      <AccordionItem value={`subAccordion${subFaq._id}`} key={subIndex}>
                        <AccordionTrigger className='flex [&[data-state=open]>button]:pointer-events-auto [&[data-state=open]>button]:opacity-100'>
                          <span>{subFaq.question}</span>
                          {location && (
                            <MisterCopyIcon
                              textToCopy={`${location.origin}${location.pathname}?faq=${encodeURIComponent(subFaq._id)}`}
                              className='pointer-events-none text-brand-muted opacity-0 duration-300'
                              CopyIcon={Link2}
                              tooltipText={translate('copyLinkToFaq', 'Copy link to FAQ')}
                            />
                          )}
                        </AccordionTrigger>
                        <AccordionContent>
                          <RichText data={subFaq.answer} />
                          {subFaq?.link?.linkText && (
                            <MisterButton icon='arrow-right' variant='transparent' buttonText={subFaq?.link?.linkText} type='a' className='mt-4' link={subFaq.link} />
                          )}
                        </AccordionContent>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </AccordionContent>
              </AccordionItem>
            ) : (
              <AccordionItem value={`accordion${faq._id}`} key={i}>
                <AccordionTrigger>{faq.question}</AccordionTrigger>
                <AccordionContent>
                  <RichText data={faq.answer} />
                </AccordionContent>
              </AccordionItem>
            ),
          )
        )}
      </Accordion>
    </section>
  )
}

export default SectionFaq
