import { FunctionComponent, ReactNode, useEffect } from 'react'
import { useToggle } from 'react-use'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@ui/components/ui/dialog'
import { cn } from '@ui/lib/utils'

interface Props {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  size?: 'default' | 'small'
  title: string
  showTitle?: boolean
  ariaDescription?: string
}

/**
 * @deprecated: still works, but components should directly use the Sheet component from the ui package
 */
const MisterDialog: FunctionComponent<Props> = ({ isOpen, onClose, size = 'default', title, showTitle = true, ariaDescription, children }) => {
  const [visible, setVisible] = useToggle(isOpen)

  useEffect(() => {
    setVisible(isOpen)
  }, [isOpen, setVisible])

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      setVisible(false)

      setTimeout(() => {
        onClose()
      }, 300)
    }
  }

  return (
    <Dialog defaultOpen={false} open={visible} onOpenChange={handleOpenChange}>
      <DialogContent className={cn(size === 'small' ? 'max-w-[75%] sm:max-w-[40%] md:max-w-[25rem]' : 'max-w-[95%] sm:max-w-[90%] md:max-w-3xl xl:max-w-4xl')}>
        <DialogHeader className={cn(!showTitle && 'sr-only')}>
          <DialogTitle>{title}</DialogTitle>
          {ariaDescription && <DialogDescription>{ariaDescription}</DialogDescription>}
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default MisterDialog
