'use client'

import { ShopifyProductAvailability } from '@/types/shopify'
import { FunctionComponent, useRef, useState } from 'react'
import useProductForm from 'src/hooks/shop/useProductForm2'
import MisterIcon from '../MisterIcon'
import { cn } from '@ui/lib/utils'
import { Separator } from '@ui/components/ui/separator'
import { localeCurrencyMap } from 'src/utils/price.util'
import { formatISO } from 'date-fns'
import { useRouter } from 'next/router'
import { ChevronDown, ChevronUp, LoaderCircle } from 'lucide-react'
import { useTranslations } from 'src/contexts/Globals.context'
import OptionsSelect from 'src/components/pages/products/OptionsSelect'
import { ProductCardProduct } from 'data-access/sanity/fragments/components/productCardProduct.fragment'
import { ProductMedia } from 'data-access'

interface MisterQuickAddToCartProps {
  product: ProductCardProduct
  productMedia: ProductMedia[]
  addToCartToast?: boolean
  liveProductData?: ShopifyProductAvailability
  onAddToCart?: () => void
}

const MisterQuickAddToCart: FunctionComponent<MisterQuickAddToCartProps> = ({ product, productMedia, liveProductData, addToCartToast = false, onAddToCart }) => {
  const ref = useRef<HTMLFormElement>(null)
  const { locale } = useRouter()
  const [atcOpen, setAtcOpen] = useState(false)
  const translate = useTranslations()

  // Frustratingly Safari and Firefox don't appear to support this approach.
  // TODO: Investigate alternative approaches.
  // https://developer.mozilla.org/en-US/docs/Web/API/FocusEvent/relatedTarget
  // const handleBlur = (e: any) => {
  //   console.log('blur handler fired', e.relatedTarget)
  //   if (ref.current && !ref.current.contains(e.relatedTarget)) {
  //     setAtcOpen(false)
  //   }
  // }

  const priceValidUntil = formatISO(new Date(new Date().setFullYear(new Date().getFullYear() + 1)), { representation: 'date' })

  const { variants, loadingAddToCart, handleAddToCart, selectedVariant, setSelectedVariant, options, availableOptions, liveAvailableOptions } = useProductForm({
    slug: product.slug.current,
    product,
    productMedia,
    liveProductData,
  })

  const [selectedOptions, setSelectedOptions] = useState(variants.length === 1 ? availableOptions[0] : {})

  const handleQuickAddToCartClick = () => {
    if (!atcOpen) {
      return setAtcOpen(true)
    }

    handleAddToCart(addToCartToast ? 'toast' : 'drawer')
    onAddToCart?.()
  }

  return (
    <form className='hidden @[15.625rem]/product-card:block' onMouseLeave={() => setAtcOpen(false)} /* onBlur={handleBlur} */ ref={ref}>
      <div
        className={cn(
          `absolute -inset-x-4 bottom-2 flex flex-row-reverse opacity-0 transition-all duration-200 ease-out group-focus-within/product:inset-x-2 group-focus-within/product:opacity-100 group-hover/product:inset-x-2 group-hover/product:opacity-100 md:-inset-x-5 md:bottom-4 group-focus-within/product:md:inset-x-4 group-hover/product:md:inset-x-4`,
        )}
      >
        <button
          type='button'
          onClick={handleQuickAddToCartClick}
          className={cn(
            'relative inline-flex h-10 items-center justify-center self-end whitespace-nowrap rounded-md text-body-sm font-medium ring-offset-transparent transition-all duration-300 ease-out after:absolute after:-z-[1] after:h-10 after:scale-90 after:rounded-md after:opacity-50 after:transition-all after:duration-300 after:ease-out hover:after:scale-100 hover:after:opacity-100 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
            atcOpen
              ? 'z-[20] w-full bg-brand-blue-action text-white opacity-100 after:w-full after:bg-brand-blue-action'
              : 'z-[18] w-10 text-secondary-foreground after:w-10 after:bg-secondary',
            atcOpen && !selectedVariant && 'cursor-not-allowed bg-brand-blue-light after:bg-brand-blue-light',
          )}
          disabled={atcOpen && !selectedVariant}
          tabIndex={0}
          data-testid='quick-add-to-cart'
          itemProp={atcOpen ? 'offers' : undefined}
          itemType={atcOpen ? 'https://schema.org/Offer' : undefined}
          itemScope={atcOpen ? true : undefined}
        >
          <div className={cn('inline-grid items-center transition-[grid-template-columns] duration-300 ease-out', atcOpen ? 'grid-cols-[auto,1fr]' : 'grid-cols-[auto,0fr]')}>
            {loadingAddToCart ? <LoaderCircle className='size-4 animate-spin' /> : <MisterIcon type='cart' className='size-6' />}
            <span className={cn('min-w-0 text-white transition-[opacity] duration-300 ease-out', atcOpen ? 'opacity-1 ml-3' : 'ml-0 opacity-0')}>
              {atcOpen && (
                <>
                  <meta itemProp='price' content={String(product.price)} />
                  <meta itemProp='priceValidUntil' content={priceValidUntil} />
                  <meta itemProp='itemCondition' content='https://schema.org/NewCondition' />
                  <meta itemProp='priceCurrency' content={localeCurrencyMap?.[locale || 'en'] || 'EUR'} />
                </>
              )}
              {selectedVariant ? translate('addToCartButtonText', 'Add to Cart') : translate('chooseSize', 'Choose a size')}
            </span>
          </div>
        </button>
      </div>
      <div className={cn('absolute inset-x-0 top-full z-[18] rounded-t-md bg-white pb-20 transition-all duration-300 ease-out', atcOpen ? '-translate-y-full' : 'scrollbar-hide')}>
        <fieldset className='space-y-2 px-1 pt-4'>
          <label className='px-2 text-body-sm-bold'>{translate('chooseSize', 'Choose a size')}</label>
          <div className='max-h-60 w-full overflow-scroll pr-2 scrollbar-hide'>
            <div className='relative h-full pb-4'>
              {atcOpen &&
                (product.primaryCollection?.productType === 'trousers' ? (
                  <OptionsSelect
                    id={product._id}
                    options={options}
                    availableOptions={availableOptions}
                    liveAvailableOptions={liveAvailableOptions}
                    productQuantity={selectedVariant?.inventory?.quantity}
                    availableInventories={liveProductData?.variants?.edges?.map(({ node }) => node?.quantityAvailable)}
                    stockWarningThreshold={5}
                    isGiftCard={false}
                    isCollectionCard={true}
                    onChange={(v) => {
                      const variant = variants.find(({ options: s }) => s?.every(({ name, value }) => v?.[name] === value))
                      setSelectedVariant(variant || null)
                      setSelectedOptions(v)
                    }}
                    selectedOptions={selectedOptions}
                  />
                ) : (
                  variants.map((variant) => {
                    const title = variant.options.map((option) => option.value).join(' / ')
                    return (
                      <div
                        key={variant.shopifyVariantId}
                        className={cn(
                          'flex items-center overflow-hidden rounded-md px-2 has-[:checked]:bg-brand-beige-light',
                          !variant.isAvailable ? '' : 'hover:bg-brand-beige-light',
                        )}
                        data-testid='size-select'
                      >
                        <input
                          onChange={() => setSelectedVariant(variant)}
                          className='peer hidden'
                          checked={selectedVariant?.shopifyVariantId === variant.shopifyVariantId}
                          disabled={!variant.isAvailable}
                          type='radio'
                          value={variant.shopifyVariantId}
                          id={variant.shopifyVariantId}
                        />
                        <label
                          className='m-0 w-full cursor-pointer py-2 text-body-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 peer-checked:font-bold peer-disabled:cursor-not-allowed peer-disabled:text-brand-grey-dark peer-disabled:opacity-50'
                          tabIndex={atcOpen ? 0 : -1}
                          htmlFor={variant.shopifyVariantId}
                        >
                          {title}
                        </label>
                        {variant.inventory.quantity <= 5 && variant.inventory.quantity > 0 && (
                          <span className='whitespace-nowrap text-body-sm'>
                            {translate('onlyQuantityLeftText', 'Only {quantity} left').replace('{quantity}', variant.inventory.quantity)}
                          </span>
                        )}
                        {/* TODO: Once we have a design for the notify me functionality in the product card we are removing it */}
                        {/* {!product.discontinued && !variant.isAvailable && (
                        <Button tabIndex={atcOpen ? 0 : -1} asChild variant='link' className='whitespace-nowrap text-btn-sm'>
                          <MisterSiteLink link={convertSlug(product.slug, 'product')}>{translate('notifyMeTextQuickATC', 'Notify me')}</MisterSiteLink>
                        </Button>
                      )} */}
                      </div>
                    )
                  })
                ))}
              <div className='pointer-events-none absolute inset-0 @container'>
                <div className='br-gradient-from-t sticky inset-x-0 -top-[1px] mt-auto hidden h-5 animate-fade-at-start items-center justify-center bg-gradient-to-b from-white to-transparent [animation-timeline:scroll()] @h-xs:flex'>
                  <ChevronUp className='hidden size-5 text-brand-grey-dark supports-[animation-timeline]:block' />
                </div>

                <div className='br-gradient-from-b sticky inset-x-0 top-[calc(14rem)] mt-auto hidden h-5 animate-fade-at-end items-center justify-center bg-gradient-to-t from-white to-transparent [animation-timeline:scroll()] @h-xs:flex'>
                  <ChevronDown className='hidden size-5 text-brand-grey-dark supports-[animation-timeline]:block' />
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <Separator />
      </div>
    </form>
  )
}

export default MisterQuickAddToCart
