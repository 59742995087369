import React, { FunctionComponent, ReactNode } from 'react'
import { useGlobalTopMargin } from 'src/components/globalState/MisterScrollMargin'
import { useUI } from 'src/contexts/UI.context'
import { cn } from 'ui/lib/utils'

interface ScrollHeaderWrapperProps {
  announcementBar: ReactNode
  header: ReactNode
}

const ScrollHeaderWrapper: FunctionComponent<ScrollHeaderWrapperProps> = ({ announcementBar, header }) => {
  const [{ showAnnouncementBar }] = useGlobalTopMargin()
  const { displaySearchUI } = useUI()
  return (
    <>
      <div
        className={cn('z-header lg:sticky lg:transition-all lg:duration-300', showAnnouncementBar && !displaySearchUI ? 'lg:top-0' : 'lg:-top-[var(--announcement-bar-height)]')}
      >
        {announcementBar}
      </div>
      <div className={cn('sticky top-0 z-header lg:transition-all lg:duration-300', showAnnouncementBar && !displaySearchUI && 'lg:top-[var(--announcement-bar-height)]')}>
        {header}
      </div>
    </>
  )
}

export default ScrollHeaderWrapper
