import { CSSProperties, FC } from 'react'
import { AlgoliaProductHit } from '../../../utils/algolia.util'
import GridCallout from './GridCallout'
import { SectionGridCalloutsData } from 'data-access/sanity/fragments/sections/sectionGridCallouts.fragment'

interface GridCalloutsProps {
  gridCallouts: SectionGridCalloutsData[]
  items: AlgoliaProductHit[]
  queryID?: string
}

const GridCallouts: FC<GridCalloutsProps> = ({ gridCallouts = [], items, queryID }) => {
  // Mobile is always 2 columns
  const getMaximumTwoColumnGridCallouts = () => (items.length > 4 ? Math.floor((items.length - 4) / 6 + 1) : 0)
  const getMaximumNthColumnGridCallouts = (productsBeforeFirstCallout: number, productsBeforeNthCallout: number) =>
    gridCallouts.reduce(
      (acc, gridCallout, index) => {
        const decrement =
          gridCallout.columnSpan === 1
            ? index === 0
              ? productsBeforeFirstCallout
              : productsBeforeNthCallout
            : index === 0
              ? productsBeforeFirstCallout - 1
              : productsBeforeNthCallout - 1
        if (acc.productsCount - decrement >= 0) {
          acc.productsCount -= decrement
          acc.count++
        }
        return acc
      },
      { productsCount: items.length, count: 0 },
    ).count

  const maximumTwoColumnGridCallouts = getMaximumTwoColumnGridCallouts()
  const maximumThreeColumnGridCallouts = getMaximumNthColumnGridCallouts(5, 11)
  const maximumFourColumnGridCallouts = getMaximumNthColumnGridCallouts(7, 15)

  const visibleGridCallouts = gridCallouts.filter((gridCallout) => gridCallout.showGridCallout)

  return (
    <>
      {visibleGridCallouts.map((gridCallout, index) => (
        <GridCallout
          style={
            {
              '--index': index,
              '--cols': gridCallout.columnSpan,
              '--show-on-2col': index + 1 <= maximumTwoColumnGridCallouts ? 'flex' : 'none',
              '--show-on-3col': index + 1 <= maximumThreeColumnGridCallouts ? 'flex' : 'none',
              '--show-on-4col': index + 1 <= maximumFourColumnGridCallouts ? 'flex' : 'none',
            } as CSSProperties
          }
          key={`grid-callout-${index}-${queryID}`}
          className='-col-end-1 relative col-span-2 row-start-[calc(3+var(--index)*4)] [display:var(--show-on-2col)] @3xl/product-grid:col-[span_var(--cols)/-1] @3xl/product-grid:row-start-[calc(2+var(--index)*4)] @3xl/product-grid:[display:var(--show-on-3col)] @7xl/product-grid:[display:var(--show-on-4col)]'
          {...gridCallout}
        />
      ))}
    </>
  )
}

export default GridCallouts
