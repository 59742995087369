import { FunctionComponent, useContext } from 'react'
import { useRouter } from 'next/router'
import { cn } from 'ui/lib/utils'
import { localeCurrencyMap } from '../../../utils/price.util'
import { formatISO } from 'date-fns'
import Price from '../Price'
import { GlobalsContext } from 'src/contexts/Globals.context'
import { getDiscountCampaign } from 'src/utils/discountCampaign.util'

interface MisterProductInfoProps {
  title: string
  subtitle?: string
  price: number
  productIsAvailable: boolean
  showPrice?: boolean
  productId?: string
}

const priceValidUntil = formatISO(new Date(new Date().setFullYear(new Date().getFullYear() + 1)), { representation: 'date' })

const MisterProductInfo: FunctionComponent<MisterProductInfoProps> = ({ title, subtitle, price, productIsAvailable, showPrice = true, productId }) => {
  const { locale } = useRouter()
  const { discountCampaigns } = useContext(GlobalsContext)
  const { discount } = getDiscountCampaign(discountCampaigns, productId) || {}

  return (
    <div data-label='ProductInfoHeader' className='w-full pr-2 pt-3' itemProp='offers' itemType='https://schema.org/Offer' itemScope>
      <meta itemProp='price' content={String(price)} />
      <meta itemProp='priceValidUntil' content={priceValidUntil} />
      <meta itemProp='itemCondition' content='https://schema.org/NewCondition' />
      <meta itemProp='priceCurrency' content={localeCurrencyMap?.[locale || 'en'] || 'EUR'} />
      <meta itemProp='availability' content={productIsAvailable ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock'} />
      <div className='flex'>
        <h2 className={cn('text-body-lg-bold font-bold sm:mb-1')}>{title}</h2>
        {showPrice && (
          <div className='ml-auto flex shrink-0 gap-2 text-body-lg'>
            {discount && (
              <s className='line-through'>
                <Price price={price} />
              </s>
            )}
            <Price price={discount ? price * (1 - discount / 100) : price} />
          </div>
        )}
      </div>
      {subtitle && <p className='text-body-md'>{subtitle}</p>}
    </div>
  )
}

export default MisterProductInfo
