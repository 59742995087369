import MisterMedia from '../../shared/media/MisterMedia'
import RichText from '../../shared/rich-text/rich-text'
import { cn } from 'ui/lib/utils'
import { Fragment, FunctionComponent } from 'react'
import MisterContainer from '../../shared/MisterContainer'
import { useTranslations } from '../../../contexts/Globals.context'
import MisterIcon from 'src/components/shared/MisterIcon'
import { add, isSameDay, parseISO } from 'date-fns'
import useSpecialDays from 'src/hooks/utils/useSpecialDays'
import { days } from '../../../utils/date.util'
import { SectionStoreData } from 'data-access/sanity/fragments/sections/sectionStore.fragment'

interface SectionStoreProps {
  data: SectionStoreData
}

const SectionStore: FunctionComponent<SectionStoreProps> = ({ data }) => {
  const {
    store: { googleMapsLink, telephoneNumber, defaultOpeningTimes, specialOpeningTimes, city, street, postalCode, isOpen },
  } = data || {}
  const translate = useTranslations()
  const { dayOfTheWeek, upcomingSpecialDays } = useSpecialDays(defaultOpeningTimes, specialOpeningTimes)
  const todayIndex = days.indexOf(dayOfTheWeek || 'monday')
  const reorderedDays = [...days.slice(todayIndex), ...days.slice(0, todayIndex)]

  return (
    <MisterContainer dataLabel='section-store' type='fluid' className='flex w-full flex-col gap-8 p-4 lg:grid lg:grid-cols-12 lg:p-14' data-id={data._id}>
      <div className='flex flex-col gap-8 lg:col-span-7 lg:gap-16'>
        <h3 className='text-heading-3'>{data?.title}</h3>
        <div className='contents lg:grid lg:grid-cols-2'>
          <div>
            <p className='mb-4 whitespace-pre-line text-body-lg-bold'>{translate('address')}</p>
            <div className='flex flex-col justify-between gap-8 text-body-lg'>
              <div>
                {street && <p>{street}</p>}
                {postalCode && city && (
                  <p>
                    {postalCode} {city}
                  </p>
                )}
              </div>
              {googleMapsLink && (
                <div>
                  <a href={googleMapsLink} target='_blank' rel='noopener noreferrer' className='underline'>
                    {translate('openInGoogleMaps')}
                  </a>
                </div>
              )}
              {telephoneNumber && (
                <div className='flex items-center gap-2'>
                  <MisterIcon type='phone' className='w-6' />
                  <a href={`tel:${telephoneNumber}`} className='underline'>
                    {telephoneNumber}
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className='flex flex-col gap-4 text-body-lg'>
            <p className='text-body-lg-bold'>{translate('openingHours')}</p>
            {isOpen ? (
              <>
                {defaultOpeningTimes && (
                  <div className='grid w-fit grid-cols-2 gap-x-7 text-body-lg'>
                    {reorderedDays.map((day, i) => {
                      const specialDayMatch = upcomingSpecialDays?.find(({ date }) => isSameDay(parseISO(date), add(new Date(), { days: i })))
                      const { open, close, closed } = specialDayMatch || defaultOpeningTimes[day]
                      const messageNumber = specialDayMatch ? upcomingSpecialDays.filter((specialDay) => specialDay.message).indexOf(specialDayMatch) + 1 : null
                      return (
                        <Fragment key={day}>
                          <div className={cn(day === dayOfTheWeek && 'font-bold', 'text-label-xl capitalize')}>{translate(`${day}`)}</div>
                          <div className={cn(day === dayOfTheWeek && 'font-bold', specialDayMatch && 'italic')}>
                            {closed ? translate('closed') : `${open} - ${close}`}
                            {specialDayMatch && <span>*</span>}
                            {specialDayMatch && (
                              <span className='ml-1 not-italic text-brand-medium-grey'>{messageNumber && messageNumber > 0 ? <sup>{messageNumber}</sup> : null}</span>
                            )}
                          </div>
                        </Fragment>
                      )
                    })}
                  </div>
                )}
                {upcomingSpecialDays?.length > 0 && (
                  <div>
                    {upcomingSpecialDays
                      .filter((specialDay) => specialDay.message)
                      .map((specialDay, i) => {
                        return (
                          <p key={specialDay.date} className='italic'>
                            <sup>{i + 1}</sup>
                            {specialDay.message}
                          </p>
                        )
                      })}
                  </div>
                )}
              </>
            ) : (
              <p>{translate('openingSoon')}</p>
            )}
          </div>
        </div>
        {data?.description && (
          <div className='my-auto max-w-prose'>
            <RichText className='text-body-lg' data={data?.description} />
          </div>
        )}
      </div>
      <MisterMedia
        media={data?.storeMedia}
        className='aspect-square object-cover lg:col-span-5'
        mobileSizes='(max-width: 1024px) 95vw, (max-width: 1536px) 40vw, 36rem'
        desktopSizes='(max-width: 1024px) 95vw, (max-width: 1536px) 40vw, 36rem'
      />
    </MisterContainer>
  )
}

export default SectionStore
