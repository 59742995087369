import React, { FunctionComponent } from 'react'

const LogoSvg: FunctionComponent<{ className: string }> = ({ className }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 178 23' fill='none' className={className}>
      <g fill='#071933' clipPath='url(#a)'>
        <path
          fillRule='evenodd'
          d='M.597 21.502 3.507.778h.334l8.262 16.971L20.264.78h.335l2.91 20.69h-1.974L19.528 6.66 12.337 21.5h-.502L4.544 6.594 2.537 21.502H.597ZM59.398 21.501l2.91-20.69h.335l8.262 16.971 8.16-16.97h.335l2.91 20.69h-1.973L78.33 6.693 71.14 21.5h-.502L63.379 6.592l-2.007 14.91h-1.974Z'
          clipRule='evenodd'
        />
        <path d='M32.239 9.839v11.63h-2.04V.778h4.046c2.242 0 3.78.1 4.583.27a5.126 5.126 0 0 1 2.943 1.825c.77.913 1.137 2.063 1.137 3.415 0 1.115-.267 2.13-.769 2.975-.535.845-1.27 1.487-2.24 1.927-.97.44-2.309.676-4.015.676l7.292 9.601h-2.509L32.24 9.838Zm0-7.032v7.032l3.512.033c1.371 0 2.341-.135 3.01-.405.636-.27 1.17-.676 1.539-1.251.367-.575.535-1.217.535-1.927s-.2-1.319-.569-1.893c-.368-.575-.836-.98-1.438-1.217-.602-.237-1.606-.372-2.977-.372h-3.612ZM115.424 9.839v11.63h-2.04V.778h4.047c2.241 0 3.78.1 4.582.27a5.123 5.123 0 0 1 2.944 1.825c.769.913 1.137 2.063 1.137 3.415 0 1.115-.268 2.13-.769 2.975-.535.845-1.271 1.487-2.241 1.927-.97.44-2.308.676-4.014.676l7.292 9.601h-2.509l-8.429-11.63Zm0-7.032v7.032l3.512.033c1.372 0 2.342-.135 3.011-.405.635-.27 1.17-.676 1.538-1.251.368-.575.535-1.217.535-1.927a3.4 3.4 0 0 0-.568-1.893c-.368-.575-.837-.98-1.439-1.217-.602-.237-1.605-.372-2.976-.372h-3.613Z' />
        <path
          fillRule='evenodd'
          d='m165.396 17.681 1.739-1.048c1.204 2.265 2.609 3.415 4.214 3.415.669 0 1.305-.17 1.907-.473.602-.338 1.037-.744 1.371-1.319a3.51 3.51 0 0 0 .468-1.724c0-.71-.234-1.386-.702-2.028-.635-.913-1.806-2.029-3.479-3.313-1.705-1.319-2.742-2.232-3.144-2.84-.702-.98-1.07-2.029-1.07-3.178 0-.913.201-1.724.636-2.468a4.68 4.68 0 0 1 1.772-1.758c.77-.44 1.606-.642 2.509-.642.97 0 1.84.236 2.676.71.836.473 1.706 1.386 2.642 2.67l-1.672 1.285c-.77-1.048-1.405-1.724-1.94-2.028-.535-.338-1.138-.474-1.773-.474-.803 0-1.472.237-2.007.744a2.538 2.538 0 0 0-.769 1.86c0 .439.1.878.267 1.318.168.406.536.879 1.004 1.386.267.27 1.137.947 2.609 2.062 1.739 1.319 2.977 2.502 3.612 3.55.669 1.014.97 2.062.97 3.11 0 1.488-.568 2.806-1.672 3.922-1.104 1.115-2.475 1.656-4.081 1.656a5.83 5.83 0 0 1-3.345-1.014c-.97-.71-1.873-1.826-2.742-3.38Z'
          clipRule='evenodd'
        />
        <path d='m97.73.778 9.466 20.69h-2.174l-3.178-6.829h-8.763l-3.178 6.83H87.63l9.6-20.69h.501Zm-.268 4.395-3.478 7.505h6.924l-3.446-7.505Z' />
        <path fillRule='evenodd' d='M131.211.778h2.208l6.723 15.99 6.823-15.99h2.208l-8.83 20.724h-.435L131.211.778ZM158.572 21.502h-2.074V.778h2.074v20.724Z' clipRule='evenodd' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h178v23H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LogoSvg
