import { RefObject, useEffect } from 'react'
import { useToggle } from 'react-use'

export const useInView = (ref: RefObject<HTMLElement | null>, percentageInView = 0) => {
  const [inView, setInView] = useToggle(false)
  const [horizontallyInView, setHorizontallyInView] = useToggle(false)

  useEffect(() => {
    const sectionRef = ref?.current

    const observer = new IntersectionObserver(
      (entry) => {
        if (entry && entry[0]?.isIntersecting) {
          setInView(true)
        } else {
          setInView(false)
        }
      },
      {
        // isIntersecting when target element reaches bottom 10% of the viewport height
        rootMargin: `0% 0% ${percentageInView}% 0%`,
      },
    )
    const horizontalObserver = new IntersectionObserver(
      (entry) => {
        if (entry && entry[0]?.isIntersecting) {
          setHorizontallyInView(true)
        } else {
          setHorizontallyInView(false)
        }
      },
      {
        // isIntersecting when target element reaches bottom 10% of the viewport height
        rootMargin: `0% ${percentageInView}% 0% ${percentageInView}%`,
      },
    )

    if (sectionRef) {
      observer.observe(sectionRef)
      horizontalObserver.observe(sectionRef)
    }

    // Initiate observer with target
  }, [percentageInView, ref, setHorizontallyInView, setInView])

  return { inView, horizontallyInView }
}

export default useInView
