import { FunctionComponent, useState } from 'react'
import StoreCard from './StoreCard'
import MisterContainer from 'src/components/shared/MisterContainer'
import { RadioGroup, RadioGroupItem } from '@ui/components/ui/radio-group'
import { Label } from '@ui/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@ui/components/ui/select'
import { useTranslations } from 'src/contexts/Globals.context'
import { useRouter } from 'next/router'
import { useCookie } from 'react-use'
import { I18N_COUNTRY_CODE_RAW } from 'src/middleware'
import { SectionStoreIndexData } from 'data-access/sanity/fragments/sections/sectionStoreIndex.fragment'

interface SectionStoreIndexProps {
  data: SectionStoreIndexData
}

const SectionStoreIndex: FunctionComponent<SectionStoreIndexProps> = ({ data }) => {
  const [value] = useCookie(I18N_COUNTRY_CODE_RAW)
  const { locale } = useRouter()
  const translate = useTranslations()

  const FilterOptions = [
    { value: 'all-countries', label: translate('allCountries', 'All Countries'), count: data.stores.length },
    ...[...new Set(data.stores.map((store) => store.store.country))].map((country) => ({
      value: country,
      label: translate(country, country),
      count: data.stores.filter((store) => store.store.country === country).length,
    })),
  ]

  const defaultCountry =
    value && FilterOptions.filter((option) => option.value === value?.toLowerCase()).length
      ? value.toLowerCase()
      : locale && FilterOptions.filter((option) => option.value === locale).length
        ? locale
        : 'all-countries'

  const [countryFilter, setCountryFilter] = useState<string>(defaultCountry)

  const filteredStores = data.stores.filter((store) => countryFilter === 'all-countries' || store.store.country === countryFilter)

  return (
    <MisterContainer dataLabel='section-stores-index' className='grid grid-cols-2 gap-10 py-16 md:grid-cols-4 md:py-24' data-id={data._id}>
      <div className='col-span-2 flex min-w-min flex-col items-center gap-16 md:col-span-1 md:items-start'>
        <h1 className='text-balance text-heading-3 md:text-left'>{data.title}</h1>
        <RadioGroup className='hidden space-y-4 md:block' defaultValue='all-countries' value={countryFilter} onValueChange={setCountryFilter}>
          {FilterOptions.map(({ value, label, count }) => (
            <div key={value} className='flex items-center space-x-2'>
              <RadioGroupItem value={value} id={value} />
              <Label htmlFor={value}>{`${label} (${count})`}</Label>
            </div>
          ))}
        </RadioGroup>
        <div className='block w-full md:hidden'>
          <Select value={countryFilter} onValueChange={setCountryFilter}>
            <SelectTrigger>
              <SelectValue placeholder={countryFilter} />
            </SelectTrigger>
            <SelectContent>
              {FilterOptions.map(({ label, value, count }) => (
                <SelectItem key={value} value={value}>{`${label} (${count})`}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <ul className='col-span-2 flex flex-col gap-x-2 gap-y-20 sm:grid sm:grid-cols-2 md:col-span-3 lg:grid-cols-3'>
        {filteredStores.map((store, i) => (
          <StoreCard key={i} store={store} todaysOpeningHours={data.todaysOpeningHours} viewStoreDetails={data.viewStoreDetails} />
        ))}
      </ul>
    </MisterContainer>
  )
}

export default SectionStoreIndex
