import { FunctionComponent, PropsWithChildren, useEffect } from 'react'
import { cn } from 'ui/lib/utils'
import { useToggle } from 'react-use'

interface Props {
  show?: boolean
  size?: 'micro' | 'small' | 'medium' | 'large'
  light?: boolean
  inline?: boolean
  message?: string
}

const MisterSpinner: FunctionComponent<PropsWithChildren<Props>> = ({ show = true, size = 'small', light = false, inline = false, message }) => {
  const [showMessage, setShowMessage] = useToggle(false)

  useEffect(() => {
    if (!message) {
      return
    }

    setTimeout(() => {
      setShowMessage(true)
    }, 1000)
  }, [message, setShowMessage])

  if (!show) {
    return null
  }

  const color = light ? '#FFF' : 'currentColor'

  const Spinner = () => (
    <svg
      className={cn(
        'animate-spin',
        !inline && 'mx-auto',
        inline && 'inline',
        size === 'micro' && 'size-4',
        size === 'small' && 'size-6',
        size === 'medium' && 'size-12',
        size === 'large' && 'size-16',
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      aria-busy='true'
    >
      <circle className='opacity-25' cx='12' cy='12' r='10' stroke={color} strokeWidth='4'></circle>
      <path className='opacity-75' fill={color} d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
    </svg>
  )

  return message ? (
    <div className='relative flex flex-col items-center'>
      <div className={cn('transition-transform duration-1000', showMessage && '-translate-y-4')}>{Spinner()}</div>
      <span className={cn('absolute opacity-0 transition-all duration-1000', showMessage && 'translate-y-4 opacity-100')}>{message}</span>
    </div>
  ) : (
    Spinner()
  )
}

export default MisterSpinner
