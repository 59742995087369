import { cn } from '@ui/lib/utils'
import { FC, Fragment, useState } from 'react'
import TinyChevron from '../icons/TinyChevron'
import { Breadcrumb, BreadcrumbEllipsis, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from './breadcrumb'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from './dropdown-menu'

interface Page {
  href?: string
  label: string
}

interface BreadcrumbDropdownProps {
  pages: Page[]
  index: number
  isLast: boolean
  variant?: 'default' | 'pdp'
}

const BreadcrumbDropdown: FC<BreadcrumbDropdownProps> = ({ pages, index, isLast, variant = 'default' }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  return (
    <>
      <DropdownMenu onOpenChange={setDropdownOpen} open={dropdownOpen}>
        <DropdownMenuTrigger>
          <BreadcrumbItem className='flex items-center gap-0.5 transition-colors hover:text-brand-blue-action'>
            {pages[0]?.label}
            <span className='sr-only'>{pages[0]?.label}</span>
            <TinyChevron className={cn('size-3 transition-transform duration-200 ease-out', dropdownOpen && 'rotate-180', variant === 'default' && 'mt-0.5')} />
          </BreadcrumbItem>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='start'>
          {pages.map((item, i) => (
            <DropdownMenuItem key={i} asChild>
              <BreadcrumbLink href={item.href}>{item.label}</BreadcrumbLink>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
      {!isLast && <BreadcrumbSeparator />}
    </>
  )
}

interface BreadcrumbSingleItemProps {
  page: Page
  index: number
  total: number
  variant: 'default' | 'pdp'
}
const BreadcrumbSingleItem: FC<BreadcrumbSingleItemProps> = ({ page, index, total, variant }) => {
  const isLast = index === total - 1
  const shouldEllipsis = total > 3 && index > 2 && !isLast

  return (
    <>
      <BreadcrumbItem className={cn(isLast && 'truncate font-semibold', 'whitespace-nowrap')}>
        {shouldEllipsis ? (
          <BreadcrumbEllipsis />
        ) : page?.href ? (
          <BreadcrumbLink href={page.href}>{page.label}</BreadcrumbLink>
        ) : (
          <BreadcrumbPage className={cn(variant === 'pdp' && 'font-semibold text-brand-blue', 'truncate')}>{page.label}</BreadcrumbPage>
        )}
      </BreadcrumbItem>
      {!isLast && <BreadcrumbSeparator />}
    </>
  )
}

export interface BreadcrumbPageData {
  href?: string
  label: string
}

interface DynamicBreadcrumbProps {
  pages: (BreadcrumbPageData | BreadcrumbPageData[])[]
  variant?: 'default' | 'pdp'
}

export const DynamicBreadcrumb: FC<DynamicBreadcrumbProps> = ({ pages, variant = 'default' }) => (
  <Breadcrumb>
    <BreadcrumbList className={cn('w-full flex-nowrap', variant === 'pdp' && 'text-body-sm text-brand-blue')}>
      {pages.map((page, index) => (
        <Fragment key={index}>
          {page instanceof Array ? (
            <BreadcrumbDropdown pages={page} index={index} isLast={index === pages.length - 1} variant={variant} />
          ) : (
            <BreadcrumbSingleItem page={page} index={index} total={pages.length} variant={variant} />
          )}
        </Fragment>
      ))}
    </BreadcrumbList>
  </Breadcrumb>
)

export default DynamicBreadcrumb
