import { Badge } from '@ui/components/ui/badge'
import { cn } from '@ui/lib/utils'
import Link from 'next/link'
import { Fragment, FunctionComponent, MouseEventHandler, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useToggle, useWindowSize } from 'react-use'
import { useDraggable } from 'react-use-draggable-scroll'
import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import { isTouchScreen } from 'src/utils/window.util'
import { useTranslations } from '../../../contexts/Globals.context'
import MisterChevronButtonV2 from '../../shared/button/MisterChevronButtonV2'
import MisterMedia from '../../shared/media/MisterMedia'
import { OverviewCardProps, SectionProps } from './section-overview-cards'

const OverviewCard: FunctionComponent<OverviewCardProps> = ({ title, description, overviewImage, slug, isNew }) => {
  const translate = useTranslations()
  const hasImage = overviewImage?.mobile?.url || overviewImage?.desktop?.url

  return (
    <li>
      <Link
        onClick={() => trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_CATEGORY_IMAGE, { clickTitle: title, clickDestination: slug })}
        href={slug}
        title={title}
        className='flex w-auto flex-shrink-0 snap-center flex-col gap-4 lg:w-60'
      >
        <div className='relative overflow-hidden rounded-md'>
          {hasImage ? (
            <MisterMedia className='h-full min-h-[320px] w-full min-w-[240px] transform-gpu object-cover transition-transform duration-300 hover:scale-105' media={overviewImage} />
          ) : (
            <div className='h-[320px] w-[240px] rounded-md bg-brand-grey'></div>
          )}
        </div>

        <div className='flex flex-col gap-1'>
          <div>
            {isNew && (
              <Badge size='lg' className='float-right mx-1'>
                {translate('new', 'New')}
              </Badge>
            )}
            <span className='text-body-xl-bold text-brand-blue'>{title}</span>
          </div>
          {description && <span className='text-pretty pr-4 text-body-md text-brand-blue'>{description}</span>}
        </div>
      </Link>
    </li>
  )
}

const OverviewCards: FunctionComponent<SectionProps> = ({ data }) => {
  const sliderRef = useRef<HTMLUListElement>(null)
  const [scrollableWidth, setScrollableWidth] = useState<number>(0)
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const { width } = useWindowSize()
  const [useDragHandler, setUseDragHandler] = useToggle(false)
  const { events } = useDraggable(sliderRef as unknown as MutableRefObject<HTMLInputElement>, { isMounted: useDragHandler })
  const displayCollection = data.collections[0]

  // Width of the card in pixel, plus grid gap between card and next card
  const cardWidth = 240 + 8

  useEffect(() => {
    setUseDragHandler(!isTouchScreen)
  }, [setUseDragHandler])

  const handleRightClick: MouseEventHandler<HTMLButtonElement> = () => {
    sliderRef?.current?.scrollTo({
      left: scrollPosition + cardWidth,
      behavior: 'smooth',
    })
  }

  const handleLeftClick: MouseEventHandler<HTMLButtonElement> = () => {
    sliderRef?.current?.scrollTo({
      left: scrollPosition - cardWidth,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    const cleanUpRef = sliderRef?.current

    const updateScrollDimensions = () => {
      setScrollableWidth((sliderRef?.current?.scrollWidth || 0) - (sliderRef?.current?.clientWidth || 0))
      setScrollPosition(sliderRef?.current?.scrollLeft || 0)
    }

    const handleScroll = () => {
      setScrollPosition(sliderRef?.current?.scrollLeft || 0)
    }

    updateScrollDimensions()
    sliderRef?.current?.addEventListener('scroll', handleScroll)

    return () => {
      cleanUpRef?.removeEventListener('scroll', handleScroll)
      // Reset state when unmounting or when data changes
      setScrollableWidth(0)
      setScrollPosition(0)
    }
  }, [sliderRef, width, data._id])

  const showLeftButton = useMemo(() => scrollPosition > 0 && !!sliderRef?.current?.clientWidth, [scrollPosition, sliderRef])
  const showRightButton = useMemo(() => scrollableWidth > scrollPosition + 10, [scrollableWidth, scrollPosition])

  if (!displayCollection) {
    return null
  }

  return (
    <Fragment key={`overview-cards-${data._id}`}>
      <MisterChevronButtonV2
        orientation='left'
        onClick={handleLeftClick}
        className={cn('absolute left-8 top-[40%] z-sliderArrow hidden -translate-y-[40%] border-none', showLeftButton ? 'md:flex' : 'md:hidden')}
      />
      <MisterChevronButtonV2
        orientation='right'
        onClick={handleRightClick}
        className={cn('absolute right-8 top-[40%] z-sliderArrow hidden -translate-y-[40%] border-none', showRightButton ? 'md:flex' : 'md:hidden')}
      />
      <ul
        className={cn('relative flex snap-x snap-mandatory list-none gap-x-2 overflow-y-hidden overflow-x-scroll px-4 scrollbar-hide lg:px-8')}
        {...(useDragHandler ? events : undefined)}
        ref={sliderRef}
      >
        {displayCollection?.data?.map((collection, index) => <OverviewCard key={`overview-card-${index}`} {...collection} />)}
      </ul>
    </Fragment>
  )
}

export default OverviewCards
