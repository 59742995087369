import { Fragment, FunctionComponent, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import MisterContainer from 'src/components/shared/MisterContainer'
import { SectionSliderData, SliderCardItemData } from 'data-access/sanity/fragments/sections/sectionSlider.fragment'
import { cn } from '@ui/lib/utils'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import MisterMedia from 'src/components/shared/media/MisterMedia'
import { useToggle, useWindowSize } from 'react-use'
import { useDraggable } from 'react-use-draggable-scroll'
import { isTouchScreen } from 'src/utils/window.util'
import { convertSlug } from 'src/utils/url.util'
import MisterChevronButtonV2 from 'src/components/shared/button/MisterChevronButtonV2'
import { useTranslations } from 'src/contexts/Globals.context'
import { Badge } from '@ui/components/ui/badge'
import { useExperimentFeatureFlag } from 'src/hooks/posthog/useExperimentFeatureFlag'

interface SliderCardProps {
  item: SliderCardItemData
  index: number
  isLegacy: boolean
}

const SliderCard: FunctionComponent<SliderCardProps> = ({ item, index, isLegacy }) => {
  const translate = useTranslations()
  const { title, subtitle, link, image } = item
  const adaptedLink = link?._type === 'link' ? link : convertSlug(link, 'collection')

  return isLegacy ? (
    <li className='flex w-[16rem] min-w-[16rem] select-none snap-center snap-always flex-col text-center md:w-[16rem] md:min-w-[16rem]'>
      <MisterSiteLink link={adaptedLink} title={`${title} | ${subtitle} | MR MARVIS`}>
        <div className='relative overflow-hidden bg-brand-grey'>
          <MisterMedia
            priority={index <= 1}
            className='aspect-[4/5] transition-transform duration-75 ease-in-out md:hover:scale-105'
            media={image}
            desktopSizes='16rem'
            mobileSizes='16rem'
          />
        </div>
        <div className='flex flex-col !bg-brand-beige-light bg-white px-1 py-4'>
          <p className='mb-1 text-body-sm-bold md:text-body-md-bold'>{title}</p>
          <p className='truncate text-body-sm'>{subtitle}</p>
        </div>
      </MisterSiteLink>
    </li>
  ) : (
    <li>
      <MisterSiteLink link={adaptedLink} title={`${title} | ${subtitle} | MR MARVIS`} className='flex w-auto flex-shrink-0 snap-center flex-col gap-4 lg:w-60'>
        <div className='relative overflow-hidden rounded-md'>
          <MisterMedia
            priority={index <= 1}
            className='h-full min-h-[320px] w-full min-w-[240px] transform-gpu object-cover transition-transform duration-300 hover:scale-105'
            media={image}
            desktopSizes='16rem'
            mobileSizes='16rem'
          />
        </div>
        <div className='flex flex-col gap-1'>
          <div>
            {item?.new && (
              <Badge size='lg' className='float-right mx-1'>
                {translate('new', 'New')}
              </Badge>
            )}
            <span className='text-body-xl-bold text-brand-blue'>{title}</span>
          </div>
          <span className='text-pretty pr-4 text-body-md text-brand-blue'>{subtitle}</span>
        </div>
      </MisterSiteLink>
    </li>
  )
}

interface SectionSliderProps {
  data: SectionSliderData
}

const SectionSlider: FunctionComponent<SectionSliderProps> = ({ data }) => {
  const { title, items } = data || {}
  const { width } = useWindowSize()
  const sliderRef = useRef<HTMLUListElement>(null)
  const [scrollableWidth, setScrollableWidth] = useState<number>(0)
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const [useDragHandler, setUseDragHandler] = useToggle(false)
  const { events } = useDraggable(sliderRef as unknown as MutableRefObject<HTMLInputElement>, { isMounted: useDragHandler })

  // A/B test Section Slider Redesign
  // @see https://eu.posthog.com/project/12378/experiments/25778
  const flag = 'section-slider-redesign'
  const variant: 'control' | 'legacy' | string | boolean | undefined = useExperimentFeatureFlag(flag)
  const isLegacy = variant === 'legacy'

  // Width of the card in pixel, plus grid gap between card and next card
  const cardWidth = 240 + 8

  useEffect(() => {
    setUseDragHandler(!isTouchScreen)
  }, [setUseDragHandler])

  const handleArrowClick = (direction: 'left' | 'right') => {
    sliderRef?.current?.scrollTo({
      left: direction === 'left' ? scrollPosition - cardWidth : scrollPosition + cardWidth,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    const cleanUpRef = sliderRef?.current

    const updateScrollDimensions = () => {
      setScrollableWidth((sliderRef?.current?.scrollWidth || 0) - (sliderRef?.current?.clientWidth || 0))
      setScrollPosition(sliderRef?.current?.scrollLeft || 0)
    }

    const handleScroll = () => {
      setScrollPosition(sliderRef?.current?.scrollLeft || 0)
    }

    updateScrollDimensions()
    sliderRef?.current?.addEventListener('scroll', handleScroll)

    return () => {
      cleanUpRef?.removeEventListener('scroll', handleScroll)
      // Reset state when unmounting or when data changes
      setScrollableWidth(0)
      setScrollPosition(0)
    }
  }, [sliderRef, width, data._id])

  const showLeftButton = useMemo(() => scrollPosition > 0 && !!sliderRef?.current?.clientWidth, [scrollPosition, sliderRef])
  const showRightButton = useMemo(() => scrollableWidth > scrollPosition + 10, [scrollableWidth, scrollPosition])

  if (items.length === 0) {
    return <ul ref={sliderRef} />
  }

  return (
    <MisterContainer type='fluid' dataLabel='section-slider' data-id={data._id} padding={false}>
      {title && <h2 className={cn(isLegacy && 'text-center', 'mb-8 px-6 text-heading-5 lg:text-heading-3')}>{title}</h2>}
      <div className='relative'>
        <MisterChevronButtonV2
          orientation='left'
          onClick={() => handleArrowClick('left')}
          className={cn('absolute left-8 top-[40%] z-sliderArrow hidden -translate-y-[40%] border-none', showLeftButton ? 'md:flex' : 'md:hidden')}
        />
        <MisterChevronButtonV2
          orientation='right'
          onClick={() => handleArrowClick('right')}
          className={cn('absolute right-8 top-[40%] z-sliderArrow hidden -translate-y-[40%] border-none', showRightButton ? 'md:flex' : 'md:hidden')}
        />
        <ul
          className={cn('relative flex snap-x snap-mandatory list-none gap-x-2 overflow-y-hidden overflow-x-scroll px-4 scrollbar-hide lg:px-8')}
          {...(useDragHandler ? events : undefined)}
          ref={sliderRef}
        >
          {items.map((item, index) => (
            <Fragment key={index}>
              <SliderCard index={index} item={item} isLegacy={isLegacy} />
            </Fragment>
          ))}
        </ul>
      </div>
    </MisterContainer>
  )
}

export default SectionSlider
