import { FC, PropsWithChildren, ReactNode } from 'react'
import { useInstantSearch } from 'react-instantsearch'

type Props = PropsWithChildren<{
  fallback: ReactNode
}>

const MisterEmptyQueryBoundary: FC<Props> = ({ children, fallback }) => {
  const { indexUiState } = useInstantSearch()

  if (!indexUiState.query) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    )
  }

  return children
}

export default MisterEmptyQueryBoundary
