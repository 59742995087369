import { ColorFilter, colorFilterVariants } from '@ui/components/ui/color-filter'
import { VariantProps } from 'class-variance-authority'
import { FC } from 'react'
import { RendererProps } from '../SearchFilterGroup'

type ColorFilterVariantProps = VariantProps<typeof colorFilterVariants>

const getColorVariant = (internalName: string): ColorFilterVariantProps['variant'] => {
  const colorVariantMap: Record<string, ColorFilterVariantProps['variant']> = {
    Pattern: 'pattern',
    Stripes: 'stripes',
    Multicolor: 'multicolor',
    default: 'default',
  }
  return colorVariantMap[internalName] || 'default'
}

const ColorRenderer: FC<RendererProps> = ({ label, value, isRefined, refine }) => {
  const [internalName, colorFamilyName, hexCode] = label.split('||')
  const colorVariant = getColorVariant(internalName as string)

  return (
    <ColorFilter pressed={isRefined} defaultPressed={isRefined} color={hexCode} variant={colorVariant} onPressedChange={() => refine(value)}>
      {colorFamilyName}
    </ColorFilter>
  )
}

export default ColorRenderer
