import { FunctionComponent, useContext } from 'react'
import MisterContainer from '../../shared/MisterContainer'
import MisterSpinner from '../../shared/loader/MisterSpinner'
import useCustomer from '../../../hooks/customer/useCustomer'
import SectionText from '../section-text/section-text'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import MisterProductCard from '../../shared/productCard/MisterProductCard'
import { WishlistContext } from '../../../contexts/Wishlist.context'
import { useUI } from '../../../contexts/UI.context'
import { Button } from '@ui/components/ui/button'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { SectionWishlistData } from 'data-access/sanity/fragments/sections/sectionWishlist.fragement'
import { getWishlistProductInformation } from 'data-access'

interface SectionWishlistProps {
  data: SectionWishlistData
  index: number
  isArticle?: boolean
}

const SectionWishlist: FunctionComponent<SectionWishlistProps> = ({ data }) => {
  const { saveWishlistButtonText, wishlistTitle, wishlistEmptyStatement, loggedOutWishListWithItemsStatement, loggedInWishListWithItemsStatement } = data || {}
  const { customer } = useCustomer()
  const { locale } = useRouter()
  const { wishlist, productIds } = useContext(WishlistContext)!
  const { wishlistCount } = useUI()

  const { data: sanityProductData, isLoading } = useQuery({
    queryKey: ['wishlistProducts', locale, productIds],
    queryFn: () => getWishlistProductInformation(productIds, locale),
    placeholderData: keepPreviousData,
    enabled: !!productIds && !!locale,
  })

  return (
    <MisterContainer dataLabel='section-wishlist' data-id={data._id} type={'fluid'} padding={false}>
      <div className='relative mb-8 mt-14 flex flex-col lg:block'>
        <SectionText index={0} data={wishlistTitle} />
        <div className='mt-4 flex justify-center text-brand-blue lg:absolute lg:right-12 lg:top-0 lg:mt-0'>
          {!customer?.isLoggedIn && productIds?.length > 0 && (
            <Button asChild>
              <MisterSiteLink link={{ page: { _type: 'account', slug: { current: 'login' } } }}>{saveWishlistButtonText || 'Save list'}</MisterSiteLink>
            </Button>
          )}
        </div>
      </div>
      {!wishlist ? (
        <div className='relative mb-16 mt-14 flex items-center justify-center text-body-lg'>
          <MisterSpinner />
        </div>
      ) : sanityProductData && sanityProductData.length ? (
        <>
          {loggedOutWishListWithItemsStatement && !customer?.isLoggedIn && <h2 className='relative my-4 text-center text-body-lg'>{loggedOutWishListWithItemsStatement}</h2>}
          {loggedInWishListWithItemsStatement && customer?.isLoggedIn && <h2 className='relative my-4 text-center text-body-lg'>{loggedInWishListWithItemsStatement}</h2>}
          <ol
            data-label='collection-grid'
            className='container relative mx-auto grid grid-cols-1 gap-2 gap-y-6 px-4 pt-4 md:grid-cols-3 md:px-8 lg:grid-cols-4 lg:gap-y-8 lg:px-10'
          >
            {sanityProductData?.map((item, i) => (
              <MisterProductCard
                key={item?._id}
                singleView // Here we hardcode the single view to allow for the quick add to cart
                quickAddToCart
                wishList
                product={{ ...item, _id: `${item._id}-${data._id}` }}
                index={i}
                imageSizes='(max-width: 768px) 100vw, (max-width: 1024px) 30vw, (max-width: 1536px) 25vw, 24rem'
              />
            ))}
          </ol>
        </>
      ) : !isLoading && !wishlistCount ? (
        <h2 className='relative mb-16 mt-14 text-center text-body-lg'>{wishlistEmptyStatement}</h2>
      ) : null}
    </MisterContainer>
  )
}

export default SectionWishlist
