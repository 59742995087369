'use client'

import * as React from 'react'
import * as LabelPrimitive from '@radix-ui/react-label'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@ui/lib/utils'

const labelVariants = cva('text-body-lg peer-disabled:cursor-not-allowed peer-disabled:text-brand-muted lg:text-body-md')

const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>>(
  ({ className, ...props }, ref) => <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props} />,
)
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
