import { cn } from '@ui/lib/utils'
import { ProductSpot as ProductSpotType } from 'data-access/sanity/fragments/components/hotspotImage.fragment'
import Link from 'next/link'
import { CSSProperties, FunctionComponent, PropsWithChildren } from 'react'
import { useUI } from 'src/contexts/UI.context'

const Component: FunctionComponent<PropsWithChildren<{ isLink: boolean; className: string; products: ProductSpotType[]; link: string }>> = ({
  isLink,
  products,
  className,
  link,
  children,
}) => {
  const { openShopTheLook } = useUI()

  return isLink ? (
    <Link href={link} className={className}>
      {children}
    </Link>
  ) : (
    <button onClick={() => openShopTheLook(products.map(({ product }) => product))} className={className}>
      {children}
    </button>
  )
}

interface ProductSpotProps {
  productSpots: ProductSpotType[]
  isLink?: boolean
  className?: string
}

const ProductSpots: FunctionComponent<ProductSpotProps> = ({ productSpots, isLink = false, className }) => {
  if (!productSpots) {
    return null
  }

  return (
    <div className={cn('pointer-events-none absolute inset-0 z-10', className)}>
      {productSpots?.map(
        ({ x, y, product }, i) =>
          product?.isAvailable && (
            <div
              key={i}
              style={{ '--x': `calc(${x}% - 8px)`, '--y': `calc(${y}% - 16px)` } as CSSProperties}
              className={cn(
                'group/dot pointer-events-auto absolute left-[var(--x)] top-[var(--y)] hidden size-6 cursor-pointer hover:opacity-100 group-hover:opacity-100 lg:block',
              )}
            >
              <Component
                isLink={isLink}
                products={productSpots}
                link={product.slug.current}
                className='pointer-events-none absolute -left-2 -top-1/2 flex w-max items-center gap-1 rounded-full pl-2 pr-1 text-left transition-colors hover:bg-white group-hover/dot:pointer-events-auto group-hover/dot:bg-white'
              >
                <div className='flex size-4 items-center justify-center rounded-full bg-white shadow-dark'>
                  <div className='size-2 rounded-full bg-brand-blue' />
                </div>
                <div className='flex flex-col px-1 py-2 leading-none opacity-0 transition-opacity group-hover/dot:opacity-100'>
                  <span className='text-body-sm'>{product.primaryCollectionTitle}</span>
                  <span className='text-body-sm-bold'>{product.color?.colorName}</span>
                </div>

                <svg
                  className='opacity-0 transition-opacity group-hover/dot:opacity-100'
                  stroke='currentColor'
                  fill='currentColor'
                  strokeWidth='0'
                  viewBox='0 0 512 512'
                  height='1em'
                  width='1em'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='48' d='M184 112l144 144-144 144'></path>
                </svg>
              </Component>
            </div>
          ),
      )}
    </div>
  )
}

export default ProductSpots
