import { FunctionComponent, useEffect, useState } from 'react'
import MisterDialog from '../../../shared/dialog/MisterDialog'
import SizeChartsHeaderInfo, { Header } from '../size-chart-header-info/size-chart-header-info'
import { SizeChart as SizeChartType } from 'data-access/sanity/fragments/components/sizeChart.fragment'
import { Switch } from '@ui/components/ui/switch'

interface Props {
  data: SizeChartType
  unitIsInch: boolean
  showUnitToggle: boolean
  collectionTitle: string
  onChangeUnit: (checked: boolean) => void
}

const SizeChart: FunctionComponent<Props> = ({ data, unitIsInch, showUnitToggle, collectionTitle, onChangeUnit }) => {
  const { title, description, headers, tableData: chart, tableDataInch: chartInch } = data
  const [infoModalData, setInfoModalData] = useState<Header | null>(null)
  const rowHeaders: string[] = []
  const rowData: string[][] = []
  let chartTitle = collectionTitle

  useEffect(() => {
    if (chartTitle && chartTitle.length > 13) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      chartTitle = chartTitle.toLowerCase().replace('the ', '').replace('die ', '').replace('los ', '').replace('las ', '').replace('el ', '').trim()
    }
  })

  const chartData = unitIsInch && chartInch ? chartInch : chart

  if (chartData) {
    chartData?.rows?.forEach((row) => {
      const cells = [...row.cells]
      rowHeaders.push(cells.shift()!)
      rowData.push(cells)
    })
  }

  return (
    <div className='mb-10 flex flex-col'>
      {title && <h3 className='py-4 text-center text-heading-5 font-bold'>{title}</h3>}
      {showUnitToggle && (
        <div className='flex items-center space-x-2 self-center'>
          <span>cm</span>
          <Switch id='size-chart-unit' checked={unitIsInch} onCheckedChange={onChangeUnit} />
          <span>inch</span>
        </div>
      )}
      {description && <span className='py-3 text-center text-body-md font-normal lg:py-6'>{description}</span>}
      {infoModalData?.headerText?.description && (
        <MisterDialog isOpen={!!infoModalData} onClose={() => setInfoModalData(null)} size='small' title={infoModalData?.headerText.title || title || chartTitle}>
          <SizeChartsHeaderInfo data={infoModalData} />
        </MisterDialog>
      )}
      <div className='flex'>
        <div className='z-sizeGuide pt-10'>
          <table>
            <tbody>
              <tr className='flex h-16 w-24 flex-col justify-center bg-brand-blue px-1 py-4 text-white'>
                <th className='flex flex-col items-center text-body-sm-bold'>
                  {chartTitle && chartTitle?.length < 14 && <span>MR MARVIS</span>}
                  <span>{chartTitle}</span>
                </th>
              </tr>
              <tr className='flex w-24 flex-col items-center shadow-[7px_0px_9px_-9px_rgba(0,0,0,0.5)]'>
                {rowHeaders?.map((row, i) => {
                  return (
                    <td key={i} className='w-full py-2 text-center text-body-sm-bold even:bg-brand-grey'>
                      {row}
                    </td>
                  )
                })}
              </tr>
            </tbody>
          </table>
        </div>
        <div className='relative w-full overflow-scroll overscroll-none pt-10 scrollbar-hide'>
          <table className='relative pt-10 text-center'>
            <tbody>
              <tr>
                {headers?.map((header, i) => {
                  return (
                    <th key={i} className='w-32 min-w-[5rem] bg-brand-grey p-0 text-body-sm-bold'>
                      <div className='flex h-16 items-center justify-center px-1 sm:px-3'>
                        {header?.headerText?.title}
                        {header?.headerText?.description && (
                          <button className='absolute flex -translate-y-14 items-center justify-center' onClick={() => setInfoModalData(header)}>
                            <div className='flex size-6 items-center justify-center rounded-full border-2 border-gray-400 text-body-sm-bold text-gray-500 duration-150 hover:border-brand-blue hover:text-brand-blue'>
                              ?
                            </div>
                          </button>
                        )}
                      </div>
                    </th>
                  )
                })}
              </tr>
              {rowData?.map((row, i) => {
                return (
                  <tr className='odd:bg-brand-grey hover:bg-brand-blue-light' key={i}>
                    {row.map((cell, i) => {
                      return (
                        <td key={i} className='py-2 text-body-sm'>
                          {cell}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default SizeChart
