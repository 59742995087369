import { FunctionComponent } from 'react'
import MisterContainer from '../../shared/MisterContainer'
import { useTranslations } from '../../../contexts/Globals.context'
import MisterArticleCard from './article-card/MisterArticleCard'
import { Article } from 'data-access/sanity/fragments/components/relatedArticles.fragments'

interface RelatedArticlesProps {
  relatedArticles: Article[]
}

const RelatedArticles: FunctionComponent<RelatedArticlesProps> = ({ relatedArticles }) => {
  const translate = useTranslations()

  return (
    <MisterContainer>
      <h2 className='mb-8 text-center text-heading-4 font-bold lg:mb-14'>{translate('otherStories', 'Related')}</h2>
      <ul className='mb-14 grid grid-cols-1 gap-y-10 sm:gap-x-4 md:grid-cols-3 lg:gap-y-14 lg:p-0'>
        {relatedArticles?.map((article, index: number) => <MisterArticleCard index={index} key={article._id} article={article} titleAs='h3' />)}
      </ul>
    </MisterContainer>
  )
}

export default RelatedArticles
