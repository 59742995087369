import { DoubleSlider } from '@ui/components/ui/double-slider'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useRange } from 'react-instantsearch'
import { useRouter } from 'next/router'
import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import { Input } from '@ui/components/ui/input'
import { RendererProps } from '../SearchFilterGroup'

const PriceRenderer: FC<Pick<RendererProps, 'attribute'>> = ({ attribute }) => {
  const { locale } = useRouter()
  const minRef = useRef<HTMLInputElement>(null)
  const maxRef = useRef<HTMLInputElement>(null)
  const { start, range, canRefine, refine } = useRange({ attribute })
  const { min, max } = range
  const [value, setValue] = useState({ start: min ?? 0, end: max ?? 0 })
  const [tempValue, setTempValue] = useState({ start: min ?? 0, end: max ?? 0 })
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null)

  const handleRefine = useCallback(
    (newValue: { start: number; end: number }) => {
      trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_FILTER, {
        filtergroup: attribute,
        filterValue: newValue,
      })
      refine([newValue.start, newValue.end])
    },
    [refine, attribute],
  )

  const isValidMin = (newMin: number) => min && newMin >= min && newMin < value.end
  const isValidMax = (newMax: number) => max && newMax <= max && newMax > value.start

  const debounceChange = (callback: () => void, delay: number) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }
    const timeout = setTimeout(callback, delay)
    setDebounceTimeout(timeout)
  }

  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMin = Number(e.target.value)
    setTempValue((prev) => ({ ...prev, start: newMin }))
    debounceChange(() => {
      if (isValidMin(newMin)) {
        setValue((prev) => ({ ...prev, start: newMin }))
        handleRefine({ start: newMin, end: value.end })
      }
    }, 300) // Debounce set to 300ms
  }

  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMax = Number(e.target.value)
    setTempValue((prev) => ({ ...prev, end: newMax }))
    debounceChange(() => {
      if (isValidMax(newMax)) {
        setValue((prev) => ({ ...prev, end: newMax }))
        handleRefine({ start: value.start, end: newMax })
      }
    }, 300) // Debounce set to 300ms
  }

  const handleMinBlur = () => {
    let newMin = tempValue.start
    if (!isValidMin(newMin)) {
      newMin = value.start
    }
    setValue((prev) => ({ ...prev, start: newMin }))
    setTempValue((prev) => ({ ...prev, start: newMin }))
    handleRefine({ start: newMin, end: value.end })
  }

  const handleMaxBlur = () => {
    let newMax = tempValue.end
    if (!isValidMax(newMax)) {
      newMax = value.end
    }
    setValue((prev) => ({ ...prev, end: newMax }))
    setTempValue((prev) => ({ ...prev, end: newMax }))
    handleRefine({ start: value.start, end: newMax })
  }

  const handleSliderChange = (newValues: [number, number]) => {
    const [newMin, newMax] = newValues
    setValue({ start: newMin, end: newMax })
    setTempValue({ start: newMin, end: newMax }) // Sync tempValue with the slider
  }

  const handleSliderCommit = () => {
    handleRefine({ start: value.start, end: value.end })
  }

  const currency = locale === 'dk' ? 'DKK' : locale === 'gb' ? '£' : '€'

  useEffect(() => {
    if (min !== undefined && max !== undefined) {
      // Set initial values when min and max are available
      setValue({ start: start[0] && start[0] < min ? min : start[0] || min, end: start[1] && start[1] > max ? max : start[1] || max })
      setTempValue({ start: start[0] && start[0] < min ? min : start[0] || min, end: start[1] && start[1] > max ? max : start[1] || max })
    }
  }, [min, max, start])

  if (min === undefined || max === undefined) {
    return null
  }

  return (
    <div className='flex w-full flex-col gap-4'>
      <DoubleSlider
        min={min}
        max={max}
        defaultValue={[min, max]}
        value={[value.start, value.end]}
        minStepsBetweenThumbs={1}
        disabled={!canRefine}
        onValueChange={handleSliderChange}
        onValueCommit={handleSliderCommit}
      />
      <div className='flex flex-row justify-between'>
        <div className='flex items-center gap-0.5 rounded-md border-[1.5px] border-brand-blue-light px-3 py-2'>
          <span>{currency}</span>
          <Input
            ref={minRef}
            type='number'
            className='m-0 h-min w-min min-w-0 border-0 p-0 text-center [appearance:textfield] focus-visible:ring-offset-2 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none'
            min={min}
            max={max}
            value={tempValue.start}
            inputMode='numeric'
            onChange={handleMinChange}
            onBlur={handleMinBlur}
          />
        </div>
        <div className='flex items-center gap-0.5 rounded-md border-[1.5px] border-brand-blue-light px-3'>
          <span>{currency}</span>
          <Input
            ref={maxRef}
            type='number'
            className='m-0 h-min w-min min-w-0 border-0 p-0 text-center [appearance:textfield] focus-visible:ring-offset-2 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none'
            min={min}
            max={max}
            value={tempValue.end}
            inputMode='numeric'
            onChange={handleMaxChange}
            onBlur={handleMaxBlur}
          />
        </div>
      </div>
    </div>
  )
}

export default PriceRenderer
