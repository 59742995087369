import React, { FunctionComponent } from 'react'
import { useRouter } from 'next/router'
import { useCookie } from 'react-use'
import { useQuery } from '@tanstack/react-query'
import { I18N_COUNTRY_CODE, I18N_COUNTRY_CODE_RAW } from '../../../middleware'
import RichText from '../../shared/rich-text/rich-text'
import { cn } from 'ui/lib/utils'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import MisterDropdownMenu from './MisterDropdownMenu'
import { Button } from '@ui/components/ui/button'
import { CountryPicker } from './CountryPicker'
import { useUI } from 'src/contexts/UI.context'
import { Link } from 'data-access/sanity/fragments/components/link.fragment'
import { AnnouncementBar, AnnouncementBarItem } from 'data-access/sanity/fragments/components/announcementBar.fragment'
import { getLocalizedAnnouncementBar, SecondaryMenuItem } from 'data-access'

interface MisterAnnouncementBarProps {
  defaultAnnouncementBar?: AnnouncementBar
  duration?: number
  secondaryMenuItems?: (SecondaryMenuItem | Link | (SecondaryMenuItem & Link))[]
}

const MisterAnnouncementBar: FunctionComponent<MisterAnnouncementBarProps> = ({ defaultAnnouncementBar, secondaryMenuItems, duration = 5 }) => {
  const { locale } = useRouter()
  const { displayMobileMenu, displaySearchUI } = useUI()
  const [geoCountry] = useCookie(I18N_COUNTRY_CODE) // Cloudflare country code, filter on Next.js locales
  const [geoCurrentCountry] = useCookie(I18N_COUNTRY_CODE_RAW) // Cloudflare country code, unfiltered

  // Query by country/region, return copy in locale; in case object does not exist for a
  // region not defined in data model (e.g. chAnnouncementBar), rely on fallback/defaultAnnouncementBar
  const { data } = useQuery({
    queryKey: ['announcementBar', geoCurrentCountry, locale, geoCountry],
    queryFn: () => getLocalizedAnnouncementBar(geoCurrentCountry?.toLowerCase() || 'nonExistent', geoCountry || locale),
    enabled: !!geoCurrentCountry,
    initialData: defaultAnnouncementBar || undefined,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  })

  const announcementBar = data || defaultAnnouncementBar
  const items = (announcementBar?.announcementBarItems || []).filter((item) => (Array.isArray(item.body) ? item.body?.[0]?.children?.[0]?.text || '' : item.body).length > 3)
  const itemCount = items.length

  const announcementBarText = (text: AnnouncementBarItem['body']) => {
    if (Array.isArray(text)) {
      return <RichText data={text} className='text-body-md text-white' />
    }

    return text
  }

  const hideAnnouncementBar = displayMobileMenu || displaySearchUI

  return (
    <div
      data-announcement-bar
      className={cn(
        'relative flex h-announcement-bar-height bg-brand-blue px-10 transition-[height,background-color] duration-500 ease-out lg:ease-in-out',
        hideAnnouncementBar && 'h-0 bg-white',
      )}
    >
      <ul className='group absolute inset-0 flex w-full overflow-hidden text-center'>
        {items.map((item, i) => (
          <li
            key={`announcement-${i}`}
            style={{ animationDuration: `${itemCount * duration}s`, animationDelay: `${i * duration}s` }}
            className={cn(
              'absolute inset-0 flex h-announcement-bar-height w-full items-center justify-center overflow-hidden px-10 text-center lg:text-left xl:text-center',
              itemCount > 1 && 'pointer-events-none animate-ticker opacity-0 will-change-[opacity] md:group-hover:[animation-play-state:paused]',
            )}
          >
            {item.link ? (
              <MisterSiteLink className='w-full' link={item.link}>
                {announcementBarText(item.body)}
              </MisterSiteLink>
            ) : (
              announcementBarText(item.body)
            )}
          </li>
        ))}
      </ul>
      <nav className='z-10 ml-auto hidden items-center text-white lg:flex'>
        {secondaryMenuItems &&
          secondaryMenuItems.length &&
          secondaryMenuItems.map((item, i) =>
            item?._type === 'link' ? (
              <div key={i} className='flex items-center px-3'>
                <MisterSiteLink key={i} link={item} className='contents'>
                  <Button variant='link' hover='underline' className='text-body-md text-white'>
                    {item.linkText}
                  </Button>
                </MisterSiteLink>
              </div>
            ) : (
              item?._type === 'secondaryMenuItem' && <MisterDropdownMenu key={i} item={item} align='start' />
            ),
          )}
        <CountryPicker />
      </nav>
    </div>
  )
}

export default MisterAnnouncementBar
