import { useTranslations } from '../../../contexts/Globals.context'
import { Fragment, FunctionComponent } from 'react'
import { Badge } from '@ui/components/ui/badge'

interface MisterBadgeProps {
  badges?: {
    limitedEdition?: boolean
    new?: boolean
    widerStripes?: boolean
  }
  discontinued?: boolean
  productIsAvailable?: boolean
}

const MisterBadge: FunctionComponent<MisterBadgeProps> = ({ badges, discontinued, productIsAvailable = true }) => {
  const translate = useTranslations()

  const MAX_BADGES = 2
  let badgesCount = 0

  const badgesToShow = [
    {
      badge: 'comingSoon',
      show: !discontinued && !productIsAvailable,
      value: <Badge className='z-productCard truncate bg-white text-brand-blue'>{translate('comingSoon', 'Coming soon')}</Badge>,
    },
    {
      badge: 'soldOut',
      show: discontinued && !productIsAvailable,
      value: <Badge className='z-productCard truncate bg-brand-grey-dark'>{translate('addToCartButtonSoldOutText', 'Sold out')}</Badge>,
    },
    { badge: 'new', show: badges?.new && productIsAvailable, value: <Badge className='z-productCard'>{translate('new', 'New')}</Badge> },
    {
      badge: 'limitedEdition',
      show: badges?.limitedEdition && productIsAvailable,
      value: <Badge className='z-productCard truncate bg-brand-brass'>{translate('limitedEdition', 'Limited edition')}</Badge>,
    },
    {
      badge: 'widerStripes',
      show: badges?.widerStripes && productIsAvailable,
      value: <Badge className='z-productCard truncate bg-white text-brand-blue'>{translate('widerStripes', 'Wider stripes')}</Badge>,
    },
  ]

  return (
    <div className='absolute end-2 top-2 flex flex-row-reverse gap-2 md:end-4 md:top-4'>
      {badgesToShow.map(({ show, value }, i) => {
        if (show && badgesCount < MAX_BADGES) {
          badgesCount++
          return <Fragment key={i}>{value}</Fragment>
        }
      })}
    </div>
  )
}

export default MisterBadge
