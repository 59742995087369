import { FunctionComponent } from 'react'
import MisterSiteLink from '../../shared/site-link/MisterSiteLink'
import { useRouter } from 'next/router'
import { cn } from 'ui/lib/utils'
import MisterContainer from '../../shared/MisterContainer'
import { SectionNavigationData } from 'data-access/sanity/fragments/sections/sectionNavigation.fragment'

export interface SectionNavigationProps {
  data: SectionNavigationData
}

const SectionNavigation: FunctionComponent<SectionNavigationProps> = ({ data }) => {
  const { navigationItems } = data || {}
  const {
    query: { slug },
  } = useRouter()

  return (
    <MisterContainer
      dataLabel='section-navigation'
      className='grid grid-cols-2 justify-items-center gap-1 gap-y-4 px-2 md:flex md:flex-wrap md:items-center md:justify-center md:gap-2 lg:gap-8'
      padding={false}
      data-id={data._id}
    >
      {navigationItems?.map((item, i: number) => {
        const isCurrent = item?.page?.slug?.current === slug
        return (
          <MisterSiteLink
            key={`navigationItem${i}`}
            link={item}
            className={cn(
              isCurrent ? 'after:border' : 'opacity-50 hover:opacity-100 hover:after:border',
              item?.linkText?.length && item?.linkText?.length > 12 ? 'px-4' : 'px-6',
              'relative block w-max py-2 text-btn-md after:absolute after:inset-0 after:rounded-full after:border-brand-blue md:px-8',
            )}
          >
            {item?.linkText}
          </MisterSiteLink>
        )
      })}
    </MisterContainer>
  )
}

export default SectionNavigation
