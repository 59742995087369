import { FunctionComponent } from 'react'
import MisterMedia from '../../../shared/media/MisterMedia'
import { Button } from '@ui/components/ui/button'
import { Media } from 'data-access/sanity/fragments/components/media.fragment'

interface SizeChartsListItemProps {
  image?: Media
  buttonText: string
  collectionTitle: string
  onClick: () => void
}

const SizeChartsListItem: FunctionComponent<SizeChartsListItemProps> = ({ image, buttonText, collectionTitle, onClick }) => {
  return (
    <div className='flex items-center justify-between border border-brand-grey p-4 sm:justify-evenly'>
      <MisterMedia media={image} className='!size-24' mobileSizes='6rem' desktopSizes='6rem' />
      <div className='flex flex-col items-center gap-4'>
        <span className='text-body-md-bold'>{collectionTitle}</span>
        <Button size='md' onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default SizeChartsListItem
