import { FunctionComponent, useState } from 'react'
import { TrackingForm } from './blocks/trackingForm'
import { DeliveryStatus } from './blocks/deliveryStatus'
import { Checkpoints } from './blocks/checkpoints'
import { Carrier } from './blocks/carrier'
import { useTranslations } from 'src/contexts/Globals.context'
import { Button } from '@ui/components/ui/button'
import { SectionAftershipData } from 'data-access/sanity/fragments/sections/sectionAftership.fragment'
import AfterShipProducts from './blocks/AfterShipProducts'

export type CarrierSlug = 'dhl-germany' | 'postnl' | 'royal-mail' | 'dpd-de' | 'bpost' | 'spain-correos-es' | 'postnord' | 'la-poste-colissimo' | 'poste-italiane' | 'fedex' | 'ups'

type Tag = 'Pending' | 'InfoReceived' | 'InTransit' | 'OutForDelivery' | 'AttemptFail' | 'Delivered' | 'AvailableForPickup' | 'Exception' | 'Expired'
interface Props {
  data: SectionAftershipData
}

interface Checkpoint {
  location?: string
  city?: string
  state?: string
  country_name: string
  message: string
  tag: Tag
  subtag_message: string
  checkpoint_time: string
}

export interface TrackingInfo {
  expected_delivery?: string
  aftership_estimated_delivery_date?: string
  tag: Tag
  checkpoints?: Checkpoint[]
  custom_fields?: {
    shipment_type?: string
    item_names?: string
  }
  courier_tracking_link?: string
  failed_delivery_attempts?: number
  shipment_type?: string
  tracking_number?: string
  origin_raw_location?: string
  pickup_location?: string
  slug?: CarrierSlug
}

const SectionAftership: FunctionComponent<Props> = () => {
  const translate = useTranslations()
  const [shipment, setShipment] = useState<TrackingInfo | undefined>(undefined)

  const redoTracking = () => {
    window.scroll({ top: -60, left: 0, behavior: 'smooth' })
    setShipment(undefined)
  }

  if (shipment) {
    return (
      <div className='m-auto flex max-w-[520px] flex-col gap-12 px-4 pb-16 pt-16 transition-all duration-1000 lg:pt-0'>
        <DeliveryStatus shipment={shipment} />
        <Checkpoints shipment={shipment} />
        <Carrier shipment={shipment} />
        <AfterShipProducts shipment={shipment} />
        <Button variant='outline' onClick={() => redoTracking()}>
          {translate('goBackToTrack', 'Go back to track')}{' '}
        </Button>
      </div>
    )
  }

  return <TrackingForm setShipment={setShipment} />
}

export default SectionAftership
