import { cn } from 'ui/lib/utils'
import { CSSProperties, ElementType, FunctionComponent, HTMLAttributes, PropsWithChildren } from 'react'
import { useGlobalTopMargin } from '../globalState/MisterScrollMargin'

interface MisterContainerProps<T extends ElementType = 'section'> extends HTMLAttributes<T> {
  dataLabel?: string
  padding?: boolean
  type?: 'fluid' | 'default' | 'small'
  backgroundColor?: string
  backgroundColorDesktop?: string
  className?: string
  as?: T
  onClick?: () => void
}

const MisterContainer = <T extends ElementType = 'section'>({
  dataLabel = undefined,
  padding = true,
  children,
  type = 'default',
  backgroundColor,
  backgroundColorDesktop,
  className,
  as = 'section' as T,
  onClick,
  style,
  ...rest
}: PropsWithChildren<MisterContainerProps<T>>): ReturnType<FunctionComponent<MisterContainerProps<T>>> => {
  const Tag = as as ElementType
  const [{ scrollTopMargin }] = useGlobalTopMargin()

  return (
    <Tag
      onClick={onClick}
      {...rest}
      data-label={dataLabel}
      style={
        {
          '--backgroundColorMobile': backgroundColor,
          '--backgroundColorDesktop': backgroundColorDesktop,
          '--scrollTopMargin': `${scrollTopMargin}px`,
          ...style,
        } as CSSProperties
      }
      className={cn(
        'scroll-mt-[var(--scrollTopMargin)]',
        padding && 'px-4 md:px-10',
        type === 'default' ? 'max-w-[90rem]' : type === 'small' ? 'max-w-[48rem]' : 'max-w-screen w-full',
        backgroundColor && 'bg-[var(--backgroundColorMobile)]',
        backgroundColorDesktop && 'bg-[var(--backgroundColorDesktop)]',
        className,
      )}
    >
      {children}
    </Tag>
  )
}

export default MisterContainer
