import { Button } from '@ui/components/ui/button'
import { cn } from '@ui/lib/utils'
import React, { FunctionComponent } from 'react'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { useTranslations } from 'src/contexts/Globals.context'
import { useUI } from 'src/contexts/UI.context'
import WishListIcon from '../../icons/Wishlist.icon'
import { Slug } from 'data-access/sanity/types'

interface WishlistButtonProps {
  wishlistSlug: {
    slug: Slug
  }
}

export const WishlistButton: FunctionComponent<WishlistButtonProps> = ({ wishlistSlug }) => {
  const translate = useTranslations()
  const { wishlistCount } = useUI()

  return (
    <Button asChild variant='ghost' size='icon' className='relative hidden lg:flex'>
      <MisterSiteLink
        className='flex items-center'
        title={translate('wishlist', 'Wishlist')}
        link={{
          type: 'internal',
          page: { _type: undefined, slug: { current: wishlistSlug.slug.current || 'wishlist' } },
        }}
      >
        <WishListIcon className={cn(wishlistCount && wishlistCount > 0 ? 'fill-current' : 'fill-transparent')} />

        <div
          className={cn(
            'absolute right-0 top-0 flex size-4 items-center justify-center rounded-full bg-brand-warning text-center text-body-xs font-semibold text-white transition-all duration-75',
            wishlistCount && wishlistCount > 0 ? 'scale-100 opacity-100' : 'scale-0 opacity-0',
          )}
        >
          {wishlistCount}
        </div>
      </MisterSiteLink>
    </Button>
  )
}
