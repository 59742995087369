import { FunctionComponent } from 'react'
import MisterContainer from '../../shared/MisterContainer'
import SectionText from '../section-text/section-text'
import { SectionTwoColumnsData } from 'data-access/sanity/fragments/sections/sectionTwoColumns.fragment'

interface SectionTwoColumnsProps {
  data: SectionTwoColumnsData
}

const SectionTwoColumns: FunctionComponent<SectionTwoColumnsProps> = ({ data }) => {
  const { sections, width } = data || {}

  return (
    <MisterContainer className='grid grid-cols-1 md:grid-cols-2' type={width} data-id={data._id}>
      {sections?.map((section, index) => <SectionText key={`two-column-${section?.title}-${index}`} index={index} data={section} />)}
    </MisterContainer>
  )
}

export default SectionTwoColumns
