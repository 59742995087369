import { SectionProductCardData } from 'data-access/sanity/fragments/sections/sectionProductCard.fragment'
import Image from 'next/image'
import { FunctionComponent } from 'react'
import { sanityImageLoader } from 'sanity-helpers'
import MisterProductCard from 'src/components/shared/productCard/MisterProductCard'
import { useSectionLayout } from 'src/contexts/SectionLayout.context'

interface SectionProductCardProps {
  data: SectionProductCardData
  index: number
}

const SectionProductCard: FunctionComponent<SectionProductCardProps> = ({ data, index }) => {
  const { sizes } = useSectionLayout(data._id) || {}
  return (
    <section className='group grid size-full grid-cols-1 grid-rows-1'>
      <MisterProductCard
        className='col-span-1 col-start-1 row-span-1 row-start-1'
        wishList
        quickAddToCart
        imageSizes={sizes || '100vw'}
        index={index}
        product={{ ...data.product, _id: `${data.product._id}-${data._id}` }}
        squareImage={data.square}
      />
      {data.hoverImage && (
        <Image
          className='col-span-1 col-start-1 row-span-1 row-start-1 object-cover transition-opacity group-focus-within:opacity-0 group-hover:opacity-0'
          src={data.hoverImage.url}
          sizes={sizes || '100vw'}
          loader={sanityImageLoader}
          fill
          alt=''
        />
      )}
    </section>
  )
}

export default SectionProductCard
