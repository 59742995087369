import { cn } from 'ui/lib/utils'
import { FunctionComponent, useRef } from 'react'
import { useInView } from '../../../hooks/utils/useInView'
import { useMedia, useUpdateEffect } from 'react-use'
import { Image, urlForImage } from 'sanity-helpers'
import MisterVideoPauseButton from './MisterVideoPauseButton'

export interface MisterVideoProps {
  type?: string
  className?: string | null
  id?: string
  videoPoster?: Image
  videoPosterDesktop?: Image
  videoContain?: boolean
  autoplay?: boolean
  controls?: boolean
  defaultMuted?: boolean
  remoteVideoUrl?: string
  remoteVideoUrlDesktop?: string
  priority?: boolean
  pauseButton?: boolean
}

const MisterVideo: FunctionComponent<MisterVideoProps> = ({
  id,
  className,
  videoPoster,
  videoPosterDesktop,
  autoplay = true,
  controls = true,
  defaultMuted = true,
  videoContain = false,
  remoteVideoUrl,
  remoteVideoUrlDesktop,
  priority = false,
  pauseButton = false,
}) => {
  const mobileContainerRef = useRef<HTMLDivElement>(null)
  const desktopContainerRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const videoRefDesktop = useRef<HTMLVideoElement>(null)
  const { inView } = useInView(mobileContainerRef, 0)
  const { horizontallyInView } = useInView(mobileContainerRef, -30)
  const { inView: inViewDesktop } = useInView(desktopContainerRef, 0)
  const isDesktop = useMedia('(min-width: 768px)', false)
  const poster = urlForImage(videoPoster)
  const posterDesktop = urlForImage(videoPosterDesktop)

  // Artificial implementation of autoplay once in view
  useUpdateEffect(() => {
    if (mobileContainerRef?.current && autoplay && (!isDesktop || !desktopContainerRef?.current)) {
      const isInView = isDesktop ? inView : horizontallyInView

      videoRefDesktop?.current?.pause()
      if (isInView) {
        videoRef?.current?.play()
      } else {
        videoRef?.current?.pause()
      }
    }

    if (desktopContainerRef?.current && autoplay && isDesktop) {
      videoRef?.current?.pause()
      if (inViewDesktop) {
        videoRefDesktop?.current?.play()
      } else {
        videoRefDesktop?.current?.pause()
      }
    }
  }, [horizontallyInView, inViewDesktop, isDesktop])

  if (!remoteVideoUrl && !remoteVideoUrlDesktop) {
    return null
  }

  return (
    <>
      {/* Mobile */}
      {remoteVideoUrl && (
        <div id={id} ref={mobileContainerRef} className={cn(className, pauseButton && 'group relative')}>
          <video
            key={`${id}-1`}
            className={cn('size-full', videoContain ? 'object-contain' : 'object-cover', remoteVideoUrlDesktop && 'md:hidden')}
            controls={controls}
            muted={defaultMuted}
            poster={poster?.src}
            ref={videoRef}
            preload={priority ? 'auto' : 'none'}
            loop
            disableRemotePlayback
            playsInline
          >
            <source src={remoteVideoUrl} />
            <track kind='captions' />
          </video>
          {pauseButton && videoRef && <MisterVideoPauseButton videoRef={videoRef} />}
        </div>
      )}
      {/* Desktop */}
      {remoteVideoUrlDesktop && (
        <div id={id} ref={desktopContainerRef} className={cn(className, pauseButton && videoRef && 'group relative')}>
          <video
            key={`${id}-2`}
            className={cn('hidden size-full md:block', videoContain ? 'object-contain' : 'object-cover')}
            controls={controls}
            muted={defaultMuted}
            poster={posterDesktop?.src || poster?.src}
            ref={videoRefDesktop}
            preload={priority ? 'auto' : 'none'}
            loop
            disableRemotePlayback
            playsInline
          >
            <source src={remoteVideoUrlDesktop} />
            <track kind='captions' />
          </video>
          {pauseButton && <MisterVideoPauseButton videoRef={videoRefDesktop} />}
        </div>
      )}
    </>
  )
}

export default MisterVideo
