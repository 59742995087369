import { FunctionComponent } from 'react'
import useForgotPassword from 'src/hooks/customer/useForgotPassword'
import { Button } from '@ui/components/ui/button'
import { LoaderCircle } from 'lucide-react'
import { Form, FormControl, FormField, FormItem, FormMessage, useForm } from '@ui/components/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { InputFloatingLabel } from '@ui/components/ui/input-floating-label'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import MisterAuthPageContainer from 'src/components/shared/account/MisterAuthPageContainer'
import { SectionForgotPasswordData } from 'data-access/sanity/fragments/sections/sectionForgotPassword.fragment'

interface SectionForgotPasswordProps {
  data: SectionForgotPasswordData
}

const SectionForgotPassword: FunctionComponent<SectionForgotPasswordProps> = ({ data }) => {
  const { title, subtitle, backgroundImage, emailLabel, emailErrorMessage, submitSuccessTitle, submitSuccessSubtitle, submitButtonText, returnToLoginLink } = data
  const [forgetPassword, forgotPasswordState] = useForgotPassword()

  const forgotPasswordFormSchema = z.object({
    email: z.string().email({ message: emailErrorMessage }),
  })

  const form = useForm<z.infer<typeof forgotPasswordFormSchema>>({
    mode: 'onBlur',
    resolver: zodResolver(forgotPasswordFormSchema),
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = async (values: z.infer<typeof forgotPasswordFormSchema>) => {
    await forgetPassword(values.email)
  }

  return (
    <MisterAuthPageContainer image={backgroundImage}>
      <div className='flex flex-col gap-9'>
        {!forgotPasswordState.response ? (
          <>
            <div className='flex flex-col gap-2'>
              <h2 className='text-heading-3'>{title}</h2>
              {subtitle && <p className='text-body-md'>{subtitle}</p>}
            </div>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className='flex flex-col gap-7'>
                <FormField
                  control={form.control}
                  name='email'
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <InputFloatingLabel label={emailLabel} type='email' {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className='flex flex-col gap-2'>
                  <div className='flex flex-col gap-4'>
                    <Button type='submit' variant='primary'>
                      {forgotPasswordState.loading ? <LoaderCircle className='size-4 animate-spin' /> : submitButtonText}
                    </Button>

                    <MisterSiteLink className='text-center text-body-sm underline' link={returnToLoginLink}>
                      {returnToLoginLink.linkText}
                    </MisterSiteLink>
                  </div>
                  {forgotPasswordState.error && <FormMessage className='text-center'>{forgotPasswordState.error}</FormMessage>}
                </div>
              </form>
            </Form>
          </>
        ) : (
          <div className='flex flex-col gap-9'>
            <div className='flex flex-col gap-2'>
              <h2 className='text-heading-3'>{submitSuccessTitle}</h2>
              {submitSuccessSubtitle && <p className='text-body-md'>{submitSuccessSubtitle}</p>}
            </div>
            <MisterSiteLink link={returnToLoginLink}>
              <Button variant='outline' className='w-full'>
                {returnToLoginLink.linkText}
              </Button>
            </MisterSiteLink>
          </div>
        )}
      </div>
    </MisterAuthPageContainer>
  )
}

export default SectionForgotPassword
