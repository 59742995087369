import type { Transaction, IdentifiedSanityDocumentStub, QueryParams } from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'
import type { SanityAsset, SanityReference } from '@sanity/image-url/lib/types/types'
import { groq } from 'next-sanity'
import { sanityCdnClient } from './client'

const cdnBaseUrl = process.env.NEXT_PUBLIC_SANITY_CDN_BASE_URL || 'https://cdn.mrmarvis.com'

export const sanityImageBuilder = imageUrlBuilder(sanityCdnClient).withOptions({
  // Custom CDN domain
  // @see https://www.sanity.io/docs/image-url#custom-cdn-domains
  baseUrl: cdnBaseUrl,
})

export interface Image {
  _type: 'image'
  url: string
  originalFilename: string
  lqip: string
  width: number
  height: number
  crop?: {
    top: number
    bottom: number
    left: number
    right: number
  }
  hotspot?: {
    height: number
    x: number
    y: number
  }
  // These are only optional because we don't send the reference to algolia as we don't currently use hotspot or crop for those images
  asset?: SanityReference
  isOpaque?: boolean
}

/**
 * Sanity image builder.
 *
 * @see https://www.sanity.io/docs/image-urls
 * @see https://www.sanity.io/docs/image-url
 */
export const baseImageBuilder = (image: Image, quality = 60) => {
  // We don't get the asset reference from Algolia so we fallback to the URL to build the image
  const fallbackIfNoImageAsset = image?.asset ? image : image.url

  return sanityImageBuilder.image(fallbackIfNoImageAsset).auto('format').quality(quality).fit('min')
}

/**
 * Offer a sensible set of image defaults.
 *
 * @see https://www.sanity.io/docs/image-urls
 * @see https://www.sanity.io/docs/image-url
 */
export const urlForImage = (image?: Image) => {
  if (!image) {
    return undefined
  }

  // Dimensions are the same as original asset, offering a sensible
  // default, but for a responsive srcset, calculation is needed by
  // manually using baseImageBuilder() and setting widths and heights.
  const width = image.width
  const height = image.height

  const src = baseImageBuilder(image).width(width).height(height).url()

  return {
    src,
    width,
    height,
  }
}

/**
 *  Custom loader to pass along to Next.js Image component as 'loader={sanityImageLoader}'.
 *  Doing so will allow the Image component to use the Sanity image API to fetch images,
 *  and not route images through the Next.js image optimization pipeline. So, recommended!
 *
 *  Next.js App router can and should use the NextSanityImage from packages/sanity/nextjs.ts
 *  where you no longer need to include this custom loader.
 *
 * @see https://github.com/sanity-io/next-sanity/blob/main/packages/next-sanity/src/image/imageLoader.ts
 */
export const sanityImageLoader = ({ src, width, quality }: { src: string; width: number; quality?: number }) => {
  const url = new URL(src)
  url.searchParams.set('auto', 'format')
  url.searchParams.set('fit', url.searchParams.get('fit') || 'min')

  if (url.searchParams.has('h') && url.searchParams.has('w')) {
    const originalHeight = parseInt(url.searchParams.get('h')!, 10)
    const originalWidth = parseInt(url.searchParams.get('w')!, 10)

    url.searchParams.set('h', Math.round((originalHeight / originalWidth) * width).toString())
  }

  url.searchParams.set('w', width.toString())

  if (quality) {
    url.searchParams.set('q', quality.toString())
  }

  return url.href
}

export { groq, SanityAsset, Transaction, IdentifiedSanityDocumentStub, QueryParams }
