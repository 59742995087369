'use client'

import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import * as LabelPrimitive from '@radix-ui/react-label'
import { cn } from '@ui/lib/utils'
import { Label } from './label'

const TinyCheck: React.FunctionComponent = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={8} height={6} fill='none' {...props}>
      <path stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M7 1 2.385 5 1 3.8' />
    </svg>
  )
}

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>>(
  ({ className, ...props }, ref) => (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        'group peer relative grid size-5 shrink-0 place-items-center rounded-sm border-[1.5px] border-primary ring-offset-transparent after:absolute after:left-1/2 after:top-1/2 after:size-3.5 after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-[2px] hover:after:bg-brand-grey-dark focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:border-brand-muted disabled:text-brand-muted disabled:after:hidden data-[state=checked]:hover:after:hidden',
        className,
      )}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn(
          'flex size-3.5 items-center justify-center rounded-[2px] text-current group-data-[state=checked]:bg-primary group-data-[state=checked]:text-primary-foreground group-data-[state=checked]:group-disabled:bg-brand-muted',
        )}
      >
        <TinyCheck />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  ),
)
Checkbox.displayName = CheckboxPrimitive.Root.displayName

const CheckboxLabel = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>>(({ className, ...props }, ref) => {
  return <Label className={cn('cursor-pointer text-body-lg md:text-body-md', className)} {...props} ref={ref} />
})
CheckboxLabel.displayName = LabelPrimitive.Root.displayName

export { Checkbox, CheckboxLabel }
