import { useQuery } from '@tanstack/react-query'
import { getProductsAvailability } from 'data-access'
import { useRouter } from 'next/router'

const useProductsAvailability = (productIds: string[] | null) => {
  const { locale } = useRouter()

  const queryKey = ['productsAvailability', locale, productIds]

  const { data } = useQuery({
    queryKey,
    staleTime: 5 * 60 * 1000, // 5 minutes
    queryFn: () => getProductsAvailability(locale, productIds!),
    enabled: !!locale && !!productIds,
    select: (data) => (data || []).filter(Boolean)?.[0],
  })

  return data
}

export default useProductsAvailability
