import { useCallback } from 'react'
import { useRouter } from 'next/router'

export const useRouterRefresh = () => {
  const router = useRouter()

  return useCallback(() => router.replace(router.asPath), [router])
}

export default useRouterRefresh
