import { Button } from '@ui/components/ui/button'
import { LoaderCircle } from 'lucide-react'
import { FunctionComponent } from 'react'
import { useSearchParam } from 'react-use'
import useResetPassword from 'src/hooks/customer/useResetPassword'
import { Form, FormControl, FormField, FormItem, FormMessage, useForm } from '@ui/components/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { InputFloatingLabel } from '@ui/components/ui/input-floating-label'
import MisterAuthPageContainer from 'src/components/shared/account/MisterAuthPageContainer'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { SectionResetPasswordData } from 'data-access/sanity/fragments/sections/sectionResetPassword.fragment'

interface SectionResetPasswordProps {
  data: SectionResetPasswordData
}

const SectionResetPassword: FunctionComponent<SectionResetPasswordProps> = ({ data }) => {
  const {
    title,
    subtitle,
    backgroundImage,
    passwordLabel,
    passwordErrorMessage,
    confirmPasswordLabel,
    passwordMatchErrorMessage,
    submitButtonText,
    submitSuccessTitle,
    submitSuccessSubtitle,
    returnToLoginLink,
  } = data

  const [resetPassword, resetState] = useResetPassword()
  const id = useSearchParam('id')
  const modifiedId = `gid://shopify/Customer/${id}`
  const token = useSearchParam('token')

  const resetPasswordFormSchema = z
    .object({
      password: z.string().min(6, { message: passwordErrorMessage }),
      confirmPassword: z.string().min(6, { message: passwordErrorMessage }),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: passwordMatchErrorMessage,
      path: ['confirmPassword'], // Specify the field path for the error message
    })

  const form = useForm<z.infer<typeof resetPasswordFormSchema>>({
    mode: 'onBlur',
    resolver: zodResolver(resetPasswordFormSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  const onSubmit = async (values: z.infer<typeof resetPasswordFormSchema>) => {
    const { password, confirmPassword } = values
    await resetPassword(password, confirmPassword, modifiedId, token)
  }

  return (
    <MisterAuthPageContainer image={backgroundImage}>
      <div className='flex flex-col gap-9'>
        {!resetState.response ? (
          <>
            <div className='flex flex-col gap-2'>
              <h2 className='text-heading-3'>{title}</h2>
              {subtitle && <p className='text-body-md'>{subtitle}</p>}
            </div>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className='flex flex-col gap-9'>
                <div className='flex flex-col gap-3'>
                  <FormField
                    control={form.control}
                    name='password'
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <InputFloatingLabel label={passwordLabel} type='password' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='confirmPassword'
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <InputFloatingLabel label={confirmPasswordLabel} type='password' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className='flex flex-col gap-2'>
                  <Button type='submit' variant='primary'>
                    {resetState.loading ? <LoaderCircle className='size-4 animate-spin' /> : submitButtonText}
                  </Button>
                  {resetState.error && <FormMessage className='text-center'>{resetState.error}</FormMessage>}
                </div>
              </form>
            </Form>
          </>
        ) : (
          <div className='flex flex-col gap-9'>
            <div className='flex flex-col gap-2'>
              <h2 className='text-heading-3'>{submitSuccessTitle}</h2>
              {submitSuccessSubtitle && <p className='text-body-md'>{submitSuccessSubtitle}</p>}
            </div>
            <MisterSiteLink link={returnToLoginLink}>
              <Button variant='outline' className='w-full'>
                {returnToLoginLink.linkText}
              </Button>
            </MisterSiteLink>
          </div>
        )}
      </div>
    </MisterAuthPageContainer>
  )
}

export default SectionResetPassword
