import { ChangeEvent, FunctionComponent } from 'react'
import MisterIcon from '../MisterIcon'
import { cn } from 'ui/lib/utils'

interface Props {
  id: string
  label?: string
  name: string
  wrapperClassName?: string
  value: string
  onChangeHandler?: (e: ChangeEvent<HTMLSelectElement>) => void
  options?: Option[]
  primaryOptions?: Option[]
  inForm?: boolean
  placeholderText?: string
}

interface Option {
  label: string
  value?: string
}

const MisterDropdown: FunctionComponent<Props> = ({ id, label, name, wrapperClassName, value, onChangeHandler, options, primaryOptions, inForm = false, placeholderText }) => {
  if (!options) return null
  return (
    <div className={cn('relative mb-5 flex flex-col', wrapperClassName)}>
      {label && (
        <label htmlFor={id} className='pb-1 text-body-lg empty:hidden'>
          {label}
        </label>
      )}
      <div className='relative'>
        <select
          value={value}
          name={name}
          id={id}
          className={cn(
            'w-full appearance-none rounded-none p-4 hover:cursor-pointer',
            inForm ? 'border-2 border-transparent bg-brand-grey text-body-lg' : 'border text-body-lg-bold',
          )}
          onChange={(e) => (onChangeHandler ? onChangeHandler(e) : null)}
        >
          {placeholderText && (
            <option value='' disabled hidden>
              {placeholderText}
            </option>
          )}
          {primaryOptions && (
            <>
              {primaryOptions.map((option, i) => (
                <option key={'primary-' + i} value={option.value || option.label}>
                  {option.label}
                </option>
              ))}
              <option disabled>---</option>
            </>
          )}
          {options.map((option, i) => (
            <option key={i} value={option.value || option.label}>
              {option.label}
            </option>
          ))}
        </select>
        <MisterIcon type='dropdown-arrow' className='pointer-events-none absolute right-3 top-1/2 ml-2 size-4 -translate-y-[50%] font-extrabold' />
      </div>
    </div>
  )
}

export default MisterDropdown
