import { SectionBulletPointsData } from 'data-access/sanity/fragments/sections/sectionBulletPoints.fragment'
import { FunctionComponent } from 'react'
import MisterLucideIcon from 'src/components/shared/MisterLucideIcon'
import RichText from 'src/components/shared/rich-text/rich-text'

interface SectionBulletPointsProps {
  data: SectionBulletPointsData
}

const SectionBulletPoints: FunctionComponent<SectionBulletPointsProps> = ({ data }) => {
  const { title, bulletPoints } = data
  return (
    <div className='mb-16'>
      {title && <h2 className='mb-6 text-heading-5 font-bold'>{title}</h2>}
      <ul className='flex flex-col gap-4'>
        {bulletPoints?.map((bulletPoint, i) => {
          const { icon, title, text } = bulletPoint
          return (
            <li key={i} className='grid grid-cols-[1rem_1fr] items-center gap-2'>
              {icon && <MisterLucideIcon size={16} name={icon} />}
              {title && <h3 className='col-start-2 font-semibold'>{title}</h3>}
              {text && <RichText data={text} className='col-start-2' />}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default SectionBulletPoints
