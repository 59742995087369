import { FunctionComponent } from 'react'
import RichText from 'src/components/shared/rich-text/rich-text'
import MisterContactMethods from 'src/components/shared/support/MisterContactMethods'
import { useTranslations } from 'src/contexts/Globals.context'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import MisterHalfAndHalf from 'src/components/shared/MisterHalfAndHalf'
import MisterContainer from 'src/components/shared/MisterContainer'
import MisterLucideIcon from 'src/components/shared/MisterLucideIcon'
import { SectionSupportOverviewData, SupportPage } from 'data-access/sanity/fragments/sections/sectionSupportOverview.fragment'

export interface SectionSupportOverviewProps {
  data: SectionSupportOverviewData
}

const SupportPages: FunctionComponent<{ pages?: SupportPage[] }> = ({ pages }) => {
  const translate = useTranslations()
  if (!pages?.length) {
    return null
  }

  const SupportPage = ({ icon, title, link }: SupportPage, index: number) => {
    return (
      <MisterSiteLink key={index} className='group flex gap-3 border px-5 py-6 align-middle transition-all duration-200 md:max-w-96 md:hover:bg-brand-blue-grey' link={link}>
        <MisterLucideIcon name={icon} strokeWidth={1.5} className='transition-all duration-200 md:group-hover:stroke-white' size={20} />
        <div className='content-center text-body-md-bold transition-all duration-200 md:group-hover:text-white'>{title}</div>
      </MisterSiteLink>
    )
  }

  return (
    <div className='flex flex-col gap-6'>
      <span className='text-body-xl-bold'>{translate('allTopics', 'All topics')}</span>
      <div className='flex flex-col gap-3'>{pages?.map((page, index) => SupportPage(page, index))}</div>
    </div>
  )
}

const SectionSupportOverview: FunctionComponent<SectionSupportOverviewProps> = ({ data }) => {
  const leftSection = (
    <div className='mb-16 flex flex-col'>
      <h2 className='mb-4 text-heading-5 font-bold md:text-heading-3'>{data?.title}</h2>
      {data?.description && <RichText className='text-body-sm md:text-body-lg' data={data?.description} />}
      <MisterContactMethods
        className='mt-8 hidden md:flex'
        openingHoursHeader={data.customerServiceData.openingHoursHeader}
        openingHours={data.customerServiceData?.openingHours}
        specialOpeningHours={data.customerServiceData?.specialOpeningHours}
        email={data.customerServiceData?.email}
        phone={data.customerServiceData?.phone}
        whatsApp={data.customerServiceData?.whatsApp}
        messenger={data.customerServiceData?.messenger}
      />
    </div>
  )

  const rightSection = <SupportPages pages={data?.pages} />
  return (
    <MisterContainer className='pb-16 pt-12 md:pt-24'>
      <MisterHalfAndHalf leftHalf={leftSection} rightHalf={rightSection} />
    </MisterContainer>
  )
}

export default SectionSupportOverview
