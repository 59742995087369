import { createContext, useContext } from 'react'
import { ContextCustomer } from '@/types/contextCustomer'
import { AccountData } from 'data-access/sanity/fragments/globals/account.fragment'

export const AccountContext = createContext<{ customer: ContextCustomer | null; accountData: AccountData | null }>({ customer: null, accountData: null })

export const useAccountContext = () => {
  const { customer, accountData } = useContext(AccountContext)
  if (!customer || !accountData) throw new Error('useAccountContext must be used within AccountContextProvider')
  return { customer, accountData }
}
