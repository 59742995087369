import { useSessionStorage } from 'react-use'

export const useCollectionPageHistory = () => {
  const [lastVisitedPcp, setLastVisitedPcp] = useSessionStorage<string>('lastVisitedPcp')

  return {
    lastVisitedPcp,
    setLastVisitedPcp,
  }
}
