import { FunctionComponent, ReactNode } from 'react'
import { ChevronRight } from 'lucide-react'
import MenuCard from '../MenuCard'
import { Button } from '@ui/components/ui/button'
import { countiesAndRegions } from 'src/utils/i18n.util'
import { useTranslations } from 'src/contexts/Globals.context'
import { useRouter } from 'next/router'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { useMobileMenu } from 'src/contexts/MobileMenu.context'
import StylesMenu from './StylesMenu'
import CategoryMenu from './CategoryMenu'
import SecondaryMenu from './SecondaryMenu'
import { CountryPicker } from './CountryPicker'

export const Flag: FunctionComponent<{ code?: string }> = ({ code }): ReactNode => {
  const correctedCode = code === 'en' ? 'eu' : code
  const country = correctedCode ? countiesAndRegions.find((c) => c.isoAlpha2 === correctedCode.toUpperCase()) : false

  return (
    <span className='flex w-[15px] items-center justify-center empty:hidden' role='img' aria-label={correctedCode} aria-hidden={true}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      {country && <img src={`data:image/png;base64,${country?.flag}`} alt={correctedCode || ''} width={30} height={20} />}
    </span>
  )
}

const TopLevelMobileMenu: FunctionComponent = () => {
  const { handleMobileMenuClose, handleMenuChange, setCurrentMainMenuItem, setCurrentCategory, setCurrentSecondaryMenuItem, data } = useMobileMenu()
  const translate = useTranslations()
  const { locale } = useRouter()

  const { mainMenuItems, secondaryMenuItems, topMenuCards } = data || {}

  return (
    <div className='flex h-full flex-col gap-7 px-4'>
      <div className='flex h-full flex-col gap-10 px-0'>
        <ul className='flex flex-col'>
          {(mainMenuItems ?? []).map((mainMenuItem, i) =>
            mainMenuItem._type === 'link' ? (
              <li key={i}>
                <Button onClick={handleMobileMenuClose} variant='ghost' className='flex px-2 py-3 text-btn-xl'>
                  <MisterSiteLink link={mainMenuItem}>{mainMenuItem.linkText}</MisterSiteLink>
                </Button>
              </li>
            ) : (
              mainMenuItem._type === 'mainMenuItem' && (
                <li key={i}>
                  <Button
                    variant='ghost'
                    className='flex items-center gap-1 px-2 py-3 text-btn-xl'
                    data-testid='mobile-menu-link'
                    onClick={() => {
                      ;(mainMenuItem.productCategories ?? []).length === 1
                        ? (handleMenuChange({ type: 'styles', component: <StylesMenu /> }, 'forward'), setCurrentMainMenuItem(i), setCurrentCategory(0))
                        : (handleMenuChange({ type: 'categories', component: <CategoryMenu /> }, 'forward'), setCurrentMainMenuItem(i))
                    }}
                  >
                    {mainMenuItem.mainMenuItemLink.linkText}
                    <ChevronRight className='size-5 stroke-[1.5px] text-brand-grey-dark' />
                  </Button>
                </li>
              )
            ),
          )}
        </ul>
        <ul className='mt-auto flex flex-col text-btn-lg'>
          {(secondaryMenuItems ?? []).map((secondaryMenuItem, i) => {
            return secondaryMenuItem?._type === 'link' ? (
              <li key={i}>
                <Button onClick={handleMobileMenuClose} variant='ghost' className='flex items-center gap-1 px-2 py-3'>
                  <MisterSiteLink link={secondaryMenuItem}>{secondaryMenuItem.linkText}</MisterSiteLink>
                </Button>
              </li>
            ) : (
              secondaryMenuItem?._type === 'secondaryMenuItem' && (
                <li key={i}>
                  <Button
                    variant='ghost'
                    className='flex items-center gap-1 px-2 py-3'
                    onClick={() => {
                      handleMenuChange({ type: 'secondary', component: <SecondaryMenu /> }, 'forward'), setCurrentSecondaryMenuItem(i)
                    }}
                  >
                    {secondaryMenuItem.secondaryMenuItemTitle}
                    <ChevronRight className='size-5 stroke-[1.5px] text-brand-grey-dark' />
                  </Button>
                </li>
              )
            )
          })}
          <li>
            <Button variant='ghost' className='flex items-center gap-1 px-2 py-3'>
              <MisterSiteLink
                onClick={handleMobileMenuClose}
                e2eSelector='account-link'
                link={{ type: 'internal', page: { slug: { current: 'account' } } }}
                title={translate('account', 'Account')}
              >
                {translate('account', 'Account')}
              </MisterSiteLink>
            </Button>
          </li>
          <li>
            <Button
              variant='ghost'
              className='flex items-center gap-1 px-2 py-3'
              onClick={() => {
                handleMenuChange({ type: 'countryPicker', component: <CountryPicker /> }, 'forward')
              }}
            >
              <Flag code={locale} />
              {translate(`${locale}CountryPicker`)}
              <ChevronRight className='size-5 stroke-[1.5px] text-brand-grey-dark' />
            </Button>
          </li>
        </ul>
      </div>
      <div className='mt-auto flex gap-2 pb-8'>
        {topMenuCards?.map(({ image, pageLink }, i) => <MenuCard key={i} image={image} link={pageLink} text={pageLink.linkText} onClick={handleMobileMenuClose} />)}
      </div>
    </div>
  )
}

export default TopLevelMobileMenu
