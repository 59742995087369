import { FunctionComponent } from 'react'
import MisterSiteLink from '../site-link/MisterSiteLink'
import { AlgoliaArticleHit } from 'src/utils/algolia.util'
import { Highlight } from 'react-instantsearch'
import MisterMedia from '../media/MisterMedia'

type Props = {
  item: AlgoliaArticleHit
  onClick?: () => void
}

export const MisterArticleResult: FunctionComponent<Props> = ({ item, onClick }) => {
  return (
    <li
      data-insights-object-id={item.objectID ?? undefined} // Algolia data attr
      data-insights-position={item.__position ?? undefined} // Algolia data attr
      data-insights-query-id={item.__queryID ?? undefined} // Algolia data attr
    >
      <MisterSiteLink onClick={onClick} title={item.title} href={item.url} className='flex flex-col gap-2'>
        {item.images[0] && <MisterMedia media={item.images[0]} className='rounded-md' />}
        <div className='flex h-full flex-col gap-1'>
          <h3 className='text-body-lg-bold'>{item.title}</h3>

          <p className='line-clamp-2 text-body-md'>
            {/* @ts-ignore - Somehow Algolia types are not correct here */}
            <Highlight attribute='content' hit={item} />
          </p>
        </div>
      </MisterSiteLink>
    </li>
  )
}
