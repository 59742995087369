import { ShopifyOrder } from '@/types/shopify'
import { cn } from 'ui/lib/utils'
import { useRouter } from 'next/router'
import { FunctionComponent } from 'react'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { useShopify } from 'src/contexts/Shopify.context'
import { formatDateInternational } from '../../../utils/date.util'
import { formatPrice } from '../../../utils/price.util'
import MisterOrderLineItem from './MisterOrderLineItem'
import { rmaPortalUrl } from '../../../utils/url.util'
import { Check, Download } from 'lucide-react'
import { Button } from '@ui/components/ui/button'
import { AccountData } from 'data-access/sanity/fragments/globals/account.fragment'

interface MisterOrderSummaryProps {
  order: ShopifyOrder
  email: string
  accountData: AccountData
}

const MisterOrderSummary: FunctionComponent<MisterOrderSummaryProps> = ({ order, email, accountData }) => {
  const {
    shippingAddress,
    billingAddress: orderBillingAddress,
    id,
    processedAt,
    successfulFulfillments,
    orderNumber,
    name,
    lineItems,
    subtotalPrice,
    totalTax,
    totalShippingPrice,
    totalPrice,
  } = order
  const { store } = useShopify()
  const { locale } = useRouter()

  const {
    shippingAddress: shippingAddressString,
    billingAddress,
    orderTotal,
    tax,
    shipping,
    subtotal,
    shipped,
    processing,
    orderPlaced,
    trackShipment,
    order: orderText,
    date: dateText,
    requestReturn,
    invoice,
  } = accountData || {}

  const today = new Date()
  const thresholdDate = new Date(today.getTime() - 100 * 24 * 60 * 60 * 1000)
  const parsedZip = shippingAddress?.zip.replace(' ', '%20').toUpperCase()

  const orderId = id.match(/\/(\d+)\?/)?.[1]
  const invoicePdfUrl = `https://${store?.storeDomain}/apps/sufio/invoice/download/?id=${orderId}&number=${orderNumber}`

  const orderDate = formatDateInternational(processedAt || new Date(), 'en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

  const originalOrderDate = new Date(processedAt)
  const isOlderThan100Days = originalOrderDate.getTime() <= thresholdDate.getTime()
  const orderCompleted = !!successfulFulfillments?.[0]?.trackingInfo?.[0]?.url
  const orderProcessing = !orderCompleted && successfulFulfillments?.[0]?.trackingInfo

  const shippingAddressOrBillingAddress = shippingAddress || orderBillingAddress

  return (
    <li key={orderNumber} className='mb-4 flex border-2 border-brand-grey last:pb-0 lg:mb-8'>
      <div className='flex w-full flex-col justify-between'>
        <div className='flex justify-between p-4 sm:p-8'>
          <div className='flex flex-row flex-wrap gap-2'>
            <div className='mr-4 flex flex-col sm:mr-8'>
              <span className='text-body-sm-bold'>{orderText}</span>
              <span className='text-body-md'>{name}</span>
            </div>
            <div className='mr-4 flex flex-col sm:mr-8'>
              <span className='text-body-sm-bold'>{dateText}</span>
              <span className='text-body-md'>{orderDate}</span>
            </div>
          </div>
          <div className='flex flex-wrap items-start justify-end gap-4'>
            {store?.storeDomain && orderId && orderNumber && (
              <Button size='sm' variant='outline' asChild className='flex gap-2'>
                <MisterSiteLink link={{ newTab: true, type: 'external', externalLink: invoicePdfUrl }}>
                  <Download className='inline size-4' />
                  {invoice}
                </MisterSiteLink>
              </Button>
            )}
            {!isOlderThan100Days && (
              <Button size='sm' asChild>
                <MisterSiteLink
                  link={{
                    newTab: true,
                    type: 'external',
                    externalLink: rmaPortalUrl(name, parsedZip || email),
                  }}
                >
                  {requestReturn}
                </MisterSiteLink>
              </Button>
            )}
          </div>
        </div>
        <div className='border-t border-brand-grey pt-4 sm:pt-8'>
          <div className='flex px-4 text-body-md sm:px-8'>
            {successfulFulfillments?.[0]?.trackingInfo?.[0]?.url ? (
              <MisterSiteLink
                className='pb-4 underline'
                link={{
                  newTab: true,
                  type: 'external',
                  externalLink: successfulFulfillments?.[0]?.trackingInfo?.[0]?.url,
                }}
              >
                {trackShipment}
              </MisterSiteLink>
            ) : null}
          </div>
          <div className='relative pb-4'>
            <div className='absolute inset-0 mx-4 h-2 rounded-full bg-brand-grey sm:mx-8' />
            <div
              className={cn(
                'absolute inset-0 mx-4 h-2 rounded-full bg-brand-positive sm:mx-8',
                orderProcessing && 'w-[50%] sm:w-[48%]',
                !orderCompleted && !orderProcessing && 'w-[8%] sm:w-[5%]',
              )}
            />
          </div>
          <div className='flex justify-between px-4 pb-2 text-body-md sm:px-8'>
            <span className='flex items-center gap-1'>
              <Check className='inline size-4' /> {orderPlaced}
            </span>
            <span className='flex items-center gap-1'>
              {(orderProcessing || orderCompleted) && <Check className='inline size-4' />} {processing}
            </span>
            <span className='flex items-center gap-1'>
              {orderCompleted && <Check className='inline size-4' />} {shipped}
            </span>
          </div>
          <ul className={cn('border-b border-t border-brand-grey md:grid md:grid-cols-2', lineItems?.edges.length % 2 !== 0 && '[&>*:nth-last-child(1)]:md:border-b-0')}>
            {lineItems.edges.map(({ node: lineItem }, index) => {
              return <MisterOrderLineItem key={index} lineItem={lineItem} index={index} accountData={accountData} />
            })}
          </ul>
          <div className='contents p-4 sm:flex sm:flex-row-reverse sm:justify-between sm:p-4'>
            <div className='flex w-full flex-col justify-end border-t border-brand-grey p-4 text-body-md sm:w-1/3 sm:border-t-0'>
              <div className='flex justify-between pt-4 sm:pt-0'>
                <p>{subtotal}</p>
                <p>{formatPrice(subtotalPrice?.amount ? subtotalPrice?.amount : 0, locale)}</p>
              </div>
              <div className='flex justify-between'>
                <p>{shipping}</p>
                <p className='ml-16'>{formatPrice(totalShippingPrice?.amount, locale)}</p>
              </div>
              {/* <div className='flex justify-between'>
                <p>Discount</p>
                <p className='ml-16'>{formatPrice(order?.subtotalPrice?.amount, locale)}</p>
              </div> */}
              <div className='flex justify-between'>
                <p>{tax}</p>
                <p className='ml-16'>{formatPrice(totalTax?.amount ? totalTax?.amount : 0, locale)}</p>
              </div>
              <div className='flex justify-between'>
                <p className='text-body-md-bold'>{orderTotal}</p>
                <p className='ml-16'>{formatPrice(totalPrice?.amount, locale)}</p>
              </div>
            </div>
            <div className='flex w-full justify-between p-4 sm:w-2/3 sm:justify-start'>
              <div className='mr-16 flex flex-col text-body-md' data-analytics-mask={true}>
                <p className='text-body-md-bold'>{billingAddress}</p>
                <p>{`${orderBillingAddress.firstName} ${orderBillingAddress.lastName}`}</p>
                <p>{orderBillingAddress.address1}</p>
                <p>{orderBillingAddress.zip}</p>
                <p>{orderBillingAddress.city}</p>
                <p>{orderBillingAddress.country}</p>
              </div>
              <div className='flex flex-col text-right text-body-md sm:text-start' data-analytics-mask={true}>
                <p className='text-body-md-bold'>{shippingAddressString}</p>
                <p>{`${shippingAddressOrBillingAddress.firstName} ${shippingAddressOrBillingAddress.lastName}`}</p>
                <p>{shippingAddressOrBillingAddress.address1}</p>
                <p>{shippingAddressOrBillingAddress.zip}</p>
                <p>{shippingAddressOrBillingAddress.city}</p>
                <p>{shippingAddressOrBillingAddress.country}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

export default MisterOrderSummary
