import { FunctionComponent } from 'react'
import MisterSiteLink from './site-link/MisterSiteLink'
import MisterMedia from './media/MisterMedia'
import RichText from './rich-text/rich-text'
import { PageStory } from 'data-access/sanity/fragments/sections/sectionStories.fragment'

interface MisterStoryCardProps {
  story: PageStory
}

const MisterStoryCard: FunctionComponent<MisterStoryCardProps> = ({ story }) => {
  return (
    <li className='first:ml-4 last:mr-4'>
      <article className='flex h-full w-auto min-w-[375px] select-none snap-center snap-always flex-col shadow'>
        <MisterSiteLink link={story.link} title={story.title} className='group/item'>
          <div className='overflow-hidden bg-brand-grey'>
            <MisterMedia
              priority={false}
              className='h-64 object-cover transition-transform duration-75 ease-in-out will-change-transform group-hover/item:scale-105'
              media={story?.thumbnail}
              desktopSizes='(max-width: 1024px) 75vw, (max-width: 1536px) 30vw, 28rem'
              mobileSizes='(max-width: 1024px) 75vw, (max-width: 1536px) 30vw, 28rem'
            />
          </div>
          <div className='flex flex-col justify-between gap-5 px-6 pb-6 pt-5'>
            <p className='text-body-md'>{story.preTitle}</p>
            <h3 className='text-heading-5'>{story.title}</h3>
            {story.excerpt && (
              <div className='not-prose'>
                <RichText data={story.excerpt} />
              </div>
            )}
          </div>
        </MisterSiteLink>
      </article>
    </li>
  )
}

export default MisterStoryCard
