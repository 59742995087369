import { Button } from '@ui/components/ui/button'
import { Check } from 'lucide-react'
import { useRouter } from 'next/router'
import { FunctionComponent, ReactNode } from 'react'
import { useCookie } from 'react-use'
import { useTranslations } from 'src/contexts/Globals.context'
import { useMobileMenu } from 'src/contexts/MobileMenu.context'
import { I18N_COUNTRY_CODE_PREFERENCE } from 'src/middleware'
import { defaultCookieConfig } from 'src/utils/cookie.util'
import { countiesAndRegions } from 'src/utils/i18n.util'

const Flag: FunctionComponent<{ code?: string }> = ({ code }): ReactNode => {
  const correctedCode = code === 'en' ? 'eu' : code
  const country = correctedCode ? countiesAndRegions.find((c) => c.isoAlpha2 === correctedCode.toUpperCase()) : false

  return (
    <span className='flex w-5 items-center justify-center empty:hidden' role='img' aria-label={correctedCode} aria-hidden={true}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      {country && <img src={`data:image/png;base64,${country?.flag}`} alt={correctedCode || ''} width={30} height={20} />}
    </span>
  )
}

export const CountryPicker: FunctionComponent = () => {
  const translate = useTranslations()
  const { handleMobileMenuClose } = useMobileMenu()
  const [value, updateCookie] = useCookie(I18N_COUNTRY_CODE_PREFERENCE)
  const [, setCPCookie] = useCookie('usedCountryPicker')
  const { locale, locales, reload } = useRouter()

  const localesForDropdown = (locales || []).filter((country) =>
    // Only add values to dropdown with a label/translation
    translate(`${country}CountryPicker`),
  )

  const handleClick = async (country: string) => {
    if (value !== country) {
      setCPCookie('true', defaultCookieConfig)
      updateCookie(country, defaultCookieConfig)
    }
    reload()
  }

  return (
    <div className='flex h-full flex-col px-4 pt-10'>
      <ul className='flex flex-col'>
        {localesForDropdown.map((localeLoop, i) => {
          const isCurrent = localeLoop === locale
          return (
            <Button
              key={i}
              onClick={() => (isCurrent ? null : handleClick(localeLoop), handleMobileMenuClose)}
              data-label={localeLoop}
              variant='ghost'
              className='justify-between gap-2 py-3 pl-4 pr-0 text-btn-xl'
            >
              <div className='flex gap-4'>
                <Flag code={localeLoop} />
                {translate(`${localeLoop}CountryPicker`)}
              </div>
              {isCurrent && (
                <div className='flex size-6 items-center justify-center rounded-full bg-brand-blue'>
                  <Check className='size-4 text-white' />
                </div>
              )}
            </Button>
          )
        })}
      </ul>
    </div>
  )
}
