import { FunctionComponent, useState } from 'react'
import { useLocalStorage } from 'react-use'
import MisterMedia from '../../../shared/media/MisterMedia'
import { useRouter } from 'next/router'
import RichText from '../../../shared/rich-text/rich-text'
import { useTranslations } from 'src/contexts/Globals.context'
import SizeChart from '../size-chart/size-chart'
import { cn } from 'ui/lib/utils'
import { SizeGuideModal } from 'data-access/sanity/fragments/components/sizeCharts.fragment'

interface Props {
  data: SizeGuideModal
  className?: string
  showCategoryNavigation?: boolean
}

export interface CollectionListItem {
  title: string
  parentCollection?: string
  id: string
}

const SizeCharts: FunctionComponent<Props> = ({ data, className, showCategoryNavigation = true }) => {
  const { locale } = useRouter()
  const [sizeChartsUnit, setSizeChartsUnit] = useLocalStorage('sizeChartsUnit')
  const translate = useTranslations()

  const [unitIsInch, setUnitIsInch] = useState(() => {
    if (!(locale === 'gb' || locale === 'en')) {
      return false
    }

    if (sizeChartsUnit) {
      return sizeChartsUnit === 'inch'
    }

    return locale === 'gb'
  })

  const changeUnit = (isInch: boolean) => {
    setSizeChartsUnit(isInch ? 'inch' : 'cm')
    setUnitIsInch(isInch)
  }

  let toggleShown = false

  const showToggle = () => {
    if ((locale === 'gb' || locale === 'en') && !toggleShown) {
      toggleShown = true
      return true
    }
    return false
  }

  return (
    <div className={cn('pb-20 sm:pb-2', className)}>
      {showCategoryNavigation && (
        <div className='flex justify-between'>
          <div className='mb-2 flex w-full max-w-xl gap-4 sm:gap-10'>
            <MisterMedia
              className='size-24 sm:size-28'
              media={data?.collectionBulletImage}
              mobileSizes='(max-width: 768px) 6rem, 7rem'
              desktopSizes='(max-width: 768px) 6rem, 7rem'
            />
            <div className='flex w-full flex-row items-center justify-center gap-4 text-heading-5'>
              <h2 className='font-bold'>{translate('sizeChart', 'Size chart')}</h2>
              <div>|</div>
              <div>{data?.collectionTitle}</div>
            </div>
          </div>
        </div>
      )}
      {data?.sizeCharts?.map((sizeChart, i) => (
        <SizeChart
          collectionTitle={data.collectionTitle}
          key={i}
          data={sizeChart}
          unitIsInch={unitIsInch}
          onChangeUnit={changeUnit}
          showUnitToggle={!!sizeChart.tableDataInch && showToggle()}
        />
      ))}
      {data?.collectionSizing && (
        <div className='flex flex-col'>
          <span className='mb-4 self-center text-heading-5 font-bold'>{translate('sizeAndFit')}</span>
          <RichText className='text-start text-body-lg' data={data?.collectionSizing} />
        </div>
      )}
    </div>
  )
}

export default SizeCharts
