import { CSSProperties, FunctionComponent } from 'react'
import Sections from '../../sections/sections'
import MisterContainer from 'src/components/shared/MisterContainer'
import { cn } from '@ui/lib/utils'
import { SectionLayoutData } from 'data-access/sanity/fragments/sections/sectionLayout.fragment'
import { SectionLayoutContextProvider } from 'src/contexts/SectionLayout.context'

interface SectionLayoutProps {
  data: SectionLayoutData
  index: number
}

const SectionLayout: FunctionComponent<SectionLayoutProps> = ({ data, index }) => {
  if (!data?.sections) {
    return null
  }

  return (
    <SectionLayoutContextProvider data={data}>
      <MisterContainer backgroundColor={data.backgroundColor} padding={false} type='fluid' className='align-center grid items-baseline'>
        <div
          style={
            Object.assign(
              {},
              ...(data.columns
                ? data.columns.map(({ name, value }) => ({
                    [`--${name == 'base' ? '' : name + '-'}cols`]: `${value}`,
                  }))
                : []),
            ) as CSSProperties
          }
          className={cn(
            'grid w-full gap-2',
            data.columns?.map(({ name }) => {
              if (name === 'base') return 'grid-cols-[repeat(var(--cols),minmax(0,1fr))]'
              if (name === 'md') return 'md:grid-cols-[repeat(var(--md-cols),minmax(0,1fr))]'
              if (name === 'lg') return 'lg:grid-cols-[repeat(var(--lg-cols),minmax(0,1fr))]'
            }),
            !data.containerized ? '' : data.containerized === 'medium' ? 'mx-auto max-w-[90rem]' : 'max-w-3xl',
            data.padding && 'px-4 md:px-10',
          )}
        >
          {data.sections.map((section, i) => {
            const { rowSpan, colSpan, orderOverride } = section || {}

            return (
              <div
                key={i}
                style={
                  Object.assign(
                    {},
                    ...(rowSpan &&
                      rowSpan?.map(({ name, value }) => ({
                        [`--${name == 'base' ? '' : name + '-'}row-span`]: `${value}`,
                      }))),
                    ...(colSpan &&
                      colSpan?.map(({ name, value }) => ({
                        [`--${name == 'base' ? '' : name + '-'}col-span`]: `${value}`,
                      }))),
                    ...(orderOverride
                      ? orderOverride?.map(({ name, value }) => ({
                          [`--${name == 'base' ? '' : name + '-'}order`]: `${value}`,
                        }))
                      : []),
                  ) as CSSProperties
                }
                className={cn(
                  'relative flex flex-col items-center overflow-hidden rounded-md @container-[inline-size]',
                  colSpan?.map(({ name }) => {
                    if (name === 'base') return 'col-[span_var(--col-span)]'
                    if (name === 'md') return 'md:col-[span_var(--md-col-span)]'
                    if (name === 'lg') return 'lg:col-[span_var(--lg-col-span)]'
                  }),
                  rowSpan?.map(({ name }) => {
                    if (name === 'base') return 'row-[span_var(--row-span)/span_var(--row-span)]'
                    if (name === 'md') return 'md:row-[span_var(--md-row-span)/span_var(--md-row-span)]'
                    if (name === 'lg') return 'lg:row-[span_var(--lg-row-span)/span_var(--lg-row-span)]'
                  }),
                  orderOverride?.map(({ name }) => {
                    if (name === 'base') return 'order-[var(--order)]'
                    if (name === 'md') return 'md:order-[var(--md-order)]'
                    if (name === 'lg') return 'lg:order-[var(--lg-order)]'
                  }),
                  !orderOverride?.map(({ name }) => name).includes('md') && 'md:order-[unset]',
                  !orderOverride?.map(({ name }) => name).includes('lg') && 'lg:order-[unset]',
                )}
              >
                <Sections index={i + index + 1} section={section} />
              </div>
            )
          })}
        </div>
      </MisterContainer>
    </SectionLayoutContextProvider>
  )
}

export default SectionLayout
