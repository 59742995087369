import { FunctionComponent } from 'react'
import MisterMedia from '../../../shared/media/MisterMedia'
import RichText from 'src/components/shared/rich-text/rich-text'
import { RichText as RichTextType } from 'data-access/sanity/fragments/components/richText.fragment'
import { Media } from 'data-access/sanity/fragments/components/media.fragment'

export interface Props {
  data: Header
}

export interface Header {
  image?: Media
  headerText: {
    title: string
    description?: RichTextType
  }
}

const SizeChartsHeaderInfo: FunctionComponent<Props> = ({ data }) => {
  return (
    <div>
      {data?.headerText.description && <RichText data={data?.headerText.description} />}
      {data?.image?._id && <MisterMedia className='h-56 object-contain sm:h-full' media={data?.image} mobileSizes='17rem' desktopSizes='17rem' />}
    </div>
  )
}

export default SizeChartsHeaderInfo
