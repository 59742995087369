import { FunctionComponent, PropsWithoutRef } from 'react'

type IconComponent = FunctionComponent<PropsWithoutRef<any>>

const baseProps = {
  xmlns: 'http://www.w3.org/2000/svg',
  preserveAspectRatio: 'xMidYMid meet',
}

const OptionIcons: Record<string, IconComponent> = {
  Waist: (props) => (
    <svg {...props} viewBox='0 0 24 24' {...baseProps} xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.5 4.5H16.5' stroke='#071933' strokeLinejoin='round' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.5 2C7.22386 2 7 2.22386 7 2.5V4.98486L6.96878 5.5H7.97062L7.99909 5.03025C7.9997 5.02018 8 5.01009 8 5V3H16V5C16 5.01009 16.0003 5.02018 16.0009 5.03025L16.0294 5.5H17.0312L17 4.98486V2.5C17 2.22386 16.7761 2 16.5 2H7.5ZM17.2736 9.5H16.2718L16.9688 21H14.913L12.7015 9.5H11.2985L9.08699 21H7.03122L7.72819 9.5H6.72636L6.00092 21.4698C5.99258 21.6074 6.04142 21.7423 6.13591 21.8427C6.2304 21.9431 6.36214 22 6.5 22H9.5C9.73973 22 9.94573 21.8298 9.991 21.5944L12 11.1476L14.009 21.5944C14.0543 21.8298 14.2603 22 14.5 22H17.5C17.6379 22 17.7696 21.9431 17.8641 21.8427C17.9586 21.7423 18.0074 21.6074 17.9991 21.4698L17.2736 9.5Z'
        fill='#071933'
      />
      <path d='M4 7.5L20 7.5' stroke='#071933' strokeLinejoin='round' />
      <path d='M5 6.5L4 7.5L5 8.5' stroke='#071933' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M19 8.5L20 7.5L19 6.5' stroke='#071933' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  ),
  Length: (props) => (
    <svg {...props} viewBox='0 0 24 24' fill='none' {...baseProps} xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.5 4.5H16.5' stroke='#071933' strokeLinejoin='round' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 2.5C7 2.22386 7.22386 2 7.5 2H16.5C16.7761 2 17 2.22386 17 2.5V4.98486L17.9991 21.4698C18.0074 21.6074 17.9586 21.7423 17.8641 21.8427C17.7696 21.9431 17.6379 22 17.5 22H14.5C14.2603 22 14.0543 21.8298 14.009 21.5944L12 11.1476L9.991 21.5944C9.94573 21.8298 9.73973 22 9.5 22H6.5C6.36214 22 6.2304 21.9431 6.13591 21.8427C6.04142 21.7423 5.99258 21.6074 6.00092 21.4698L7 4.98486V2.5ZM8 3V5C8 5.01009 7.9997 5.02018 7.99909 5.03025L7.03122 21H9.08699L11.509 8.40558C11.5543 8.17016 11.7603 8 12 8C12.2397 8 12.4457 8.17016 12.491 8.40558L14.913 21H16.9688L16.0009 5.03025C16.0003 5.02018 16 5.01009 16 5V3H8Z'
        fill='#071933'
      />
      <path d='M20 1L20 23L16 23L16 1L20 1Z' fill='white' />
      <path d='M18.5 2L18.5 22' stroke='#071933' strokeLinejoin='round' />
      <path d='M19.5 3L18.5 2L17.5 3' stroke='#071933' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M17.5 21L18.5 22L19.5 21' stroke='#071933' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  ),
}

export default function OptionIcon(props: any) {
  const { option, ...restProps } = props
  const Icon = OptionIcons?.[option]

  return Icon ? <Icon {...restProps} /> : null
}
