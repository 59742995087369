import { cn } from 'ui/lib/utils'
import MisterIcon from '../MisterIcon'
import MisterSpinner from '../loader/MisterSpinner'
import { FunctionComponent, HTMLInputTypeAttribute, InputHTMLAttributes } from 'react'

interface Props {
  type: HTMLInputTypeAttribute
  id: string
  label: string
  name: string
  pattern?: InputHTMLAttributes<HTMLInputElement>['pattern']
  placeholder?: InputHTMLAttributes<HTMLInputElement>['placeholder']
  autoComplete?: InputHTMLAttributes<HTMLInputElement>['autoComplete']
  value?: InputHTMLAttributes<HTMLInputElement>['value']
  disabled?: InputHTMLAttributes<HTMLInputElement>['disabled']
  required?: InputHTMLAttributes<HTMLInputElement>['required']
  minLength?: InputHTMLAttributes<HTMLInputElement>['minLength']
  maxLength?: InputHTMLAttributes<HTMLInputElement>['maxLength']
  step?: InputHTMLAttributes<HTMLInputElement>['step']
  min?: InputHTMLAttributes<HTMLInputElement>['min']
  max?: InputHTMLAttributes<HTMLInputElement>['max']
  loading?: boolean
  inputClassName?: string
  labelClassName?: string
  wrapperClassName?: string
  hint?: string
  customSuccesMessage?: string
  customErrorMessage?: string
  onChangeHandler?: (data: Record<string, string>) => void
  showLabel?: boolean
  hasSubmitButton?: boolean
  submitButtonAriaLabel?: string
  e2eSelector?: string
  suppress1PasswordManager?: boolean
  maskForAnalytics?: boolean
  isValid?: boolean
  variant?: 'primary' | 'secondary'
  textAreaRows?: number
  validate?: boolean
}

const MisterInput: FunctionComponent<Props> = ({
  type,
  label,
  name,
  pattern,
  placeholder,
  id,
  wrapperClassName,
  hint,
  labelClassName,
  inputClassName,
  onChangeHandler,
  value,
  loading = false,
  hasSubmitButton = false,
  submitButtonAriaLabel,
  showLabel = true,
  customSuccesMessage,
  customErrorMessage,
  disabled,
  required = false,
  autoComplete,
  minLength,
  maxLength,
  e2eSelector,
  suppress1PasswordManager = false,
  maskForAnalytics = true,
  isValid = true,
  variant = 'primary',
  textAreaRows = 15,
  validate = true,
}) => {
  const variants = {
    primary: 'border-2 border-transparent focus:bg-gray-50',
    secondary: 'bg-transparent border border-brand-blue placeholder:opacity-80 placeholder:text-brand-blue',
  }

  return (
    <div className={cn('relative mb-5 flex flex-col', wrapperClassName)}>
      <label htmlFor={id} className={cn('pb-1 text-body-lg empty:hidden', !showLabel && 'sr-only', labelClassName)}>
        {label}
      </label>
      <div className='relative'>
        {type === 'textArea' ? (
          <textarea
            name={name}
            id={id}
            className={cn(
              'peer w-full resize-none py-4 pl-4 text-body-lg placeholder:pb-4 placeholder:text-body-lg',
              variant === 'primary' ? 'bg-brand-grey focus:bg-gray-50' : 'bg-gray-200 focus:bg-gray-300',
              inputClassName,
              validate && value && 'valid:border-2 invalid:border-2 invalid:border-brand-warning valid:focus:border-brand-positive',
            )}
            onChange={(e) => (onChangeHandler ? onChangeHandler({ [name]: e?.target?.value }) : null)}
            value={value}
            placeholder={placeholder}
            autoComplete={type === 'email' ? 'email' : autoComplete}
            required={required}
            minLength={minLength}
            maxLength={maxLength}
            rows={textAreaRows}
            data-testid={e2eSelector}
          />
        ) : (
          <input
            pattern={pattern}
            type={type}
            data-1p-ignore={suppress1PasswordManager ? 'true' : 'false'}
            required={required}
            autoComplete={type === 'email' ? 'email' : autoComplete}
            name={name}
            value={value}
            id={id}
            className={cn(
              'peer w-full rounded-none py-4 pl-4 text-body-lg outline-none placeholder:py-4 placeholder:text-body-lg disabled:cursor-not-allowed disabled:opacity-50',
              variant === 'primary' ? variants.primary : variants.secondary,
              inputClassName,
              validate && value && 'peer invalid:border-brand-warning valid:focus:border-brand-positive',
              disabled && '!border-transparent',
              validate && value && !isValid && '!border-brand-warning',
            )}
            minLength={minLength}
            maxLength={maxLength}
            onChange={(e) => (onChangeHandler ? onChangeHandler({ [name]: e.target.value }) : null)}
            placeholder={placeholder}
            disabled={disabled}
            data-analytics-mask={!maskForAnalytics ? undefined : true}
            data-testid={e2eSelector}
          />
        )}
        {hasSubmitButton ? (
          <button type='submit' className='absolute right-2 top-0 h-full bg-transparent' aria-label={submitButtonAriaLabel}>
            {loading ? (
              <div className={'w-12 p-3'}>
                <MisterSpinner />
              </div>
            ) : (
              <MisterIcon className='w-12 p-3' type='submit-arrow' />
            )}
          </button>
        ) : validate && !disabled ? (
          isValid ? (
            <>
              <div className={cn('invisible absolute right-5 top-1/2 translate-y-[-50%] peer-valid:peer-focus:visible')}>
                <MisterIcon type='check' className='size-5' />
              </div>
              <div className={cn('absolute right-5 top-1/2 translate-y-[-50%]', !value ? 'invisible' : 'peer-valid:invisible peer-invalid:visible')}>
                <MisterIcon type='warning' className='size-5' />
              </div>
            </>
          ) : (
            <div className={cn('absolute right-5 top-1/2 translate-y-[-50%]', !value && 'invisible')}>
              <MisterIcon type='warning' className='size-5' />
            </div>
          )
        ) : null}
        <span
          className={cn(
            validate && value && 'translate-y-full pt-1 peer-valid:invisible peer-invalid:visible peer-invalid:text-brand-warning',
            'invisible absolute bottom-0 left-0 h-auto text-body-sm',
          )}
        >
          {hint}
        </span>
        {validate && value && (
          <>
            <span className={cn(value && 'translate-y-full pt-1 text-brand-warning peer-invalid:invisible', 'absolute bottom-0 left-0 h-auto text-body-sm')}>
              {customErrorMessage}
            </span>
            <span className={cn(value && 'translate-y-full pt-1 peer-invalid:invisible', 'absolute bottom-0 left-0 h-auto text-body-sm')}>{customSuccesMessage}</span>
          </>
        )}
      </div>
    </div>
  )
}

export default MisterInput
