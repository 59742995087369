import { FunctionComponent, useState } from 'react'
import { TrackingInfo } from '../section-aftership'
import { useTranslations } from 'src/contexts/Globals.context'
import { useToggle } from 'react-use'
import { get } from 'src/utils/request.util'
import qs from 'qs'
import { Button } from '@ui/components/ui/button'
import MisterSpinner from 'src/components/shared/loader/MisterSpinner'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, useForm } from 'ui/components/ui/form'
import { Input } from '@ui/components/ui/input'
import { useRouter } from 'next/router'

export const TrackingForm: FunctionComponent<{
  setShipment: (shipment: TrackingInfo) => void
}> = ({ setShipment }) => {
  const translate = useTranslations()
  const { locale } = useRouter()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useToggle(false)

  const showError = () => {
    setError(true)
    setTimeout(() => {
      setError(false)
    }, 3000)
  }

  const trackingFormSchema = z.object({
    email: z.string().email({ message: translate('emailHint') }),
    orderId: z.string().min(1, { message: translate('orderNumber', 'Order number') }),
  })

  const form = useForm<z.output<typeof trackingFormSchema>>({
    resolver: zodResolver(trackingFormSchema),
    defaultValues: {
      email: '',
      orderId: '',
    },
  })

  const onSubmit = async (values: z.infer<typeof trackingFormSchema>) => {
    setLoading(true)

    const { email, orderId } = values

    try {
      const response: { trackingInfo: TrackingInfo } | undefined = await get(
        `/api/aftership/aftership?${qs.stringify({
          email: email,
          orderId: '#' + orderId.replace('#', ''),
          lang: locale || 'en',
        })}`,
      )
      if (response?.trackingInfo) {
        setShipment(response?.trackingInfo)
      } else {
        showError()
      }
    } catch (error) {
      showError()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='mx-auto max-w-96 space-y-7 bg-brand-beige-light pb-72 pt-16'>
        <FormField
          control={form.control}
          name='orderId'
          render={({ field }) => (
            <FormItem>
              <FormLabel>{translate('orderNumber', 'Order number')}</FormLabel>
              <FormControl>
                <Input type='text' className='bg-white' placeholder={'#1001000000'} {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='email'
          render={({ field }) => (
            <FormItem>
              <FormLabel>{translate('email', 'Email')}</FormLabel>
              <FormControl>
                <Input type='email' className='bg-white py-6 pr-14' placeholder={translate('email', 'Email')} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button className='w-full' title='submit'>
          <div className='h-6 content-center'>{loading ? <MisterSpinner size='small' /> : translate('track', 'Track')}</div>
        </Button>
        {!loading && error && <FormMessage>{translate('formErrorMessage')}</FormMessage>}
      </form>
    </Form>
  )
}
