import { cn } from '@ui/lib/utils'
import { FunctionComponent, PropsWithChildren } from 'react'

interface CollapseContainerProps {
  collapse: boolean
  className?: string
}

const CollapseContainer: FunctionComponent<PropsWithChildren<CollapseContainerProps>> = ({ children, collapse, className }) => {
  return (
    <div
      className={cn('grid grid-cols-1 overflow-hidden transition-[grid-template-rows,opacity] duration-300', collapse ? 'grid-rows-[0fr] opacity-0' : 'grid-rows-[1fr]', className)}
    >
      <div className='min-h-0'>{children}</div>
    </div>
  )
}

export default CollapseContainer
