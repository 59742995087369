import { FunctionComponent, useContext } from 'react'
import { Button } from '@ui/components/ui/button'
import MisterSiteLink, { makeHref } from 'src/components/shared/site-link/MisterSiteLink'
import { convertSlug } from 'src/utils/url.util'
import MisterImage from 'src/components/shared/image/MisterImage'
import { useMobileMenu } from 'src/contexts/MobileMenu.context'
import { GlobalsContext, useTranslations } from 'src/contexts/Globals.context'
import { sort } from 'src/utils/array.util'
import { Badge } from '@ui/components/ui/badge'
import { MainMenuItem } from 'data-access/sanity/fragments/components/mainMenuItem.fragment'
import { trackEvent, TELEMETRY_DATA_LAYER_EVENTS } from 'src/utils/telemetry.util'

const StylesMenu: FunctionComponent = () => {
  const { stylesOrder } = useContext(GlobalsContext)
  const { data, handleMobileMenuClose, currentMainMenuItem, currentCategory } = useMobileMenu()
  const mainMenuItem = data.mainMenuItems?.[currentMainMenuItem || 0] as MainMenuItem
  const { styles, productCategory, productCategoryImage, productCategoryMenuTitle } = mainMenuItem?.productCategories?.[currentCategory || 0] || {}
  const translation = useTranslations()

  return (
    <div className='flex h-full flex-col gap-10 px-4 pt-10'>
      <div className='flex flex-col gap-5'>
        <ul className='flex flex-col gap-5'>
          {styles && styles.length > 1 && (
            <li>
              <MisterSiteLink
                data-testid='mobile-menu-style-link'
                onClick={() => {
                  const href = makeHref({ _type: 'link', type: 'internal', page: { slug: productCategory?.slug, _type: 'collection' } })

                  trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                    clickDestination: href,
                  })
                  handleMobileMenuClose()
                }}
                className='mb-1.5 flex flex-col px-2'
                link={convertSlug(productCategory?.slug, 'collection')}
              >
                <span className='text-btn-xl'>{productCategory?.title}</span>
              </MisterSiteLink>
            </li>
          )}
          {styles &&
            styles.length &&
            (stylesOrder.applyOrderTo?.header ? sort(styles, stylesOrder.styles || []) : styles).map((style, i) => (
              <li key={i}>
                <MisterSiteLink
                  data-testid='mobile-menu-style-link'
                  onClick={handleMobileMenuClose}
                  className='flex flex-col gap-1 px-2'
                  link={convertSlug(style.slug, 'collection')}
                >
                  <div className='flex items-start gap-2'>
                    <span className='text-btn-xl'>{style.title}</span>
                    {style.new && (
                      <Badge size='sm' className='h-5 bg-brand-blue'>
                        {translation('new', 'New')}
                      </Badge>
                    )}
                  </div>
                  <span className='text-body-md text-brand-grey-dark'>{style.collectionDescription}</span>
                </MisterSiteLink>
              </li>
            ))}
        </ul>
      </div>
      <div className='mt-auto pb-8'>
        <div className='relative rounded-md bg-brand-beige-light'>
          <div className='absolute inset-0 flex flex-col justify-between p-6'>
            <h2 className='text-heading-3 text-brand-blue'>{productCategoryMenuTitle}</h2>
            <div>
              <MisterSiteLink onClick={handleMobileMenuClose} link={convertSlug(productCategory?.slug, 'collection')}>
                <Button variant='secondary'>Shop {productCategoryMenuTitle}</Button>
              </MisterSiteLink>
            </div>
          </div>
          <MisterImage mobile={productCategoryImage} className='max-h-[432px] rounded-md object-cover object-center' />
        </div>
      </div>
    </div>
  )
}

export default StylesMenu
