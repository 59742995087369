import { FunctionComponent, useEffect } from 'react'
import { TrackingInfo } from '../section-aftership'
import { useTranslations } from 'src/contexts/Globals.context'
import { Box, Check, LucideIcon, Truck } from 'lucide-react'
import { cn } from '@ui/lib/utils'
import { useToggle } from 'react-use'
import { useRouter } from 'next/router'

export const DeliveryStatus: FunctionComponent<{ shipment: TrackingInfo }> = ({ shipment }) => {
  const translate = useTranslations()
  const { locale } = useRouter()

  const [mounted, setMounted] = useToggle(false)

  const orderPlaced = ['InfoReceived', 'Pending'].includes(shipment.tag)
  const shipmentIsOnTheWay = ['InTransit', 'OutForDelivery', 'AttemptFail', 'AvailableForPickup', 'Exception'].includes(shipment.tag)
  const delivered = shipment.tag === 'Delivered'
  const deliveryTime = shipment.expected_delivery || shipment.aftership_estimated_delivery_date
  const deliveryLocation = shipment.pickup_location || shipment.origin_raw_location

  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, 500)
  }, [])

  const deliveryIcon = (Icon: LucideIcon, text: string, active: boolean = true, delay: string) => {
    return (
      <div
        className={cn(
          `relative flex size-9 items-center justify-center rounded-full border transition-all duration-1000`,
          active && mounted ? 'border-transparent bg-brand-blue' : 'border-brand-blue-light bg-brand-beige',
          delay,
        )}
      >
        <Icon className={cn('transition-all duration-1000', active && mounted ? 'stroke-white' : 'stroke-brand-blue-dark', delay)} strokeWidth={1.5} size={16} />
        <span className='absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-full text-nowrap pt-3'>{text}</span>
      </div>
    )
  }

  const Divider = () => {
    return <div className='h-0 grow border border-brand-blue-light' />
  }

  const lowerCaseShipmentTag = shipment.tag.charAt(0).toLowerCase() + shipment.tag.slice(1)

  return (
    <div>
      <div className='mb-7 text-center text-heading-5 font-bold md:mb-12 md:text-heading-3'>
        {deliveryTime && !delivered ? (
          <div>
            <div className='mb-2 text-body-sm-bold opacity-60 md:text-heading-5 md:font-bold'>{translate('estimatedDelivery', 'Estimated delivery')}</div>
            <div>
              {new Date(deliveryTime).toLocaleDateString(locale, {
                month: 'short',
                day: 'numeric',
                weekday: 'long',
              })}
            </div>
          </div>
        ) : (
          <div>{translate(lowerCaseShipmentTag, shipment.tag)}</div>
        )}
      </div>

      {shipmentIsOnTheWay && !delivered && (
        <div className='text-center text-body-md font-semibold md:text-heading-5 md:font-bold'>{translate('outForDelivery', 'Out for delivery')}</div>
      )}
      {deliveryLocation && <div className='mt-2 text-center text-body-md font-semibold opacity-60 md:text-body-lg'>{deliveryLocation}</div>}

      <div className='mt-4 flex items-center justify-between gap-4 px-6 pb-9 text-body-sm md:mt-5 md:text-body-lg'>
        {deliveryIcon(Check, translate('orderPlaced', 'Order placed'), orderPlaced || shipmentIsOnTheWay || delivered, 'delay-0')}
        <Divider />
        {deliveryIcon(Truck, translate('shipped', 'Shipped'), shipmentIsOnTheWay || delivered, 'delay-500')}
        <Divider />
        {deliveryIcon(Box, translate('delivered', 'Delivered'), delivered, 'delay-1000')}
      </div>
    </div>
  )
}
