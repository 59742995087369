import { Slug } from 'data-access/sanity/types'
import { cn } from '@ui/lib/utils'
import { FunctionComponent, useContext, useEffect } from 'react'
import { useConfigure, useInstantSearch } from 'react-instantsearch'
import { useAlgoliaSearch } from 'src/contexts/AlgoliaSearch.context'
import { Link } from 'data-access/sanity/fragments/components/link.fragment'
import { GlobalsContext, useTranslations } from '../../../contexts/Globals.context'
import { MisterSearchTerm } from './MisterSearchTerm'
import { useCookie } from 'react-use'
import { ELEVAR_USER_ID_COOKIE } from 'src/middleware'
import MisterCustomSearchBox from './MisterCustomSearchBox'
import MisterEmptyQueryBoundary from './MisterEmptyQueryBoundary'
import MisterCustomHits from './MisterCustomHits'
import MisterSpinner from '../loader/MisterSpinner'
import MisterTrendingItems from './MisterTrendingItems'
import { defaultCookieConfig } from 'src/utils/cookie.util'
import { useCookieConsentContext } from 'src/hooks/context/useCookieConsentContext'

export type MisterSearchBarProps = {
  searchAsYouType?: boolean
  className?: string
  searchResultsSlug?: { slug: Slug }
  quickLinks?: Link[] | undefined
}

const MisterSearchBar: FunctionComponent<MisterSearchBarProps> = ({ className }) => {
  const translate = useTranslations()
  const { status, results } = useInstantSearch()
  const { searchSettings } = useContext(GlobalsContext)
  const { hasConsentedToCookie } = useCookieConsentContext()
  const [customUserId] = useCookie(ELEVAR_USER_ID_COOKIE)
  const [, setAlgoliaLastIndexName] = useCookie('algoliaLastIndexName')
  const [, setAlgoliaLastQueryId] = useCookie('algoliaLastQueryId')

  useConfigure({
    hitsPerPage: 1000,
    clickAnalytics: true,
    userToken: customUserId ?? undefined,
  })

  const { popularSearchTerms } = searchSettings
  const { recentlySearchedTerms, setManuallyAppliedValue } = useAlgoliaSearch()

  const hasSearchHistory = recentlySearchedTerms && recentlySearchedTerms.length > 0

  // Store in local storage for telemetry purposes later on in the user journey
  // @see https://www.algolia.com/doc/guides/sending-events/guides/queryid/#track-the-queryid-in-cookies-or-local-storage
  useEffect(() => {
    if (hasConsentedToCookie('statistics')) {
      if (results.index) {
        setAlgoliaLastIndexName(results.index, defaultCookieConfig)
      }

      if (results.queryID) {
        setAlgoliaLastQueryId(results.queryID, defaultCookieConfig)
      }
    }
  }, [hasConsentedToCookie, results, setAlgoliaLastIndexName, setAlgoliaLastQueryId])

  return (
    <div className={cn('px-4 pb-10 lg:px-9', className)}>
      <div className='relative grid grid-cols-4 gap-y-4 lg:grid-cols-12 lg:gap-y-8'>
        <MisterCustomSearchBox className='col-span-full mt-6 lg:col-span-6 lg:col-start-4' />
        <div className={cn('col-span-full grid transition-[grid-template-rows] duration-200', status === 'loading' ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]')}>
          <div className='min-h-0 overflow-hidden'>
            <div className='relative flex items-center justify-center py-4'>
              <MisterSpinner />
            </div>
          </div>
        </div>
        <MisterEmptyQueryBoundary
          fallback={
            <div className='col-span-full flex flex-col gap-y-10 max-lg:mt-4'>
              <div className='flex flex-col gap-y-6'>
                <h3 className='text-body-xl-bold lg:text-center'>{translate('popularSearchesTitle', 'Popular searches')}</h3>
                <ul className='flex flex-wrap gap-2 lg:flex-nowrap lg:justify-center'>
                  {popularSearchTerms?.map((term) => <MisterSearchTerm key={`popular-term-${term}`} term={term} onClick={setManuallyAppliedValue} />)}
                </ul>
              </div>
              {hasSearchHistory ? (
                <div className='flex flex-col gap-y-6'>
                  <h3 className='text-body-xl-bold lg:text-center'>{translate('recentSearchesTitle', 'Recent searches')}</h3>
                  <ul className='flex flex-wrap gap-2 lg:flex-nowrap lg:justify-center'>
                    {recentlySearchedTerms?.map((term: string) => <MisterSearchTerm key={`recent-search-${term}`} term={term} onClick={setManuallyAppliedValue} />)}
                  </ul>
                </div>
              ) : null}
              <MisterTrendingItems title={translate('trendingProductsTitle', 'Trending Products')} className='col-span-full' />
            </div>
          }
        >
          <MisterCustomHits className='col-span-full' />
        </MisterEmptyQueryBoundary>
      </div>
    </div>
  )
}

export default MisterSearchBar
