import { cn } from '@ui/lib/utils'
import { CSSProperties, FunctionComponent } from 'react'
import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import MisterMedia from '../media/MisterMedia'
import MisterSiteLink from '../site-link/MisterSiteLink'
import { SectionGridCalloutsData } from 'data-access/sanity/fragments/sections/sectionGridCallouts.fragment'

interface GridCalloutProps extends SectionGridCalloutsData {
  className?: string
  style?: CSSProperties
}

const GridCallout: FunctionComponent<GridCalloutProps> = ({ image, text, button, columnSpan, className, style }) => {
  const hasImage = image?.mobile?.url || image?.desktop?.url

  const content = (
    <>
      <div className='relative h-full w-full'>
        {hasImage ? <MisterMedia media={image} className='h-full w-full rounded-md object-cover' /> : <div className='h-full min-h-[284px] w-full bg-brand-grey' />}
      </div>
      <div className='absolute inset-0 flex flex-col justify-end gap-2 rounded-md bg-gradient-to-t from-black/70 to-transparent to-50% p-6 lg:gap-4 lg:p-10'>
        {text && <h4 className={cn('text-balance text-callout-title-sm text-white', columnSpan === 2 ? 'w-3/4 lg:text-callout-title-lg' : 'lg:text-callout-title-md')}>{text}</h4>}
        {button?.buttonText && <span className='-m-4 p-4 text-btn-lg text-white underline'>{button.buttonText}</span>}
      </div>
    </>
  )

  return (
    <li style={style} className={cn(className, 'w-full')}>
      {button?.link ? (
        <MisterSiteLink
          onClick={() => trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_GRID_CALLOUT, { clickTitle: text, clickDestination: button.link?.page?.slug?.current || '' })}
          link={button.link}
          className='flex w-full flex-col gap-2'
        >
          {content}
        </MisterSiteLink>
      ) : (
        <div className='flex h-full flex-col gap-2'>{content}</div>
      )}
    </li>
  )
}

export default GridCallout
