import { Link as LinkType } from 'data-access/sanity/fragments/components/link.fragment'
import Link from 'next/link'
import { forwardRef, MouseEventHandler, PropsWithChildren } from 'react'

export interface MisterSiteLinkProps {
  link?: LinkType
  className?: string
  disabled?: boolean
  queryParam?: string
  onMouseEnter?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>
  onClick?: MouseEventHandler<HTMLAnchorElement>
  e2eSelector?: string
  // rest
  [key: string]: any
}

export const makeHref = (link?: LinkType): string => {
  if (!link) return ''
  const { type = 'internal' } = link || {}
  let url: string | undefined = ''
  let subPath = ''
  switch (type) {
    case 'phone':
      url = `tel:${link?.phone}`
      break
    case 'email':
      url = `mailto:${link?.email}`
      break
    case 'scrollToSection':
      url = `#${link?.scrollToSection?._id}`
      break
    default:
      url = link?.page?.slug?.current === '/' ? '' : link?.page?.slug?.current
      subPath =
        link?.page?._type === 'product'
          ? '/products/'
          : link?.page?._type === 'collection'
            ? '/collections/'
            : link?.page?._type === 'pageAccount'
              ? '/account/'
              : link?.page?._type === 'pagePressRelease'
                ? '/press-releases/'
                : link?.page?._type === 'blog' || link?.page?._type === 'pageArticle'
                  ? '/blog/'
                  : '/'
      break
  }
  return `${subPath}${url}`
}

const MisterSiteLink = forwardRef<HTMLButtonElement | HTMLAnchorElement, PropsWithChildren & MisterSiteLinkProps>(
  ({ link, children, className, disabled, onMouseEnter, queryParam, onClick, e2eSelector, ...rest }, ref) => {
    const { type = 'internal' } = link || {}

    if (type === 'external')
      return (
        <a href={link?.externalLink} className={className} target={link?.newTab === false ? '_self' : '_blank'} rel='noreferrer' {...rest}>
          {children}
        </a>
      )

    const url = makeHref(link)

    const scrollToSection: MouseEventHandler<HTMLAnchorElement> = (e) => {
      e.preventDefault()

      const section = document.querySelector(`[data-id="${link?.scrollToSection?._id}"]`)
      section &&
        section.scrollIntoView({
          behavior: 'smooth',
        })

      !!onClick && onClick(e)
    }

    return disabled ? (
      <button disabled className={className} onMouseEnter={onMouseEnter} data-testid={e2eSelector}>
        {children}
      </button>
    ) : (
      <Link
        href={`${url}${queryParam ? queryParam : ''}`}
        title={link?.linkText}
        className={className}
        target={link?.newTab ? '_blank' : '_self'}
        onMouseEnter={onMouseEnter}
        onClick={link?.type === 'scrollToSection' ? scrollToSection : onClick}
        data-testid={e2eSelector}
        {...rest}
      >
        {children}
      </Link>
    )
  },
)
MisterSiteLink.displayName = 'MisterSiteLink'

export default MisterSiteLink
