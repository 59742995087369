import * as TogglePrimitive from '@radix-ui/react-toggle'
import { cn } from '@ui/lib/utils'
import * as React from 'react'
import FilterSort from '../icons/FilterSort'

const FilterMenuToggle = React.forwardRef<React.ElementRef<typeof TogglePrimitive.Root>, React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> & { filtersActive?: boolean }>(
  ({ filtersActive = false, children, className, ...props }, ref) => (
    <TogglePrimitive.Root
      ref={ref}
      className={cn(
        'group/filterSort inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-full bg-transparent p-2 text-btn-md ring-offset-transparent transition-colors duration-200 ease-out focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 lg:border-[1.5px] lg:border-primary lg:py-5 lg:pl-6 lg:pr-7 lg:data-[state=on]:bg-primary lg:data-[state=on]:text-primary-foreground',
        className,
      )}
      {...props}
    >
      <FilterSort isActive={props.pressed} />
      <span className='relative flex h-min gap-1'>
        {children}
        {filtersActive && <div className='absolute -right-2.5 -top-1.5 size-2 self-start rounded-full bg-current' />}
      </span>
    </TogglePrimitive.Root>
  ),
)

export { FilterMenuToggle }
