import { type ClassValue, clsx } from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'

const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [
        'text-heading-1',
        'text-heading-1-aw24',
        'text-heading-1-festive',
        'text-heading-2',
        'text-heading-3',
        'text-heading-3-aw24',
        'text-heading-3-festive',
        'text-heading-4',
        'text-heading-5',
        'text-text-label-s',
        'text-lead-l',
        'text-lead-m',
        'text-lead-s',
        'text-lead-xs',
        'text-body-1',
        'text-body-2',
        'text-body-3',
        'text-body-4',
        'text-price-l',
        'text-price-s',
        'text-label-l',
        'text-label-m',
        'text-label-s',
        'text-label-xs',
        'text-nav-l',
        'text-nav-s',
        'text-subtitle-l',
        'text-subtitle-s',
        'text-body-xl',
        'text-body-xl-bold',
        'text-body-lg',
        'text-body-lg-bold',
        'text-body-md',
        'text-body-md-bold',
        'text-body-blog-md',
        'text-body-blog-md-bold',
        'text-body-sm',
        'text-body-sm-bold',
        'text-body-xs',
        'text-body-xs-bold',
        'text-btn-xl',
        'text-btn-lg',
        'text-btn-md',
        'text-btn-sm',
        'text-callout-title-lg',
        'text-callout-title-md',
        'text-callout-title-sm',
      ],
      h: ['h-announcement-bar-height'],
      'text-color': [
        'text-brand-blue',
        'text-brand-blue-action',
        'text-brand-blue-grey',
        'text-brand-blue-light',
        'text-brand-blue-pale',
        'text-brand-brass',
        'text-brand-beige',
        'text-brand-beige-light',
        'text-brand-positive',
        'text-brand-warning',
        'text-brand-grey-dark',
        'text-brand-muted',
        'text-brand-disabled',

        'text-brand-blue-dark-disabled',
        'text-brand-blue-aw23',
        'text-brand-grey',
        'text-brand-medium-grey',
        'text-brand-yellow',
        'text-brand-orange',
        'text-trustpilot-green',

        'text-border',
        'text-input',
        'text-ring',
        'text-background',
        'text-foreground',
        'text-primary',
        'text-primary-foreground',

        'text-secondary',
        'text-secondary-foreground',
        'text-destructive',
        'text-destructive-foreground',
        'text-muted',
        'text-muted-foreground',
        'text-accent',
        'text-accent-foreground',
        'text-popover',
        'text-popover-foreground',
        'text-card',
        'text-card-foreground',
      ],
    },
  },
})

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
