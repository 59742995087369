import { Button } from '@ui/components/ui/button'
import { Image } from 'data-access/sanity/fragments/common/getImage.fragment'
import { Link } from 'data-access/sanity/fragments/components/link.fragment'
import React, { FunctionComponent } from 'react'
import MisterImage from 'src/components/shared/image/MisterImage'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'

interface MenuCardCtaProps {
  title: string
  link: Link
  buttonText?: string
  image: Image
}

const MenuCardCta: FunctionComponent<MenuCardCtaProps> = ({ title, link, buttonText, image }) => {
  return (
    <div className='mt-auto pb-8'>
      <div className='relative'>
        <div className='absolute inset-0 flex flex-col justify-between p-6'>
          <h2 className='text-heading-3 text-brand-blue'>{title}</h2>
          <div>
            <MisterSiteLink link={link}>
              <Button variant='secondary' hover='expandIcon'>
                {buttonText || title}
              </Button>
            </MisterSiteLink>
          </div>
        </div>
        <MisterImage mobile={image} className='max-h-[432px] rounded-md object-cover object-center' />
      </div>
    </div>
  )
}

export default MenuCardCta
