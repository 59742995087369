'use client'

import * as React from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider'

import { cn } from '@ui/lib/utils'

export const SliderThumb = () => (
  <SliderPrimitive.Thumb className='block size-3 cursor-grab rounded-full bg-primary ring-offset-transparent transition-colors focus-visible:outline-none focus-visible:ring-[0.5px] focus-visible:ring-primary focus-visible:ring-offset-1 active:cursor-grabbing disabled:pointer-events-none disabled:opacity-50' />
)

const Slider = React.forwardRef<React.ElementRef<typeof SliderPrimitive.Root>, React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root ref={ref} className={cn('relative flex w-full touch-none select-none items-center', className)} {...props}>
    <SliderPrimitive.Track className='relative h-0.5 w-full grow overflow-hidden rounded-full bg-brand-blue-light'>
      <SliderPrimitive.Range className='absolute h-full bg-primary' />
    </SliderPrimitive.Track>
    <SliderThumb />
  </SliderPrimitive.Root>
))
Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
