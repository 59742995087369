import { baseImageBuilder, Image } from 'sanity-helpers'
import { FunctionComponent } from 'react'
import { cn } from 'ui/lib/utils'

interface MisterProductCardImageProps {
  image: Image
  sizes: string
  alt: string
  className?: string
  priority?: boolean
  defaultWidth?: number
  crop?: boolean
}

type SingleImage = {
  url: string
  width: number
  height: number
  isDefault: boolean
}

const MisterProductCardImage: FunctionComponent<MisterProductCardImageProps> = ({ image, sizes, alt, className, priority = false, defaultWidth = 1000, crop = false }) => {
  if (!image?.url) {
    return null
  }

  const widths = [defaultWidth / 4, defaultWidth / 2, defaultWidth, defaultWidth * 2, defaultWidth * 3]

  const images: SingleImage[] = widths.map((width) => {
    const height = Math.floor((3 * width) / 2)

    let baseImage = baseImageBuilder(image, 100).width(width).height(height)

    // Crop image for model shot AB test
    if (crop) {
      const cropPercentage = 0.65
      const { width, height } = image
      const rectWidth = Math.floor(width * cropPercentage)
      const rectHeight = Math.floor(height * cropPercentage)
      const x = Math.floor((width - rectWidth) / 2)
      const y = Math.floor(height - rectHeight)
      baseImage = baseImage.rect(x, y, rectWidth, rectHeight)
    }

    return {
      url: baseImage.url(),
      width,
      height,
      isDefault: width === defaultWidth,
    }
  })

  const defaultImage = images.find((image) => image.isDefault)

  // Note: do not use Next.js Image component, the sheer amount of
  // images on the PDP causes the page to crash on iOS Safari
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      className={cn('size-full', className)}
      src={defaultImage?.url}
      alt={alt}
      loading={priority ? 'eager' : 'lazy'}
      itemProp='image'
      width={defaultImage?.width}
      height={defaultImage?.height}
      srcSet={images.map((image) => `${image.url} ${image.width}w`).join(', ')}
      sizes={sizes}
    />
  )
}

export default MisterProductCardImage
