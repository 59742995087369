import { useRouter } from 'next/router'
import { FunctionComponent, useContext } from 'react'
import { GlobalsContext } from 'src/contexts/Globals.context'
import { formatPrice } from 'src/utils/price.util'

const Price: FunctionComponent<{ price: number }> = ({ price }) => {
  const { locale } = useRouter()
  const {
    localizedSettings: { showCurrencySymbol },
  } = useContext(GlobalsContext)

  return <>{formatPrice(price, locale, showCurrencySymbol)}</>
}

export default Price
