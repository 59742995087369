import { FunctionComponent, useState } from 'react'
import { TrustpilotBusinessReview } from '@/types/businessReview'
import { format, formatISO, parseISO } from 'date-fns'
import MisterRating from './MisterRating'
import MisterSiteLink from '../site-link/MisterSiteLink'
import { anonymizeAuthorName, getTrustpilotReviewUrl, getTrustpilotUrl } from 'src/utils/reviews.util'
import { useTranslations } from 'src/contexts/Globals.context'
import { useRouter } from 'next/router'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperType } from 'swiper/types'
import { Autoplay } from 'swiper/modules'
import MisterIcon from '../MisterIcon'
import MisterChevronButton from '../button/MisterChevronButton'
import { cn } from 'ui/lib/utils'
import { useToggle } from 'react-use'

interface Props {
  reviews: TrustpilotBusinessReview[]
}

const MisterReviewCarousel: FunctionComponent<Props> = ({ reviews }) => {
  const { locale } = useRouter()
  const translate = useTranslations()
  const [swiper, setSwiper] = useState<SwiperType | null>(null)
  const trustPilotLink = getTrustpilotUrl(locale)
  const [currentSwiperIndex, setCurrentSwiperIndex] = useState<number | undefined>(swiper?.activeIndex)
  const [swiperIsEnd, setSwiperIsEnd] = useToggle(false)

  return (
    <Swiper
      modules={[Autoplay]}
      spaceBetween={16}
      slidesPerView='auto'
      autoplay={{ delay: 4000 }}
      speed={400}
      onSwiper={(s) => {
        setSwiper(s)
        s.on('slideChange', (s) => {
          setCurrentSwiperIndex(s.realIndex)
          setSwiperIsEnd(s.isEnd)
        })
        setCurrentSwiperIndex(s.realIndex)
      }}
    >
      {reviews.map((review, i) => {
        const date = parseISO(review.createdAt)
        const dateFormattedLocalized = format(date, 'dd-MM-y')
        const dateFormattedISO = formatISO(date, { representation: 'date' })

        return (
          <SwiperSlide key={i} className='flex !h-auto min-h-56 max-w-80 justify-between bg-brand-beige-light p-6'>
            <MisterSiteLink
              link={{
                type: 'external',
                externalLink: getTrustpilotReviewUrl(review.id, locale),
              }}
              title='Trustpilot'
            >
              <article className='flex h-full flex-col justify-between'>
                <div>
                  <header>
                    <MisterRating rating={review.stars || 5} max={5} size='medium' className='mb-2 flex -translate-x-1 text-brand-blue md:mb-4' />
                    <h3 className='mb-3 text-body-md-bold' title={review.title}>
                      {review.title}
                    </h3>
                  </header>
                  <blockquote className='text-body-md'>{review.text}</blockquote>
                </div>
                <p className='flex gap-4 text-body-md'>
                  <span className='font-semibold'>
                    {review.consumer.displayName ? anonymizeAuthorName(review.consumer.displayName) : translate('labelVerifiedBuyer', 'Verified buyer')}
                  </span>
                  <time className='whitespace-nowrap' dateTime={dateFormattedISO}>
                    {dateFormattedLocalized}
                  </time>
                </p>
              </article>
            </MisterSiteLink>
          </SwiperSlide>
        )
      })}
      <div className='mt-5 flex justify-between pr-6 md:mt-6 lg:pr-14'>
        <div className='hidden gap-6 md:flex'>
          <MisterChevronButton
            className={cn(currentSwiperIndex == 0 && 'pointer-events-none opacity-30')}
            orientation='left'
            variant='off-white'
            onClick={() => {
              swiper?.slidePrev()
            }}
          />
          <MisterChevronButton
            className={cn(swiperIsEnd && 'pointer-events-none opacity-30')}
            orientation='right'
            variant='off-white'
            onClick={() => {
              swiper?.slideNext()
            }}
          />
        </div>
        <MisterSiteLink
          className='group flex items-center gap-1 text-body-xs-bold uppercase md:text-btn-sm'
          link={{ type: 'external', externalLink: trustPilotLink }}
          title='Trustpilot'
        >
          <span>{translate('labelViewMoreReviews', 'View more reviews')}</span>
          <MisterIcon className='size-4 transition group-hover:translate-x-1 md:size-5' type='submit-arrow' />
        </MisterSiteLink>
      </div>
    </Swiper>
  )
}

export default MisterReviewCarousel
