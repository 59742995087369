const defaultDateFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }
export const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const
export type Days = (typeof days)[number]

/**
 * Format date to locale.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 */
export const formatDateInternational = (date: string | number | Date, locale?: string, options?: object) => {
  return new Intl.DateTimeFormat(locale === 'en-us' ? 'en-US' : 'en-GB', options || defaultDateFormatOptions)?.format(new Date(date))
}
