import { DiscountCampaign } from '@/types/globals'

export function getDiscountCampaign(discountCampaigns?: DiscountCampaign[], productId?: string): DiscountCampaign | undefined {
  if (!discountCampaigns) {
    return undefined
  }

  return discountCampaigns.filter(({ showOnCollections, productIds }) =>
    productIds?.some((id) => (showOnCollections === 'select' ? id === productId : showOnCollections === 'exclude' ? id !== productId : true)),
  )[0]
}
