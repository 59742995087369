import { Checkbox } from '@ui/components/ui/checkbox'
import { Label } from '@ui/components/ui/label'
import { FC } from 'react'
import { RendererProps } from '../SearchFilterGroup'

const DefaultRenderer: FC<RendererProps> = ({ label, value, isRefined, refine, count }) => (
  <>
    <Checkbox id={`filter-${label}-value-${value}`} checked={isRefined} value={value} onClick={() => refine(value)} />
    <Label className='cursor-pointer' htmlFor={`filter-${label}-value-${value}`}>
      {label} {count && <span className='text-brand-disabled'>({count})</span>}
    </Label>
  </>
)

export default DefaultRenderer
