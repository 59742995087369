import { Button } from '@ui/components/ui/button'
import { SectionPromoData } from 'data-access/sanity/fragments/sections/sectionPromo.fragment'
import { FunctionComponent } from 'react'
import MisterMedia from 'src/components/shared/media/MisterMedia'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'

type SectionPromoProps = {
  data: SectionPromoData
}

const SectionPromo: FunctionComponent<SectionPromoProps> = ({ data }) => {
  const promoId = `promo-title-${data._id}`

  return (
    <section id='section-promo' className='w-full p-9' aria-labelledby={promoId}>
      <div className='flex flex-col gap-4 rounded-lg bg-brand-grey-light px-6 py-10 lg:flex-row lg:gap-4 lg:px-20'>
        <figure className='flex flex-1 items-center justify-center'>
          <MisterMedia media={data.media} />
        </figure>
        <div className='flex flex-1 flex-col justify-center gap-7 lg:gap-10'>
          <div className='flex flex-col gap-4'>
            <h2 id={promoId} className='text-heading-1 text-brand-blue'>
              {data.title}
            </h2>
            <p className='text-body-xl'>{data.description}</p>
          </div>
          {data.cta && (
            <footer>
              <Button className='w-fit' variant='outline' asChild>
                <MisterSiteLink link={data.cta}>{data.cta.linkText}</MisterSiteLink>
              </Button>
            </footer>
          )}
        </div>
      </div>
    </section>
  )
}

export default SectionPromo
