export const localeCurrencyMap: Record<string, string> = {
  gb: 'GBP',
  dk: 'DKK',
  nl: 'EUR',
  de: 'EUR',
  fr: 'EUR',
  it: 'EUR',
  es: 'EUR',
  ch: 'CHF',
}

/**
 * Format price according to locale.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 */
export const formatPrice = (price: number | string, locale = 'en', showCurrency = true, removeZeroDecimals = true): string => {
  const useSpaceBetweenCurrencyAndPrice = ['dk', 'ch'].includes(locale)

  const format: string = new Intl.NumberFormat(locale, {
    style: showCurrency ? 'currency' : 'decimal',
    useGrouping: false,
    currency: localeCurrencyMap?.[locale] || 'EUR',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(Number(price))
    // Remove whitespace
    .replace(/\s/g, useSpaceBetweenCurrencyAndPrice ? ' ' : '')
    // If the price begins with digit, place the space after the digit
    .replace(/^([\d,.]+)/, '$1 ')
    // If the price ends with digit, place the space before the digit
    .replace(/([\d,.]+)$/, ' $1')

  const formatDecimals = removeZeroDecimals ? format.replace('.00', '').replace(',00', '').replace(' ', '') : format

  return /^\d+$/.test(formatDecimals) ? `${formatDecimals},-` : formatDecimals
}
