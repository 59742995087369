import { useState } from 'react'
import useCustomer from './useCustomer'
import { post } from '../../utils/request.util'
import { useRouter } from 'next/router'

interface State {
  response: null | boolean
  loading: boolean
  error: null | string
}

export const useForgotPassword = (): [(email?: string) => Promise<void>, State] => {
  const { locale } = useRouter()
  const { mutateCustomer } = useCustomer()
  const [state, setState] = useState<State>({
    response: null,
    loading: false,
    error: null,
  })

  const forgotPassword = async (email?: string) => {
    if (email) {
      setState({ response: null, loading: true, error: null })

      try {
        const response = await mutateCustomer(
          post('/api/shopify/account/forgot-password', {
            email,
            locale,
          }),
        )

        setState({
          response,
          loading: false,
          error: null,
        })
      } catch (error: any) {
        setState({
          response: null,
          loading: false,
          error: error?.data?.error || error.message || 'Something went wrong.',
        })
      }
    } else {
      setState({
        response: null,
        loading: false,
        error: 'Email is missing',
      })
    }
  }

  return [forgotPassword, state]
}

export default useForgotPassword
