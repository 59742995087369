import { SectionPaddingData } from 'data-access/sanity/fragments/sections/sectionPadding.fragment'
import { CSSProperties, FunctionComponent } from 'react'

interface Props {
  data: SectionPaddingData
  index: number
}

const SectionPadding: FunctionComponent<Props> = ({ data }) => {
  const { desktopPadding, mobilePadding } = data || {}

  return (
    <div
      aria-hidden='true'
      data-label='section-padding'
      style={
        {
          '--desktopPadding': desktopPadding,
          '--mobilePadding': mobilePadding,
        } as CSSProperties
      }
      className='h-[var(--mobilePadding)] w-full lg:h-[var(--desktopPadding)]'
    />
  )
}

export default SectionPadding
