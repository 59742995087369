import { FunctionComponent } from 'react'
import { TrackingInfo } from '../section-aftership'
import { useTranslations } from 'src/contexts/Globals.context'
import MisterIcon from 'src/components/shared/MisterIcon'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@ui/components/ui/tooltip'
import { ExternalLink } from 'lucide-react'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import MisterCopyIcon from 'src/components/shared/button/MisterCopyIcon'

export const Carrier: FunctionComponent<{ shipment: TrackingInfo; locale?: string }> = ({ shipment }) => {
  const translate = useTranslations()

  const carrierLogos = {
    'dhl-germany': {
      name: 'DHL',
      logo: 'dhl',
    },
    postnl: {
      name: 'PostNL',
      logo: 'postnl',
    },
    'royal-mail': {
      name: 'Royal Mail',
      logo: 'royal-mail',
    },
    'dpd-de': {
      name: 'DPD',
      logo: 'dpd',
    },
    bpost: {
      name: 'Bpost',
      logo: 'bpost',
    },
    'spain-correos-es': {
      name: 'Correos',
      logo: 'correos',
    },
    postnord: {
      name: 'PostNord',
      logo: 'postnord',
    },
    'la-poste-colissimo': {
      name: 'La Poste',
      logo: 'la-poste',
    },
    'poste-italiane': {
      name: 'Poste Italiane',
      logo: 'poste-italiane',
    },
    fedex: {
      name: 'FedEx',
      logo: 'fedex',
    },
    ups: {
      name: 'UPS',
      logo: 'ups',
    },
  }

  const carrierInfo = () =>
    shipment.slug && (
      <div className='grid grid-cols-6 items-center gap-3 text-body-md-bold'>
        {carrierLogos[shipment?.slug] ? (
          <>
            <MisterIcon className='col-span-1 size-10' type={carrierLogos[shipment?.slug]?.logo} />
            <span className='col-span-1'>{carrierLogos[shipment.slug].name}</span>
          </>
        ) : (
          <span className='col-span-2'>{shipment.slug}</span>
        )}
        {shipment.tracking_number && (
          <div className='col-span-5 col-start-2 flex items-center gap-3 md:col-span-4 md:col-start-auto md:justify-end'>
            <span>{shipment.tracking_number}</span>
            <MisterCopyIcon textToCopy={shipment.tracking_number} tooltipText={translate('copyTrackingNumber', 'Copy tracking number')} />
            <TooltipProvider delayDuration={200}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <ExternalLink size={22} />
                </TooltipTrigger>
                <TooltipContent>
                  <span className='text-body-sm capitalize'>{translate('visitCarrierWebsite', 'Visit carrier website')}</span>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      </div>
    )

  return (
    <div>
      <div className='mb-7 text-body-md-bold md:text-body-xl-bold'>{translate('carrier', 'Carrier')}</div>
      {shipment.courier_tracking_link ? (
        <MisterSiteLink
          link={{
            type: 'external',
            externalLink: shipment.courier_tracking_link,
            newTab: true,
          }}
        >
          {carrierInfo()}
        </MisterSiteLink>
      ) : (
        carrierInfo()
      )}
    </div>
  )
}
