import { cn } from 'ui/lib/utils'
import MisterIcon from '../../../shared/MisterIcon'
import RichText from '../../../shared/rich-text/rich-text'
import { FunctionComponent, MouseEventHandler, useEffect } from 'react'
import { useToggle } from 'react-use'
import { useCookieConsentContext } from '../../../../hooks/context/useCookieConsentContext'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { ContactBlock as ContactBlockType } from 'data-access/sanity/fragments/sections/sectionContact.fragment'

interface ContactBlockProps {
  block: ContactBlockType
  customerServiceData: {
    email?: string
    phone?: string
    whatsApp?: string
    messenger?: string
  }
}

const ContactBlock: FunctionComponent<ContactBlockProps> = ({ block, customerServiceData }) => {
  // Chat button has a different presentation and behaviour
  // @see apps/store/src/components/scripts/MisterScripts.tsx
  const isChatBlock = block?.contactType === 'chat'

  const { consent, hasConsentedToCookie } = useCookieConsentContext()
  const [showChatToggle, setShowChatToggle] = useToggle(false)

  useEffect(() => {
    if (isChatBlock) {
      setShowChatToggle(hasConsentedToCookie('preferences'))
    }
  }, [consent, hasConsentedToCookie, isChatBlock, setShowChatToggle])

  const ContactBlock = (
    block: ContactBlockType,
    link?: { type: string; newTab: boolean; linkText?: string; phone?: string; email?: string; externalLink?: string },
    onClick?: MouseEventHandler<HTMLButtonElement | HTMLDivElement>,
  ) => {
    const { contactType, content, showLink } = block
    const Content = () => (
      <div
        className={cn(
          'group relative flex h-full min-h-[80px] items-center border border-brand-grey p-4 transition-all duration-200 md:hover:border-brand-blue',
          isChatBlock && !showChatToggle && 'hidden',
        )}
      >
        {contactType && <MisterIcon type={contactType} className='mr-6 size-8 lg:mr-8' />}
        <div>
          {content && <RichText className='text-body-md' data={content} />}
          {showLink && link?.linkText && (
            <span className='border-b border-transparent text-body-md text-brand-blue duration-200 group-hover:border-brand-blue'> {link?.linkText}</span>
          )}
        </div>
      </div>
    )

    return link ? <MisterSiteLink link={link}>{Content()}</MisterSiteLink> : <button onClick={onClick}>{Content()}</button>
  }

  switch (block?.contactType) {
    case 'phone':
      return ContactBlock(block, { type: 'phone', newTab: true, linkText: customerServiceData.phone, phone: customerServiceData.phone })
    case 'email':
      return ContactBlock(block, { type: 'email', newTab: true, linkText: customerServiceData.email, email: customerServiceData.email })
    case 'messenger':
      return ContactBlock(block, {
        type: 'external',
        linkText: customerServiceData.messenger,
        newTab: true,
        externalLink: 'https://m.me/' + customerServiceData.messenger?.replace(/\s/g, ''),
      })
    case 'whatsapp':
      return ContactBlock(block, {
        type: 'external',
        newTab: true,
        linkText: customerServiceData.whatsApp,
        externalLink: 'https://wa.me/' + customerServiceData.whatsApp?.replace(/\s/g, ''),
      })
    case 'chat':
      return ContactBlock(block, undefined, () => window.Kustomer.open())
    default:
      return undefined
  }
}

export default ContactBlock
