import { FunctionComponent, useEffect, useState } from 'react'
import Link from 'next/link'
import { getBaseUrl } from 'src/utils/url.util'
import { NavigationMenu, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, NavigationMenuTrigger } from '@ui/components/ui/navigation-menu'
import LogoSvg from './LogoSvg'
import { DesktopMenuContent } from './DesktopMenu/DesktopMenuContent'
import { WishlistButton } from './WishlistButton'
import { AccountButton } from './AccountButton'
import { CartButton } from './CartButton'
import { MobileMenuButton } from './MobileMenu/MobileMenuButton'
import { MobileMenuCloseButton } from './MobileMenu/MobileMenuCloseButton'
import { MobileMenu } from './MobileMenu/MobileMenu'
import { Button } from '@ui/components/ui/button'
import MisterSiteLink, { makeHref } from 'src/components/shared/site-link/MisterSiteLink'
import { ChevronDown } from 'lucide-react'
import { useRouter } from 'next/router'
import { SearchButton } from './SearchButton'
import { SearchOverlay } from './SearchOverlay/SearchOverlay'
import { MobileMenuContextProvider } from 'src/contexts/MobileMenu.context'
import { useUI } from 'src/contexts/UI.context'
import { cn } from '@ui/lib/utils'
import AlgoliaContextProvider from 'src/contexts/AlgoliaSearch.context'
import { GetMinimalNavigationResponse } from 'data-access'
import { useQuery } from '@tanstack/react-query'
import { getFullNavigation, Navigation } from 'src/domain/navigation.domain'
import { MainMenuItem } from 'data-access/sanity/fragments/components/mainMenuItem.fragment'
import { usePostHog } from 'posthog-js/react'
import { captureFeatureView, useExperimentFeatureFlag } from 'src/hooks/posthog/useExperimentFeatureFlag'
import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'

export type ActiveVariant = 'control' | 'variant-1' | 'variant-2' | string | boolean | undefined

export const getSiteUrl = (path = '/'): Readonly<string> => `${getBaseUrl()}${path}`

interface HeaderProps {
  data: GetMinimalNavigationResponse
  preview?: boolean
}

const Header: FunctionComponent<HeaderProps> = ({ data, preview }) => {
  const { locale, push } = useRouter()
  const [activeStyle, setActiveStyle] = useState<number | null>(null)
  const [capturedNavigationMenuState, setCapturedNavigationMenuState] = useState<string>('')
  const { mainMenuItems, wishlistSlug } = data || {}
  const { displaySearchUI } = useUI()
  const postHog = usePostHog()
  const flag = 'new-navigation-dec'
  const variant: ActiveVariant = useExperimentFeatureFlag(flag, undefined, false) || 'control' // Default to control if no variant is found

  const {
    data: navigationData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['navigation', locale, data, preview],
    queryFn: () => getFullNavigation(data, locale, preview),
    initialData: data,
  })

  // Conditional checks for menu when in preview mode or not
  const hasMenu = preview ? !!navigationData : !error && !isLoading && !!navigationData

  const handleMegaMenuChange = (value: string) => {
    if (value) {
      trackEvent(TELEMETRY_DATA_LAYER_EVENTS.OPEN_NAVIGATION)
    }

    setCapturedNavigationMenuState(value)
    value === ''
      ? setTimeout(() => {
          setActiveStyle(null)
        }, 400)
      : setActiveStyle(null)
  }

  useEffect(() => {
    captureFeatureView(flag, postHog)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <header data-header className={cn('overflow-hidden bg-white', displaySearchUI ? 'lg:overflow-hidden' : 'lg:overflow-visible')}>
      <div className='grid min-h-14 grid-cols-[1fr,auto,1fr] items-center justify-center px-2 py-1 lg:border-b lg:px-10 lg:py-2'>
        <MobileMenuButton />
        <Link
          href='/'
          className='group-hover:text-brand-blue-dark col-start-2 flex items-center justify-center'
          rel='home'
          title='MR MARVIS'
          itemScope
          itemType='https://schema.org/Organization'
        >
          <LogoSvg className='h-[1.063rem] lg:h-6' />
          <meta itemProp='name' content='MR MARVIS' />
          <meta itemProp='url' content={getSiteUrl()} />
          <meta itemProp='logo' content={getSiteUrl('/logo.svg')} />
        </Link>
        <div className='relative flex gap-1 justify-self-end'>
          <SearchButton />
          <AccountButton />
          <WishlistButton wishlistSlug={wishlistSlug} />
          <CartButton />
          <MobileMenuCloseButton />
        </div>
      </div>
      {hasMenu && (
        <MobileMenuContextProvider data={navigationData as Navigation} activeVariant={variant}>
          <AlgoliaContextProvider>
            <MobileMenu />
            <SearchOverlay />
          </AlgoliaContextProvider>
        </MobileMenuContextProvider>
      )}

      <NavigationMenu
        onValueChange={(value) => handleMegaMenuChange(value)}
        className={cn('hidden h-12 w-screen border-b lg:flex', displaySearchUI && 'lg:h-0 lg:opacity-0 lg:transition-height lg:duration-300')}
      >
        <NavigationMenuList>
          {(hasMenu ? navigationData.mainMenuItems : mainMenuItems)?.map((mainMenuItem, i) =>
            mainMenuItem?._type === 'link' ? (
              <NavigationMenuItem asChild key={i}>
                <MisterSiteLink
                  onClick={() =>
                    trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                      clickDestination: makeHref(mainMenuItem),
                    })
                  }
                  link={mainMenuItem}
                >
                  <Button className='inline-flex w-max items-center justify-center rounded-none border-none bg-background bg-gradient-to-r from-current to-current bg-[length:0%_0.125rem] bg-[center_bottom_0rem] bg-no-repeat px-4 py-4 text-btn-md font-medium text-primary underline-offset-4 transition-[background-size,color] duration-150 ease-in-out hover:bg-[length:100%_0.125rem] focus:bg-[length:100%_0.125rem] focus:outline-none disabled:pointer-events-none disabled:opacity-50'>
                    {mainMenuItem.linkText}
                  </Button>
                </MisterSiteLink>
              </NavigationMenuItem>
            ) : (
              mainMenuItem?._type === 'mainMenuItem' && (
                <NavigationMenuItem key={i} className='flex'>
                  {/* Hidden chevron to control the menu for keyboard users */}
                  <NavigationMenuTrigger title={mainMenuItem.mainMenuItemLink.linkText} className='flex w-0 overflow-hidden !bg-none p-0 focus:w-4'>
                    <ChevronDown className='block size-4' />
                  </NavigationMenuTrigger>
                  <NavigationMenuTrigger asChild data-testid='desktop-menu-link'>
                    <NavigationMenuLink
                      href={makeHref(mainMenuItem.mainMenuItemLink)}
                      onClick={() => trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, { clickDestination: makeHref(mainMenuItem.mainMenuItemLink) })}
                      onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          push(makeHref(mainMenuItem.mainMenuItemLink))
                          trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, { clickDestination: makeHref(mainMenuItem.mainMenuItemLink) })
                        }
                      }}
                    >
                      {mainMenuItem.mainMenuItemLink.linkText}
                    </NavigationMenuLink>
                  </NavigationMenuTrigger>

                  {hasMenu && (
                    <DesktopMenuContent
                      capturedNavigationMenuState={capturedNavigationMenuState}
                      mainMenuItem={mainMenuItem as MainMenuItem}
                      activeStyle={activeStyle}
                      setActiveStyle={setActiveStyle}
                      activeVariant={variant}
                    />
                  )}
                </NavigationMenuItem>
              )
            ),
          )}
        </NavigationMenuList>
      </NavigationMenu>
    </header>
  )
}

export default Header
