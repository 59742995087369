import { FC, useEffect, useState } from 'react'
import { useCurrentRefinements } from 'react-instantsearch'
import { AlgoliaProductHit, mapAlgoliaHitToProductCard } from '../../../utils/algolia.util'
import MisterProductCard from './MisterProductCard'

type MisterSearchProductCardProps = {
  hit: AlgoliaProductHit
  className?: string
  onClick?: () => void
  onAddToCart?: () => void
}

const MisterSearchProductCard: FC<MisterSearchProductCardProps> = ({ hit, className, onClick, onAddToCart }) => {
  const product = mapAlgoliaHitToProductCard(hit)
  const { items } = useCurrentRefinements()
  const [hasSizeRefinements, setHasSizeRefinements] = useState(false)

  /**
   * When the items change, e.g. when a search filter is applied, we check if any size filter is applied
   * This is used to determine whether to fetch live product data or not
   * We only need to fetch live product data if any size filter is applied
   * In other scenario's we can use the product data from Algolia as it is near-realtime data
   */
  useEffect(() => {
    const hasSizeFilterApplied = items.some((item) => item.attribute === 'waistSizes' || item.attribute === 'lengthSizes' || item.attribute === 'sizes')
    setHasSizeRefinements(hasSizeFilterApplied)
  }, [items])

  /**
   * If for any reason the product is not available, we don't render the product card
   */
  if (!product || !product?.slug?.current) {
    return null
  }

  return (
    <MisterProductCard
      objectID={hit.objectID}
      position={hit.__position}
      queryID={hit.__queryID}
      className={className}
      product={{ ...product, _id: `${product._id}-${hit.objectID}` }}
      index={hit.__position}
      quickAddToCart
      wishList
      imageSizes='(max-width: 1024px) 50vw, 28vw'
      variantSku={hasSizeRefinements ? hit.sku : undefined}
      onClick={onClick}
      onAddToCart={onAddToCart}
    />
  )
}

export default MisterSearchProductCard
