import { SectionSupportInfoData } from 'data-access/sanity/fragments/sections/sectionSupportInfo.fragment'
import { ChevronLeft } from 'lucide-react'
import { FunctionComponent } from 'react'
import MisterLucideIcon from 'src/components/shared/MisterLucideIcon'
import RichText from 'src/components/shared/rich-text/rich-text'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'

export interface SectionSupportInfoProps {
  data: SectionSupportInfoData
}

const SectionSupportInfo: FunctionComponent<SectionSupportInfoProps> = ({ data }) => {
  return (
    <div className='w-full'>
      {data.backButton && (
        <MisterSiteLink className='group flex items-center gap-4 pb-10 lg:pb-11' link={data.backButton}>
          <ChevronLeft size={16} className='transition duration-150 group-hover:-translate-x-1' />
          <span className='text-body-md font-semibold'>{data.backButton.linkText}</span>
        </MisterSiteLink>
      )}
      <div className='flex flex-col gap-4 md:gap-7'>
        <div className='flex items-center gap-3'>
          {data?.icon && <MisterLucideIcon name={data?.icon} className='size-6 md:size-9' />}
          <h2 className='text-heading-4 font-bold md:text-heading-3'>{data?.title}</h2>
        </div>
        {data?.description && <RichText data={data.description} />}
      </div>
    </div>
  )
}

export default SectionSupportInfo
