import React, { createContext, FunctionComponent, PropsWithChildren, useContext, useState } from 'react'
import { useRouter } from 'next/router'
import { liteClient as algoliasearch } from 'algoliasearch/lite'
import { InstantSearch, InstantSearchProps } from 'react-instantsearch'
import { updateRecentSearches } from 'src/utils/search.util'
import { useSessionStorage } from 'react-use'
import { algoliaClient, algoliaInsightsClient, getAlgoliaIndexName, searchClient } from 'src/utils/algolia.util'
import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'

interface AlgoliaContextProps {
  searchClient: ReturnType<typeof algoliasearch>
  ALGOLIA_LOCALISED_SEARCH_INDEX: string
  onStateChange: InstantSearchProps['onStateChange']
  searchTerm: string
  recentlySearchedTerms: string[]
  totalResults?: number | null
  setTotalResults?: (totalResults: number) => void
  updateRecentSearches: typeof updateRecentSearches
  limitProductsBy: number
  limitCollectionsBy: number
  limitArticlesBy: number
  limitFAQsBy: number
  limitPagesBy: number
  setLimitFAQsBy: (limit: number) => void
  setLimitPagesBy: (limit: number) => void
  setLimitArticlesBy: (limit: number) => void
  setLimitProductsBy: (limit: number) => void
  setRecentlySearchedTerms: (searchTerms: string[]) => void
  manuallyAppliedValue: string
  setManuallyAppliedValue: (value: string) => void
  searchResultsFilter: string
  setSearchResultsFilter: (filter: string) => void
  setLimitCollectionsBy: (filter: number) => void
  resetLimits: () => void
}

const AlgoliaContext = createContext<undefined | AlgoliaContextProps>(undefined)

export const useAlgoliaSearch = () => {
  const context = useContext(AlgoliaContext)

  if (context === undefined) {
    throw new Error('useAlgoliaSearch must be used within a AlgoliaContextProvider.')
  }

  return context
}

const AlgoliaContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { locale } = useRouter()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [recentlySearchedTerms, setRecentlySearchedTerms] = useSessionStorage<string[]>('searchTerms', [])
  const [totalResults, setTotalResults] = useState<number | null>(null)
  const [limitProductsBy, setLimitProductsBy] = useState(8)
  const [limitCollectionsBy, setLimitCollectionsBy] = useState(4)
  const [limitArticlesBy, setLimitArticlesBy] = useState(4)
  const [limitFAQsBy, setLimitFAQsBy] = useState(4)
  const [limitPagesBy, setLimitPagesBy] = useState(4)
  const [manuallyAppliedValue, setManuallyAppliedValue] = useState<string>('')
  const [searchResultsFilter, setSearchResultsFilter] = useState('')
  const ALGOLIA_LOCALISED_SEARCH_INDEX = getAlgoliaIndexName(locale || 'en')

  const onStateChange: InstantSearchProps['onStateChange'] = ({ uiState, setUiState }) => {
    if (uiState && uiState[ALGOLIA_LOCALISED_SEARCH_INDEX]) {
      const searchTerm = uiState[ALGOLIA_LOCALISED_SEARCH_INDEX].query || ''
      setSearchTerm(searchTerm)
      if (searchTerm) {
        trackEvent(TELEMETRY_DATA_LAYER_EVENTS.SEARCH, { search_term: searchTerm })
      }
      if (uiState[ALGOLIA_LOCALISED_SEARCH_INDEX].query) {
        updateRecentSearches({
          recentlySearchedTerms: recentlySearchedTerms,
          setRecentlySearchedTerms: setRecentlySearchedTerms,
          searchTerm: uiState[ALGOLIA_LOCALISED_SEARCH_INDEX].query,
          action: 'search',
        })
      }

      setUiState(uiState)
    }
  }

  /**
   * Reset the limits to the initial values
   */
  const resetLimits = () => {
    setLimitProductsBy(8)
    setLimitCollectionsBy(4)
    setLimitArticlesBy(4)
    setLimitFAQsBy(4)
    setLimitPagesBy(4)
  }

  return (
    <AlgoliaContext.Provider
      value={{
        searchClient: algoliaClient,
        updateRecentSearches,
        ALGOLIA_LOCALISED_SEARCH_INDEX,
        limitProductsBy,
        limitCollectionsBy,
        setLimitProductsBy,
        setLimitCollectionsBy,
        recentlySearchedTerms,
        setRecentlySearchedTerms,
        onStateChange,
        totalResults,
        setTotalResults,
        searchTerm,
        manuallyAppliedValue,
        setManuallyAppliedValue,
        searchResultsFilter,
        setSearchResultsFilter,
        limitArticlesBy,
        limitFAQsBy,
        setLimitFAQsBy,
        setLimitArticlesBy,
        limitPagesBy,
        setLimitPagesBy,
        resetLimits,
      }}
    >
      <InstantSearch
        future={{
          preserveSharedStateOnUnmount: true,
        }}
        searchClient={searchClient}
        indexName={ALGOLIA_LOCALISED_SEARCH_INDEX}
        insights={{
          insightsClient: algoliaInsightsClient,
        }}
        onStateChange={onStateChange}
      >
        {children}
      </InstantSearch>
    </AlgoliaContext.Provider>
  )
}

export default AlgoliaContextProvider
