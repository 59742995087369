import { FunctionComponent, useMemo } from 'react'
import { PostHogFeature } from 'posthog-js/react'
import Sections from '../sections'
import { SectionAbTesterData } from 'data-access/sanity/fragments/sections/sectionAbTester.fragment'
import { useMedia } from 'react-use'

interface SectionAbTesterProps {
  data: SectionAbTesterData
  index: number
  [key: string]: any
}

/**
 * PostHog A/B test renderer.
 *
 * @see https://posthog.com/docs/libraries/react#experiments-ab-tests
 * @see https://github.com/PostHog/posthog-js/blob/main/react/src/components/PostHogFeature.tsx
 */
const SectionAbTester: FunctionComponent<SectionAbTesterProps> = ({ data, index, ...rest }) => {
  const enabled = data?.enabled || false
  const controlSection = <Sections index={index} section={data.controlSection} {...rest} />
  const isMobile = useMedia('(max-width: 767px)', true)
  const isTablet = useMedia('(min-width: 768px) and (max-width: 1023px)', false)
  const isDesktop = useMedia('(min-width: 1024px)', false)

  const aspectRatioValue = useMemo(() => {
    const aspectRatioBase = data?.cssAspectRatio?.find((value) => value.name === 'base')?.value
    const aspectRatioTablet = data?.cssAspectRatio?.find((value) => value.name === 'md')?.value
    const aspectRatioDesktop = data?.cssAspectRatio?.find((value) => value.name === 'lg')?.value

    if (isMobile) {
      return aspectRatioBase
    } else if (isTablet) {
      return aspectRatioTablet || aspectRatioBase
    } else if (isDesktop) {
      return aspectRatioDesktop || aspectRatioTablet || aspectRatioBase
    }
  }, [data?.cssAspectRatio, isDesktop, isMobile, isTablet])

  const minHeightValue = useMemo(() => {
    const minHeightBase = data?.cssMinHeight?.find((value) => value.name === 'base')?.value
    const minHeightTablet = data?.cssMinHeight?.find((value) => value.name === 'md')?.value
    const minHeightDesktop = data?.cssMinHeight?.find((value) => value.name === 'lg')?.value

    if (isMobile) {
      return minHeightBase
    } else if (isTablet) {
      return minHeightTablet || minHeightBase
    } else if (isDesktop) {
      return minHeightDesktop || minHeightTablet || minHeightBase
    }
  }, [data?.cssMinHeight, isDesktop, isMobile, isTablet])

  if (!enabled) {
    return controlSection
  }

  return (
    <div
      className='w-full'
      data-type={data._type}
      data-id={data._id}
      style={{
        minHeight: minHeightValue || 'auto',
        aspectRatio: aspectRatioValue || 'auto',
      }}
    >
      <PostHogFeature flag={data.featureFlag} match='control'>
        {controlSection}
      </PostHogFeature>
      <PostHogFeature flag={data.featureFlag} match={data.alternativeSectionVariantKey}>
        <Sections index={index} section={data.alternativeSection} {...rest} />
      </PostHogFeature>
    </div>
  )
}

export default SectionAbTester
