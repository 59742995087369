import { FunctionComponent, useState } from 'react'
import { coalesceQuery } from 'src/utils/sanity.util'
import { formatPrice } from 'src/utils/price.util'
import MisterImage from 'src/components/shared/image/MisterImage'
import { TrackingInfo } from '../section-aftership'
import { useTranslations } from 'src/contexts/Globals.context'
import MisterSpinner from 'src/components/shared/loader/MisterSpinner'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { useAsync } from 'react-use'
import { GET_IMAGE_FRAGMENT, Image } from 'data-access/sanity/fragments/common/getImage.fragment'
import { Slug } from 'data-access/sanity/types'
import { sanityCdnClient } from 'sanity-helpers/client'
import { useRouter } from 'next/router'

interface Product {
  productTitle?: string
  price?: string
  image?: Image
  size?: string
  quantity?: string
  slug: Slug
}

const AfterShipProducts: FunctionComponent<{ shipment: TrackingInfo }> = ({ shipment }) => {
  const translate = useTranslations()
  const { locale } = useRouter()
  const [products, setProducts] = useState<Product[] | undefined>(undefined)

  const getProducts = async (productString?: string): Promise<Product[] | undefined> => {
    const extractProductDetails = (input: string) => {
      const [productTitle = '', size = '', quantity = ''] = input.split(/[-x]/)

      return {
        productTitle: productTitle?.trim(),
        size: size?.trim(),
        quantity: quantity?.trim(),
      }
    }

    const supportedLanguages = ['en', 'nl', 'fr', 'de', 'it', 'es', 'dk', 'ch', 'gb']
    const productFragment = (
      productTitle?: string,
    ) => `*[_type == "product" && (${supportedLanguages.map((locale) => `"${productTitle}" == productTitle.${locale}`).join(' || ')})][0] {
      ${coalesceQuery('productTitle', locale)},
      defined(images) => {
        "image": images[] {
          _type,
          _type == 'image' => {
            ${GET_IMAGE_FRAGMENT()}
          },
        }[0],
      },
      'price': shopifyVariants[0]->price['${locale}'],
      slug {
        current
      },
    }`

    const afterShipProducts = productString?.split(',').map((product) => extractProductDetails(product.trim()))
    const products: Product[] = []

    if (!afterShipProducts) return products

    try {
      const productPromises = afterShipProducts.map(async (product) => {
        try {
          const sanityProduct: { productTitle?: string; price?: string; image?: Image; slug: Slug } = await sanityCdnClient.fetch(productFragment(product?.productTitle))

          if (sanityProduct) {
            return {
              productTitle: sanityProduct?.productTitle,
              price: sanityProduct?.price && formatPrice(sanityProduct?.price, locale),
              image: sanityProduct?.image,
              size: product?.size,
              quantity: product?.quantity,
              slug: sanityProduct?.slug,
            }
          }
        } catch (error) {
          console.error(`Failed to fetch product: ${product?.productTitle}`, error)
        }
        return null
      })

      const resolvedProducts = await Promise.all(productPromises)

      products.push(...resolvedProducts.filter((product) => product !== null))
    } catch (error) {
      console.error('An error occurred while fetching products:', error)
    }

    return products
  }

  useAsync(async () => {
    getProducts(shipment?.custom_fields?.item_names)
      .then((products) => setProducts(products))
      .catch((error) => {
        console.error('Failed to get products:', error)
        setProducts([])
      })
  }, [shipment])

  if (!products) {
    return <MisterSpinner />
  }

  if (products.length === 0) {
    return null
  }

  return (
    <div>
      <div className='mb-7 text-body-md-bold md:text-body-xl-bold'>{translate('orderSummary', 'Order summary')}</div>
      {products.map((product, index) => (
        <MisterSiteLink key={index} className='flex gap-7 border-t py-5 last:border-b' link={{ type: 'internal', page: { slug: product.slug, _type: 'product' } }}>
          {product.image && <MisterImage mobile={product.image} className='w-16' />}
          <div className='flex w-full flex-col justify-between'>
            <span className='text-body-xs-bold md:text-body-md-bold'>{product.productTitle}</span>
            <div className='text-body-xs md:text-body-md'>
              {translate('optionSize', 'Size')}: {product.size}
              <div className='flex w-full justify-between'>
                {translate('quantity', 'Quantity')}: {product.quantity}
                <span className='text-body-xs-bold md:text-body-md-bold'>{product.price}</span>
              </div>
            </div>
          </div>
        </MisterSiteLink>
      ))}
    </div>
  )
}

export default AfterShipProducts
