import { ElementType, FunctionComponent, PropsWithChildren } from 'react'
import Sections from '../../sections/sections'
import { Section } from 'data-access/sanity/fragments/pages/sections.fragment'
import { cn } from '@ui/lib/utils'

interface Props {
  sections?: Section[]
  as?: ElementType
  className?: string
  // rest
  [key: string]: any
}

const MisterSectionLoop: FunctionComponent<PropsWithChildren<Props>> = ({ sections, as = 'div', className, ...rest }) => {
  const Tag = as
  const sectionsToRender = sections?.filter((section) => section._id) || []

  if (sectionsToRender.length === 0) {
    return null
  }

  let additionsToIndex = 0

  return (
    <Tag className={cn('max-w-screen flex flex-col items-center', className)}>
      {sectionsToRender.map((section, index) => {
        if (section._type === 'sectionLayout') {
          const indexForSectionLayout = index + additionsToIndex
          additionsToIndex += section.sections?.length || 0
          return <Sections key={index} index={indexForSectionLayout} section={section} {...rest} />
        }
        return <Sections key={index} index={additionsToIndex + index} section={section} {...rest} />
      })}
    </Tag>
  )
}

export default MisterSectionLoop
